import React, { useContext, useEffect, useRef, useState } from "react";
import config from "../../../config/conifg.json";
import CategorieCarousel from "./categorieCarousel";
import newsServices from "./newsServices";
import moment from "moment";
import DerniereInfo from "./derniereInfo";
import { AppContext } from "../../../appProvider";

const API_URL = config.API_URL;

export default function News(props) {
    const { isAuthenticatedClient, client } = useContext(AppContext);
    const [articleParCategorie, setArticleParCategorie] = useState([]);
    const [lastBlogs, setLastBlogs] = useState([]);
    const [preferences, setPreferences] = useState([]);
    const [frameWidth, setFrameWidth] = useState("100%");
    const [frameHeight, setFrameHeight] = useState(0);
    let tvRef = useRef(null);
    let iframeRef = useRef(null);

    useEffect(() => {
        if (iframeRef && iframeRef.current) {

            
            if (iframeRef.current.getBoundingClientRect().height ) {
                setFrameHeight(iframeRef.current.getBoundingClientRect().height)
            } else {
                setFrameHeight(
                    iframeRef.current.getBoundingClientRect().width * 0.5625
                );
            }


            window.addEventListener("resize", (e) => {

                setFrameHeight(
                    iframeRef.current.getBoundingClientRect().height
                );

            });
        }

        
    }, [iframeRef]);

    // useEffect(() => {
    //     if (tvRef && tvRef.current) {

            
    //         setFrameHeight(
    //             tvRef.current.getBoundingClientRect().width * 0.5625
    //         );
        


    //         window.addEventListener("resize", (e) => {

    //             setFrameHeight(
    //                 tvRef.current.getBoundingClientRect().width * 0.5625
    //             );

    //         });
    //     }

        
    // }, [tvRef]);

    useEffect(() => {
        if (isAuthenticatedClient && client) {
            newsServices
            .getAllBlogWithReservedGroupByCategorie()
            .then((response) => {
                setArticleParCategorie(response.data);
            })
            .catch((error) => {
                console.error();
            });
        newsServices
            .getLastBlogWithReserved(5)
            .then((response) => {
                setLastBlogs(response.data);
            })
            .catch((error) => {
                console.error();
            });

        } else {
            newsServices
                .getAllBlogGroupByCategorie()
                .then((response) => {
                    setArticleParCategorie(response.data);
                })
                .catch((error) => {
                    console.error();
                });
            newsServices
                .getLastBlog(5)
                .then((response) => {
                    setLastBlogs(response.data);
                })
                .catch((error) => {
                    console.error();
                });
        }
    }, [isAuthenticatedClient, client]);

    useEffect(() => {
        if (isAuthenticatedClient && client) {
            newsServices
                .getPreferenceByUserGroupByCategorie(client.id)
                .then((response) => {
                    setPreferences(response.data);
                })
                .catch((error) => {
                    console.error();
                });
        }
    }, [isAuthenticatedClient, client]);
    return (
        <>
            <div className="font-['Verdana'] mx-5 md:mx-20 mt-5 md:mt-10 mx-auto grid grid-cols-12 gap-5">
                <div className="col-span-12 px-5 lg:col-span-8 lg:px-0">
                    <div
                        ref={tvRef}
                        className=""
                    >
                        <iframe 
                            ref={iframeRef}
                            allowfullscreen
                            src="https://vdopanel.infocomwebservices.com/hybrid-stream-video-widget/bemediatv"
                            height="100%" 
                            width="100%" 
                            frameBorder="0"
                        ></iframe>
                    </div>
                </div>
                <DerniereInfo height={frameHeight} data={lastBlogs} />
            </div>
            {isAuthenticatedClient
                ? preferences.length
                    ? preferences.map((item) => (
                          <CategorieCarousel data={item} />
                      ))
                    : articleParCategorie.length
                    ? articleParCategorie.map((item) => (
                          <CategorieCarousel data={item} />
                      ))
                    : null
                : articleParCategorie.map((item) => (
                      <CategorieCarousel data={item} />
                  ))}
        </>
    );
}
