import axios from 'axios';
// import * as config from '../../../../config/conifg.json';
import config from '../../../../config/conifg.json'

const API_URL = config.API_URL;
class PageService {
    async getAllPage(values) {
        const {data} = await axios.get(`${API_URL}/page/all`);
        return data;
    }

    async getAllPageByCategorie(catID) {
        const {data} = await axios.get(`${API_URL}/page/c/${catID}`);
        return data;
    }

    async getAllPageBySubCategorie(catID) {
        const {data} = await axios.get(`${API_URL}/page/s/${catID}`);
        return data;
    }

    async createPage(values) {
        const {data} = await axios.post(`${API_URL}/page/create`, values);
        return data;
    }

    async getOnePageById(id) {
        const {data} = await axios.get(`${API_URL}/page/id/${id}`);
        return data;
    }

    async deleteOnePageById(id) {
        const {data} = await axios.delete(`${API_URL}/page/delete/${id}`);
        return data;
    }

    async getOnePageBySlug(slug) {
        const {data} = await axios.get(`${API_URL}/page/slug/${slug}`);
        return data;
    }

    async getLastPage(nbr) {
        const {data} = await axios.get(`${config.API_URL}/page/${nbr}/last`);
        return data;
    }

    async countPage() {
        const {data} = await axios.get(`${API_URL}/page/count`);
        return data;
    }
}

export default new PageService();