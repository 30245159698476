import React, {useContext, useEffect, useRef, useState} from 'react';
import {Formik, Form} from 'formik';
import tagService from './tagService';
import * as Yup from 'yup';
import {useNavigate, useParams} from 'react-router-dom'
import axios from 'axios';
import config from '../../../../config/conifg.json';
import {AppContext} from '../../../../appProvider';

export default function TagEdit(props) {
    const navigate = useNavigate();
    const {setNotification} = useContext(AppContext);
    let featuredImageRef = useRef(null);
    let formikRef = useRef(null);
    const [files, setFiles] = useState([]);
    const params = useParams();
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    useEffect(() => {
        tagService.getOneTagById(params.id)
            .then(response => {
                formikRef.current.setFieldValue("title",response.data.title);
                formikRef.current.setFieldValue("description", response.data.description);
            })
            .catch(error => {
                console.error(error)
            })
    },[])

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    return (<div className="m-10 ">
            <div className="p-10 bg-white rounded-xl h-screen">
                    <div className="w-full max-w-xl mx-auto">
                    <h3 className="text-2xl font-bold text-gray-600">Editer Tag</h3>
                    <Formik
                            innerRef={formikRef}
                            initialValues={{
                                title: "",
                                description: "",
                            }}

                            validationSchema={
                                Yup.object({
                                    title: Yup.string().max(255,"doit être inférieur a 255 caractères").required("Titre requis"),
                                    description: Yup.string().max(255,"doit être inférieur a 255 caractères")
                                })
                            }
                            onSubmit={(values) => {
                                tagService.updateOneTagById(params.id, values)
                                    .then(response => {
                                        setNotification({code:3, content: response.message, title:"Succès"})
                                        navigate("/admin/tag/liste",{replace:true});
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    })
                            }}
                        >{((formik) => (
                            <Form className='space-y-5 w-full mt-10' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                <div className="">
                                    <label className="block text-xl text-gray-700">Titre</label>
                                    <input  type="text" 
                                            id="title"
                                            name="title"
                                            placeholder='Titre du tag ...'
                                            className={`${!!didFocus && currentField == "title" && formik.errors.title || formik.touched.title && formik.errors.title ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                            onFocus={() => handleDidFocus("title")}
                                            {...formik.getFieldProps("title")}/>
                                            {!!didFocus && currentField == "title" && formik.errors.title || formik.touched.title && formik.errors.title ?<span className="text-red-500 text-sm">{formik.errors.title}</span> : null }
                                </div>

                                <div>
                                    <label className="text-xl text-gray-700 ">Description</label>
                                    <textarea   id="description"
                                                name="description"
                                                placeholder='En dire plus ...'
                                                className={`${!!didFocus && currentField == "description" && formik.errors.description || formik.touched.description && formik.errors.description ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("description")}
                                                {...formik.getFieldProps("description")}>
                                    </textarea>
                                    {!!didFocus && currentField == "description" && formik.errors.description || formik.touched.description && formik.errors.description ?<span className="text-red-500 text-sm">{formik.errors.description}</span> : null }
                                </div>
                                <div className='flex space-x-10 mt-10'>
                                    <button type="submit" className="flex-1 px-4 py-2 text-white bg-blue-700 rounded">Sauvegarder</button>
                                    <button type="reset" className="flex-1 px-4 py-2 text-white bg-gray-400 rounded">Réinitialiser</button>
                                </div>
                            </Form>
                        ))}
                        </Formik>
                </div>
                    
            </div>
        </div>)
}