import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { AppContext } from "../../appProvider";

export default function PopupInscription() {
    const {isAuthenticatedClient, client} = useContext(AppContext);
    let [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    useLayoutEffect(() => {

        if (isAuthenticatedClient && client) {
            closeModal();
        } else {
            openModal();
        }
    },[isAuthenticatedClient, client])


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog open={isOpen} as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="relative w-full max-w-xl transform  rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <button onClick={closeModal} className="rounded-full border border-transparent outline-none bg-red-500 text-white h-[30px] w-[30px] fixed -top-[15px] -right-[15px]">x</button>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-sm text-center sm:text-left sm:text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Restez à jour avec nos dernières infos, les innovations, nos conversations ou nos musiques – ne manquez rien !
                                    </Dialog.Title>
                                    <div className="flex flex-col sm:flex-row mt-5 items-center">
                                        <div className="shrink-0 w-[80px] h-[80px] sm:w-[150px] sm:h-[150px]">
                                            <img className="w-full h-full" src={logo}/>
                                        </div>
                                        <p className="text-xs text-center sm:text-left sm:text-sm text-gray-500">
                                        Inscrivez-vous pour ne manquez aucune de nos publications et nous soutenir !
                                        Amusez-vous avec nos instants musicaux 24h/24h
                                        Rebondissez avec bemediatv.fr
                                        Abonnez-vous pour recevoir, en avant-première, des contenus exclusifs.
                                        Vous pourriez aimer…
                                        </p>
                                    </div>

                                    <div className="flex justify-center sm:justify-end mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded border border-transparent bg-[#3009FA] text-white px-4 py-2 text-sm font-medium  hover:bg-[#30097a] focus:outline-none"
                                            onClick={() => {closeModal(); navigate("/inscription")}}
                                        >
                                            Je m'inscris
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
