import axios from 'axios';
import config from '../../../config/conifg.json';

const API_URL = config.API_URL;

class HistoriqueService {
    async createHistory(values) {
        const {data} = await axios.post(`${API_URL}/history/create`, values);
        return data;
    }

    async getAllHistoryByUser(id, nbr) {
        const {data} = await axios.get(`${API_URL}/history/nbr/${nbr}/user/${id}`);
        return data;
    }
}

export default new HistoriqueService();