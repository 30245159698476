import axios from "axios";
import config from '../../../config/conifg.json';

class PreferenceService {
    async getAllCategorie() {
        const {data} = await axios.get(`${config.API_URL}/categorie/all`);
        return data;
    }

    async getAllPreference() {
        const {data} = await axios.get(`${config.API_URL}/preference/all`);
        return data;
    }

    async getAllPreferenceByUserId(id) {
        const {data} = await axios.get(`${config.API_URL}/preference/user/${id}`);
        return data;
    }

    async addPreference(values) {
        const {data} = await axios.post(`${config.API_URL}/preference/add`,values);
        return data;
    }

    async updatePreference(id, values) {
        const {data} = await axios.put(`${config.API_URL}/preference/update/${id}`,values);
        return data;
    }

    async deletePreference(id) {
        const {data} = await axios.delete(`${config.API_URL}/preference/delete/${id}`);
        return data;
    }
}

export default new PreferenceService();