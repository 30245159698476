import React from 'react';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import { Outlet } from 'react-router-dom';

export default function Home(props) {
    return (<>  
                <Navbar/>
                <main className="flex-grow">
                    <div className="font-serif ">
                    <Outlet/>
                    </div>
                </main>
                <Footer/>
            </>)
}