import React,{useEffect, useState, useContext,useMemo} from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import categorieService from './categorieService';
import {AppContext} from '../../../../appProvider';
import {Navigate, useLocation} from 'react-router-dom';
import config from '../../../../config/conifg.json';
import {Link} from 'react-router-dom';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
// import arrayMove from 'array-move';

const SortableItem = SortableElement(({value}) => (
    <div className='flex items-center space-x-5 py-2 px-4 bg-white rounded-lg'>
        <div className="w-20 h-20 rounded-lg overflow-hidden bg-gray-400">
            {value.File ?
                <img className="w-full h-full object-cover" src={`${config.API_URL}${value.File.url}`}/>
            :null}
        </div>
        <span className="text-gray-700 text-xl font-bold">{value.title}</span>
    </div>
))

const SortableList =  SortableContainer(({items}) => {
    return (<div className="w-96 mx-auto space-y-5">
                {items.map((value, index) => (
                    <SortableItem key={`item-${value}-${index}`} index={index} value={value}/>
                ))}
            </div>)
})

export default function SortCategorieList(props) {
    const {isAuthenticated} = useContext(AppContext)
    const [categories, setCategories] = useState([]);
    const [currentCategorieId, setCurrentCategorieId] = useState(null);
    const [listType, setListeType] = useState("table");


    useEffect(() => {
        categorieService.getAllCategorieOrderASC()
            .then(response => {
                if (response.data.length) {
                    setCategories(response.data);
                }
            })
            .catch(error => {
                console.error("categorie :",error)
            })
    },[])


    const deleteCategorie = (id) => {
        categorieService.deleteOneCategoryById(id)
            .then(response => {
                let current = categories.findIndex(item => item.id == response.id);
                if (current >= 0) {
                    categories.splice(current,1)
                    setCategories(prevState => {
                        return [...prevState]
                    })
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" replace/>
    }

    const handleCurrentCategorieId = (id) => {
        setCurrentCategorieId(id);
    }

    const onSortEnd =({oldIndex,newIndex}) => {
        setCategories(prevCategories => (arrayMove(prevCategories, oldIndex,newIndex)))
        categorieService.organiserCategorieMenu({data: arrayMove(categories,oldIndex, newIndex)})
            .then(response => {
            })
            .catch(error => {
                console.error(error);
            })
    }

    
    return (<div classNamae="m-10">
                <div className="p-10">
                    <div>
                        <h2 className="text-2xl font-bold text-gray-700">Toutes les categorie</h2>
                        <div className="flex space-x-5">
                            <Link to="/admin/categorie/ajout">
                                <button className="flex mt-3 px-4 py-2 rounded-md bg-blue-500 text-white font-semibold text-basic">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    <span>
                                        Ajouter categorie
                                    </span>
                                </button>
                            </Link>
                            <Link to="/admin/categorie/organiser" className="block flex mt-3 px-4 py-2 rounded-md bg-green-500 text-white font-semibold text-basic">
                                Organiser Menu
                            </Link>
                        </div>
                    </div>
                    <div className="w-full mt-10">
                        <SortableList items={categories} onSortEnd={onSortEnd}/>
                    </div>
                    
                </div>
            </div>)
}