import React,{useContext} from 'react';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';
import {Outlet, useLocation, Navigate} from 'react-router-dom';
import BlogProvider from '../blog/blogProvider';
import NotificationList from '../../../../shared/notification/notificationList';
import {Disclosure} from '@headlessui/react'
import { AppContext } from '../../../../appProvider';
import PageProvider from '../pages/pageProvider';


export default function AdminPanel(props) {
    return (<>
                <NotificationList/>
                <div className="relative flex min-h-screen h-auto bg-gray-200 min-w-full w-auto  items-start">
                    <Disclosure>{({open}) => (
                        <>
                            <Sidebar/>
                            <Navbar/>
                            <div className={`${open ? "ml-[6rem]" : null} mt-[66px] w-full h-full`}>
                                <PageProvider>
                                <BlogProvider>
                                    <Outlet/>
                                </BlogProvider>
                                </PageProvider>
                            </div>
                        </>
                        )}
                    </Disclosure>
                </div>
            </>)
}