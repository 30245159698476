import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;
class EmailService {
    async getAllUser() {
        const {data} = await axios.get(`${API_URL}/user/all`);
        return data;
    }

    async send(values) {
        const {data} = await axios.post(`${API_URL}/email/send`, values);
        return data;
    }
}

export default new EmailService();