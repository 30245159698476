import axios from 'axios';
import config from '../../../config/conifg.json';
class NewsServices {
    async getAllBlogGroupByCategorie() {
        const {data} = await axios.get(`${config.API_URL}/blog/groupby/categorie`);
        return data;
    }

    async getAllBlogWithReservedGroupByCategorie() {
        const {data} = await axios.get(`${config.API_URL}/blog/with-reserved/groupby/categorie`);
        return data;
    }


    async getLastBlog(nbr) {
        const {data} = await axios.get(`${config.API_URL}/blog/${nbr}/last`);
        return data;
    }

    async getLastBlogWithReserved(nbr) {
        const {data} = await axios.get(`${config.API_URL}/blog/with-reserved/${nbr}/last`);
        return data;
    }

    async getPreferenceByUserGroupByCategorie(id) {
        const {data} = await axios.get(`${config.API_URL}/preference/user/${id}/groupby/categorie`);
        return data;
    }
}

export default new NewsServices();