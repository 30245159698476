import React, {useRef, useState, useContext, useEffect} from 'react';
import {Formik, Form} from 'formik';
import EmailService from './EmailService';
import * as Yup from 'yup';
import axios from 'axios';
import config from '../../../../config/conifg.json';
import {AppContext} from '../../../../appProvider';
import { useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Multiselect from '../../../../shared/dropdown/dropdown';


const API_URL = config.API_URL;

const hashConfig = {
    trigger: '#',
    separator: '<br>',
}

const directional = false


export default function SendEmail(props) {
    const navigate = useNavigate();
    const {setNotification } = useContext(AppContext);
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    const ref = useRef(null);
    const [editorRef, setEditorRef] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [contentState, setContentState] = useState(EditorState.createEmpty());

    const [clients, setClients] = useState([]);
    const [clientsDropdown, setClientsDropDown] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(()=> {
        EmailService.getAllUser()
            .then(response => {
                if (response.data) {
                    setClients(response.data)
                    setClientsDropDown(response.data.map(item => ({id: item.id,text: item.email, value: item.id})))
                }
            })
            .catch(error => {
                console.error()
            })
    }, [])


    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    const setEditorReference = (ref) => {
        setEditorRef(ref);
    }

    const onEditorStateChange = (value) => {
        setEditorState(value);
        ref.current.setFieldValue("content", draftToHtml(convertToRaw(editorState.getCurrentContent()),{
            hashConfig,
            directional
        }));
    }

    const onContentStateChange = (value) => {
        setContentState(value)
    }

    const handleSelectedClients = (values) => {
        console.log(values);
        setSelectedClients(values)
        let current_client = clients.find(item => item.id == values[0].id);
        setSelectedClient(current_client);
        let data = values.map(item =>  item.id);
        ref.current.setFieldValue('clients', data);
    }

    return (<div className="m-10">
            <div className="p-10 bg-white rounded-xl min-h-screen">
                    <div className="w-full max-w-xl mx-auto">
                    <h3 className="text-2xl font-bold text-gray-600">Envoyer un email</h3>
                    <Formik
                            innerRef={ref}
                            initialValues={{
                                object: "",
                                content: "",
                                clients:[],
                                allClient: false
                            }}

                            validationSchema={
                                Yup.object({
                                    object: Yup.string().max(255,"doit être inférieur a 255 caractères").required("Objet requis"),
                                    content: Yup.string().max(255,"doit être inférieur a 255 caractères"),
                                    clients: Yup.array().of(Yup.number()).required("Il faut choisir au moin un client")
                                })
                            }
                            onSubmit={(values) => {
                                console.log(values);
                                EmailService.send(values)
                                    .then(response => {
                                        setNotification({code:3, title: "Succès", content: response.message})
                                    })
                                    .catch(error => {
                                    })
                            }}
                        >{((formik) => (
                            <Form className='space-y-5 w-full mt-10' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                <div>
                                    <label className="block text-xl text-gray-700">Client(s)</label>
                                    <Multiselect    items={clientsDropdown} 
                                                    setItems={setClientsDropDown} 
                                                    selectedItems={selectedClients} 
                                                    setSelected={handleSelectedClients} 
                                                    theme="basic"
                                                    disabled={formik.values.allClient}
                                                    />
                                </div>
                                <div class="form-check">
                                    <input  class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                            type="checkbox"
                                            checked={formik.values.allClient}
                                            id="allClient"
                                            {...formik.getFieldProps("allClient")}
                                            />
                                    <label class="form-check-label inline-block text-gray-800" for="allClient">
                                        Tous les clients
                                    </label>
                                </div>
                                <div className="">
                                    <label className="block text-xl text-gray-700">Objet :</label>
                                    <input  type="text" 
                                            id="object"
                                            name="object"
                                            placeholder='Objet ...'
                                            className={`${!!didFocus && currentField == "object" && formik.errors.object || formik.touched.object && formik.errors.object ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                            onFocus={() => handleDidFocus("object")}
                                            {...formik.getFieldProps("object")}/>
                                            {!!didFocus && currentField == "object" && formik.errors.object || formik.touched.object && formik.errors.object ?<span className="text-red-500 text-sm">{formik.errors.object}</span> : null }
                                </div>

                                <div>
                                    <div className="border border-gray-300 rounded p-5">
                                        <Editor
                                            editorRef={setEditorReference}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName='wrapperClassName'
                                            editorClassName='min-h-[150px]'
                                            onEditorStateChange={onEditorStateChange}
                                            onContentStateChange={onContentStateChange}
                                            spellCheck={true}
                                        />
                                    </div>
                                </div>
                                <div className='flex space-x-10 mt-10'>
                                    <button type="submit" className="flex-1 px-4 py-2 text-white bg-blue-700 rounded">Envoyer</button>
                                    <button type="reset" className="flex-1 px-4 py-2 text-white bg-gray-400 rounded">Réinitialiser</button>
                                </div>
                            </Form>
                        ))}
                        </Formik>
                </div>
                    
            </div>
        </div>)
}