import axios from 'axios';
import config from '../../../config/conifg.json';

class SearchService {
    async getAllSearch(value) {
        const {data} = await axios.post(`${config.API_URL}/search/all`, value);
        return data;
    }
}

export default new SearchService();