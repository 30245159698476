import React, {useState} from 'react';
import {Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import passwordChangeService from './passwordChangeService';

function CheckCircleIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-green-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function WarningIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-yellow-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>)
}

function DangerIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-red-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function InfoIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

let status = [
    {
        textColor: 'text-yellow-500',
        icon: <WarningIcon/>,
    },
    {
        textColor: 'text-red-500',
        icon: <DangerIcon/>,
    },
    {
        textColor: 'text-blue-500',
        icon: <InfoIcon/>
    },
    {
        textColor : 'text-green-500',
        icon : <CheckCircleIcon/>,
    }
]


export default function PasswordChange(props){
    const params = useParams();
    const [currentNotification, setCurrentNotification] = useState(null);

    
    const closeCurrentNotification =() => {
        setCurrentNotification(null);
    }
    return (<div>
                <div className="max-w-md mx-auto mt-10 mb-20">
                    {
                        currentNotification ? 
                        <div className="">
                            <div className="relative flex items-center bg-gray-200  p-5 space-x-5">
                                <div className="absolute top-2 right-2">
                                    <button onClick={() =>closeCurrentNotification()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 fill-current" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                    </button>
                                </div>
                                <div>
                                    {status[currentNotification.code].icon}
                                </div>
                                <div>
                                    <h2 className={`${status[currentNotification.code].textColor} text-lg font-bold`}>{currentNotification.title}</h2>
                                    <p className=""> {currentNotification.content}</p>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    <h2 className="text-4xl font-bold text-gray-700 text-center dark:text-gray-300">Changer votre mot de passe</h2>
                    <Formik
                        initialValues={{
                            password: ""
                        }}

                        validationSchema={Yup.object({
                            password: Yup.string().required()
                        })}

                        onSubmit={(values) => {
                            passwordChangeService.changePassword(params.token, values)
                            .then(response => {
                                if (response) {
                                    setCurrentNotification({
                                        code: 3,
                                        content: response.message,
                                        title: "Succès"
                                    })
                                }
                            })
                            .catch(error => {
                                if (error && error.response) {
                                    setCurrentNotification({
                                        code: 1,
                                        content: error.response.data.message,
                                        title: "Error"
                                    })
                                } else {
                                    setCurrentNotification({
                                        code: 1,
                                        content: error.message,
                                        title: "Error"
                                    })
                                }
                                console.error(error)
                            })
                        }}
                    >{(formik)=> (
                        <Form className="space-y-5 text-left mt-10" onSubmit={formik.handleSubmit}>
                            <div className="space-y-2">
                                <label className="block text-lg text-semibold text-gray-700 dark:text-gray-400">Nouveau mot de passe <span className='text-red-700'>*</span></label>
                                <input  type="password" 
                                        id="password"
                                        name="password"
                                        className="w-full block bg-gray-200 py-2 px-3 outline-none"
                                        {...formik.getFieldProps("password")}/>
                            </div>
                            <div className="space-y-2">
                                <label className="block text-lg text-semibold text-gray-700 dark:text-gray-400">Confirmation <span className='text-red-700'>*</span></label>
                                <input  type="password" 
                                        id="confirmation"
                                        name="confirmation"
                                        className="w-full block bg-gray-200 py-2 px-3 outline-none"
                                        // {...formik.getFieldProps("confirmation")}
                                        />
                            </div>
                            <div className="space-x-10">
                                <button type="submit" className="py-2 px-4 bg-[#ff00ff] text-white w-full">Récuperation</button>
                            </div>
                        </Form>
                    )}</Formik>
                </div>
            </div>)
}