import React, {useState, useRef, useContext} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import mediaService from './mediaService';
import axios from 'axios';
import config from '../../../../config/conifg.json';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../appProvider';

const API_URL = config.API_URL;


export default function MediaAdd(props) {
    const {setNotification} = useContext(AppContext);
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [percent, setPercent] = useState(0);
    const ref= useRef(null);
    const formikRef = useRef(null);

    const handleFileChange =(e) => {
        setFiles([...e.target.files]);
        formikRef.current.setFieldValue("files", [...e.target.files])
    }
    return (<div>
                <div className='max-w-2xl mt-10 mx-auto'>
                    <h2 className='text-4xl font-bold text-gray-700 text-left'>Ajouter Medias</h2>
                    <div className='mt-5 border-2 border-gray-300 border-dashed rounded p-20 group hover:border-blue-500' onClick={() => ref.current.click()}>
                        <svg className="mx-auto h-20 w-20 text-gray-700 group-hover:text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-center text-gray-700 text-basic max-w-28"><strong>Ajouter</strong> un fichier ou glisser-déposer PNG, JPG, GIF, MP4</p>
                        
                    </div>
                    <div className="mt-5">
                        <div className="grid grid-cols-2 gap-2">
                            {files.length ? 
                                files.map((item,index) => (
                                <div key={index}>
                                    <div className="flex items-center">
                                        <div>
                                            {item.type == "image/jpeg" || item.type == "image/jpeg" ?
                                                <svg className="mx-auto h-20 w-20 text-gray-700 group-hover:text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                            : item.type == "video/mp4" ?
                                                <svg className="mx-auto h-20 w-20 text-gray-700 group-hover:text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                </svg>
                                            : null}
                                        </div>
                                        <div>
                                            <h3 className="text-sm text-gray-700 font-bold">{item.name}</h3>
                                            <span className="text-xs text-gray-500">{item.type}</span>
                                            <span className="text-xs text-gray-500"> | {item.size} Bytes</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : null}
                        </div>
                        <div className="mt-5">
                            <Formik
                                innerRef={formikRef}
                                initialValues={{
                                    files: []
                                }}
                                onSubmit ={async (values) => {
                                    let formdata = new FormData();
                                    formdata.append("file", values.files[0]);

                                    await axios.post(`${API_URL}/file/upload`, formdata, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        },
                                        onUploadProgress: (progressEvent) => {
                                            const {loaded, total} = progressEvent;
                                            let percent = Math.floor((loaded * 100) / total);
                                            setPercent(percent);
                                        } 
                                    }).then(response => {
                                        setNotification({code:3,title:"Succès",content: response.message});
                                        navigate("/admin/media/liste",{replace:true});
                                    })
                                }}
                            >{(formik)=>(
                                <Form onSubmit={formik.handleSubmit}>
                                    <input hidden type="file" ref={ref} multiple onChange={(e) => handleFileChange(e)}/>
                                    <div>
                                        <button type='submit' className="text-white bg-blue-500 px-5 py-2 rounded-md">Upload</button>
                                        {percent ? <span>(upload {percent} / 100 % )</span> : null}
                                    </div>
                                </Form>
                            )}</Formik>
                        </div>
                    </div>
                </div>
            </div>)
}