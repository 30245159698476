import axios from "axios";
import config from '../../../config/conifg.json';
class ProfileService {
    async updateCoordonnée(id,values) {
        const {data} = await axios.put(`${config.API_URL}/user/update/${id}`, values);
        return data;
    }

    async changePassword(token,value) {
        const {data} = await axios.post(`${config.API_URL}/client/change-password/${token}`,value);
        return data;
    }

    async getCurrentUserById(id) {
        const {data} = await axios.get(`${config.API_URL}/client/profile/${id}`);
        return data;
    } 
}

export default new ProfileService();