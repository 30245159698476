import React, { useContext, useEffect, useState } from 'react';
import newsServices from "../pages/news/newsServices";
import moment from 'moment';
import config from '../../config/conifg.json';
import {Link} from 'react-router-dom';
import logo_bardel from '../../../assets/images/logo.png';
import footerService from './footerService';
import { AppContext } from '../../appProvider';

moment.locale('fr')
export default function Footer(props) {
    const {setCurrentCategorie} = useContext(AppContext);
    const [last, setLast] = useState([]);
    const [pages, setPages] = useState([]);
    useEffect(()=> {
        footerService.getPageByType("PIED")
            .then(response => {
                setPages(response.data);
            })
        newsServices.getLastBlog(1)
            .then(response => {
                setLast(response.data)
            })
            .catch(error => {
                console.error()
            })
    },[])
    return (<footer className="w-full p-5 md:p-20 bg-gray-200 dark:bg-slate-400 overflow-hidden">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-10  gap-5  md:gap-10 xl:gap-5 w-full">
                    <div className="md:col-span-2 xl:col-span-3 text-left space-y-10 overflow-hidden">
                        <div >
                            <Link to="/" replace>
                                <img className="max-h-[80px] w-auto" src={logo_bardel}/>
                            </Link>
                        </div>
                        <p className="text-gray-500 dark:text-gray-900">Regardez, Ecoutez, Participez, informez vous, détendez vous, sur tous vos écrans, quelques soit le lieu 24h/24h, c’est gratuit</p>
                    </div>

                    <div className="text-left space-y-5 xl:col-span-2 2xl:col-span-2 overflow-hidden">
                        <div className="flex items-center space-x-2">
                            <h2 className="text-2xl font-semibold text-gray-900">Lien Utiles</h2>
                            <div className="w-12 h-1 rounded bg-[#ff00ff]"></div>
                        </div>
                        <ul className="ml-5 ">
                            <li><Link to="contact" className="text-md flex items-center space-x-2   text-gray-500 dark:text-gray-900">
                                <svg className="h-5 w-5 text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                                Contactez-Nous</Link></li>
                            <li><Link to="faire-un-don" className="text-md flex items-center space-x-2   text-gray-500 dark:text-gray-900">
                                <svg className="h-5 w-5 text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                                Faire-un-Don</Link></li>
                            {pages.length ?
                                pages.map(item => (
                                    <li><Link to={`${item.lien}`} onClick={() =>  setCurrentCategorie(item, "page")} className="text-md flex items-center space-x-2   text-gray-500 dark:text-gray-900">
                                        <svg className="h-5 w-5 text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                        </svg>
                                        {item.titre}</Link>
                                    </li>
                                ))
                            : null}
                            
                        </ul>
                    </div>

                    <div className="lg:col-span-2 xl:col-span-3 text-left space-y-5 overflow-hidden">
                        <div className="flex items-center space-x-2">
                            <h2 className="block h-full text-2xl font-semibold text-gray-700 dark:text-gray-900">Dernières infos</h2>
                            <div className="w-12 h-1 rounded bg-[#ff00ff]"></div>
                        </div>
                        {last.length ? 
                        last.map(item => (
                        <div className="flex flex-row items-center">
                                <div className="flex-shrink-0 h-28 w-36 bg-gray-300 rounded-md overflow-hidden">
                                    <Link to={`${item && item.Categories && item.Categories.length ? item.Categories[0].link : null}/${item.slug}`}>
                                        <img className="h-full w-full object-cover" src={`${config.API_URL}${item.File && item.File.url ? item.File.url : null}`} alt={item.title}/>
                                    </Link>
                                </div>
                                <div className="ml-5">
                                    <Link to={`${item &&item.Categories && item.Categories.length ? item.Categories[0].link : null}/${item.slug}`}>
                                        <p className="text-sm md:text-basic font-semibold text-left dark:text-gray-700">{item.title}</p>
                                    </Link>
                                    <div className="flex flex-wrap mt-5">
                                    <Link to={`${item && item.Categories && item.Categories.length ? item.Categories[0].link : null}`} className="inline-flex items-center text-gray-400 mr-5 dark:text-gray-700">
                                        <svg className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                        </svg>
                                        <span className="ml-2 text-sm md:text-basic">{item && item.Categories && item.Categories.length ? item.Categories[0].title : null}</span>
                                    </Link>
                                    <p className="inline-flex items-center text-gray-400">
                                        <svg className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span className="ml-2 text-sm md:text-basic dark:text-gray-700">
                                            {moment(item.date_publication).format('LL')}
                                        </span>
                                    </p>
                                </div>
                                </div>
                            </div>
                            ))
                        :null}
                    </div>

                    <div className="overflow-hidden xl:col-span-2 2xl:col-span-2">
                        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-900">Suivez nous</h2>
                        <div className="flex flex-wrap">
                            <a href="https://www.facebook.com/bemediatv" className="block bg-[#3b5999] rounded-full p-4 mt-2 mr-2">
                                    <svg    id="Layer_1"
                                            viewBox="0 0 512 512"
                                            className="h-4 w-4 text-white fill-current">
                                        <g>
                                            <path d="M308.3,508.5c-2.5,0.1-4.1,0.3-5.7,0.3c-34.2,0-68.3-0.1-102.5,0.1c-4.8,0-6.1-1.3-6.1-6.1c0.1-79.6,0.1-159.3,0.1-238.9   c0-2.1,0-4.2,0-6.9c-18.6,0-36.7,0-55.1,0c0-28.4,0-56.3,0-85c1.9,0,3.7,0,5.4,0c15,0,30-0.1,45,0.1c3.8,0,4.8-1.1,4.8-4.8   c-0.2-22.3-0.2-44.7,0-67c0.1-15.6,2.6-30.8,9.8-44.9c10.3-19.9,26.6-32.8,47.2-40.8c16.8-6.6,34.5-9,52.3-9.3   c29-0.4,58-0.2,87-0.3c2.7,0,4.9-0.1,4.9,3.7c-0.1,27.5-0.1,55-0.1,82.5c0,0.3-0.1,0.6-0.5,1.9c-1.7,0-3.6,0-5.5,0   c-18,0-36-0.1-54,0c-10.4,0-18.8,4.2-24.1,13.3c-1.6,2.7-2.6,6.2-2.6,9.4c-0.3,17,0,34-0.2,51c0,4,1.2,5.1,5.1,5.1   c25-0.2,50-0.1,75-0.1c2,0,3.9,0,7.3,0c-3.5,28.6-6.9,56.6-10.4,84.9c-26,0-51.3,0-77.1,0C308.3,340.8,308.3,424.4,308.3,508.5z"/>
                                        </g>
                                    </svg>
                            </a>
                            <a href="https://lnkd.in/gDQCQvbv" className="bg-[#0077b5] rounded-full p-4 mt-2 mr-2">
                                <span >
                                    <svg    enable-background="new 0 0 512 512" 
                                            id="Layer_1" 
                                            version="1.1" 
                                            viewBox="0 0 512 512"
                                            className="h-4 w-4 text-white fill-current">
                                        <g>
                                            <path d="M173.5,508.7c0-2.4,0-4.7,0-7c0-107.7,0-215.3,0-323c0-3-0.2-5.4,4.2-5.4c31.8,0.2,63.7,0.1,95.5,0.1   c0.8,0,1.6,0.2,2.9,0.4c0,14,0,27.8,0,41.7c0.4,0.1,0.7,0.3,1.1,0.4c4-5.2,7.8-10.6,12-15.7c12.2-15,27.5-26,45.5-32.6   c9.8-3.6,20.5-5.8,30.8-6.3c14.2-0.6,28.7-0.4,42.7,2c23,3.9,43.3,14.1,60.1,30.8c15.9,15.7,26.4,34.6,32.1,55.9   c3,11.3,4.9,22.9,6.7,34.4c1.2,8,1.8,16.2,1.8,24.2c0.1,65,0.1,130,0.1,195c0,1.6-0.1,3.2-0.2,5.3c-1.9,0-3.5,0-5.1,0   c-30.7,0-61.3-0.1-92,0.1c-5.1,0-6-1.7-6-6.3c0.1-59.8,0.3-119.7-0.1-179.5c-0.1-10.5-1.1-21.4-3.8-31.5   c-4.9-17.7-16.1-30.5-33.9-37c-15-5.5-30.2-6.5-45.6-2.6c-17,4.3-28.7,15.6-37.5,30.5c-6.8,11.5-8.1,24.2-8.2,36.9   c-0.4,60.7-0.2,121.3-0.2,182c0,2.3,0,4.6,0,7.1C241.9,508.7,208,508.7,173.5,508.7z"/>
                                            <path d="M109.1,342.6c0,53.3-0.1,106.6,0.1,159.9c0,4.8-1,6.6-6.3,6.5c-30.5-0.3-61-0.3-91.5,0c-5,0-6.1-1.7-6.1-6.3   c0.1-107.1,0.1-214.3,0-321.4c0-4.3,1.2-5.8,5.6-5.8c30.8,0.2,61.6,0.2,92.5,0c4.8,0,5.7,1.8,5.7,6.1   C109.1,235.3,109.1,289,109.1,342.6z"/>
                                            <path d="M56.1,4.8C89,4,108.7,27.9,108.8,57.2c0.1,30-20.9,52.4-53.9,52.3c-30.7,0-52.4-22-52.3-53.1C2.7,27.3,23.8,3.8,56.1,4.8z"/>
                                        </g>
                                    </svg>
                                </span>
                            </a>

                            <a href="https://lnkd.in/gS7QRsq" className="bg-red-600 rounded-full p-4 mt-2 mr-2">
                                <span >
                                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
                                    className="h-4 w-4 text-white stroke-current"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>

                                </span>
                            </a>
                        </div>
                    </div>

                </div>
            </footer>)
}