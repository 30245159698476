import React, {useEffect,useState, useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { AppContext } from '../../../appProvider';
import blogService from '../../../private/admin/pages/blog/blogService';
import config from '../../../config/conifg.json';
import moment from 'moment';
import ReactPaginate from 'react-paginate';


export default function DernieresEmissions(props) {
    const [blogs, setBlogs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(18);
    const [currentPage, setCurrentPage] = useState(null);
    const location = useLocation();
    useEffect(() => {     
        blogService.getAllBlog()
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => {
                console.error();
            })

    }, [])

    useEffect(() => {
        if (blogs && blogs.length) {
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(blogs.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(blogs.length / itemsPerPage));
        }
    },[blogs, itemsPerPage, itemOffset])

    const handlingPageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage ) % blogs.length;
        setItemOffset(newOffset);

    }
    return (<div className="font-['Verdana']">
                <div className="bg-gray-200 py-10">
                    <div className="mx-5 sm:mx-20 flex items-center justify-between">
                        <h2 className="text-xl md:text-4xl font-bold text-gray-700">Dernière Emissions</h2>
                        <div className="flex items-center">
                            <div className="inset-y-0">
                                <Link to="/"><span className="text-semibold text-gray-700 hover:text-red-500">Accueil</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-10 mx-5 sm:mx-20 xl:max-w-7xl mt-12 mb-20">
                    {blogs && blogs.length ?
                        currentItems.map(blog => (
                            <div className="flex flex-col">
                            <div className="relative w-full h-72 bg-gray-400 overflow-hidden font-sans">
                                <div className="absolute z-40 top-3 left-3 bg-yellow-500 rounded-full text-center py-1 px-3 text-semibold text-white">{blog.Categories && blog.Categories.length ? blog?.Categories[0]?.title : (blog.SubCategorie ? blog?.SubCategorie?.title : "Non catégorisé")}</div>
                                <Link to={`${blog.slug}`}>
                                    <div className="absolute z-10 inset-0 grid place-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 stroke-current text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <img className="absolute transition-opacity ease-in delay-150 duration-500 z-30 cursor-pointer w-full h-full object-cover" src={`${config.API_URL}${blog.File && blog.File.url ? blog.File.url : null}`} onError={(e) => e.target.style.display = "none"} alt={blog.Categories && blog.Categories.length ? blog?.Categories[0]?.title : (blog.SubCategorie ? blog?.SubCategorie?.title : "Non catégorisé")}/>
                                </Link>
                            </div>
                            <div>
                                <div className="flex space-x-5 mt-5 font-sans">
                                    <Link to={`${blog.Categories && blog.Categories.length ? blog?.Categories[0]?.link : (blog.SubCategorie ? blog?.SubCategorie?.link : "Non catégorisé")}`} className="inline-flex items-center text-gray-400 ">
                                        <svg className="h-5 w-5 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                        </svg>
                                        <span className="ml-2">{blog.Categories && blog.Categories.length ? blog?.Categories[0]?.title : (blog.SubCategorie ? blog?.SubCategorie?.title : "Non catégorisé")}</span>
                                    </Link>
                                    <p className="inline-flex items-center text-gray-400">
                                        <svg className="h-5 w-5 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span className="ml-2">
                                            {moment(blog.date_publication).format('LL')}
                                        </span>
                                    </p>
                                </div>
                                <div className="mt-10 ">
                                    <Link to={`${blog.slug}`}>
                                        <p className="dark:text-gray-300 text-gray-700 font-semibold hover:text-[#ff00ff] text-ellipsis overflow-hidden max-h-32 text-[1.275rem]">{blog.title}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        ))
                    
                    
                    : null}
                </div>
                <div className="w-full mb-10 font-sans">
                    <ReactPaginate
                        className='block flex justify-center'
                        nextClassName='dark:text-slate-200 px-4 py-2 border border-[#ff00ff]'
                        pageClassName='dark:text-slate-200 px-4 py-2 border border-[#ff00ff] mx-2'
                        previousClassName='dark:text-slate-200 px-4 py-2 border border-[#ff00ff]'
                        activeClassName='dark:text-slate-200 bg-[#ff00ff] text-white'
                        breakLabel="..."
                        nextLabel="Suivant"
                        onPageChange={handlingPageClick}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="Précedent"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>)
}