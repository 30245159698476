import React from "react";
import config from "../../../config/conifg.json";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import moment from "moment";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export default function DerniereInfo(props) {
    const { data } = props;
    return (
        <div className="font-['Verdana'] col-span-12 lg:col-span-4">
            <div className="hidden lg:block">
                <div className="pb-[20px]">
                    <div className="flex items-center justify-between space-x-2 border-l-4 border-[#ff00ff] pl-2">
                        <h2 className="block h-full text-lg xl:text-2xl  text-[#3009fa] dark:text-white">
                            Dernières Emissions
                        </h2>
                        {/* <div className="w-12 h-1 rounded bg-[#ff00ff]"></div> */}
                        <Link
                            to="dernieres-emissions"
                            className="shrink-0 flex items-center text-sm md:text-xl text-[#ff00ff] space-x-1 md:space-x-3"
                        >
                            <span className="block text-sm">Voir tout </span>
                            <svg
                                className="h-2 w-2 stroke-current"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
                
                <div className="space-y-3  overflow-y-scroll" style={{ maxHeight: `${props.height - 48}px` }}>
                    {data.map((item) => (
                        <div className="flex space-x-3">
                            <div className="shrink-0 relative h-24 md:h-24 w-32 bg-gray-300 rounded-md overflow-hidden font-sans">
                                {/* <span className="absolute z-40 bg-[#3009fa] rounded-full top-1 right-1 text-white py-0.5 px-4 font-semibold text-sm">
                                    Nouveau
                                </span> */}
                                <Link
                                    to={`${
                                        item &&
                                        item.Categories &&
                                        item.Categories.length
                                            ? item.Categories[0].link
                                            : "non-categorise"
                                    }/${item.slug}`}
                                >
                                    <div className="absolute z-10 inset-0 grid place-content-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-20 w-20 stroke-current text-[#ff00ff]"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                            />
                                        </svg>
                                    </div>
                                    <img
                                        className="absolute z-30 transition-opacity ease-in delay-150 duration-500 cursor-pointer h-full w-full object-cover"
                                        src={`${config.API_URL}${
                                            item.File && item.File.url
                                                ? item.File.url
                                                : null
                                        }`}
                                        onError={(e) =>
                                            (e.target.style.display = "none")
                                        }
                                        // alt={item.title}
                                    />
                                </Link>
                            </div>
                            <div className="space-y-2">
                                <div>
                                    <Link
                                        to={`${
                                            item &&
                                            item.Categories &&
                                            item.Categories.length
                                                ? item.Categories[0].link
                                                : "non-categorise"
                                        }/${item.slug}`}
                                    >
                                        <p className="text-gray-700 dark:text-gray-300 text-left text-ellipsis overflow-hidden max-h-32 text-[0.8rem]">
                                            {item.title}
                                        </p>
                                    </Link>
                                </div>
                                <div className="flex md:flex-col md:justify-items-start md:space-y-1 xl:space-y-0 xl:flex-row xl:space-x-2 font-sans">
                                    <Link
                                        to={`${
                                            item &&
                                            item.Categories &&
                                            item.Categories.length
                                                ? item.Categories[0].link
                                                : "non-categorise"
                                        }`}
                                        className="inline-flex items-center text-gray-400 "
                                    >
                                        <svg
                                            className="h-4 w-4 stroke-current text-[#ff00ff]"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                            />
                                        </svg>
                                        <span className="ml-2 text-xs">
                                            {item &&
                                            item.Categories &&
                                            item.Categories.length
                                                ? item.Categories[0].title
                                                : "non-categorise"}
                                        </span>
                                    </Link>
                                    <p className="inline-flex items-center text-gray-400">
                                        <svg
                                            className="h-4 w-4 stroke-current text-[#ff00ff]"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="ml-2 text-xs">
                                            {moment(
                                                item.date_publication
                                            ).format("LL")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="block lg:hidden mx-5 mt-10 space-y-5">
                <div className="flex border-l-4 border-[#ff00ff]  py-3 md:py-5 items-center justify-between pl-5 md:pl-10 overflow-hidden">
                    <h2 className=" text-[#3009fa] dark:text-white text-xl md:text-4xl text-left truncate">
                        Dernières Emissions
                    </h2>
                    <Link
                        to="dernieres-emissions"
                        className="shrink-0 flex items-center text-sm md:text-xl text-[#ff00ff] space-x-1 md:space-x-3"
                    >
                        <span className="block">Voir tout </span>
                        <svg
                            className="h-4 w-4 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 5l7 7-7 7M5 5l7 7-7 7"
                            />
                        </svg>
                    </Link>
                </div>
                <Swiper
                    breakpoints={{
                        0: {
                            width: 0,
                            slidesPerView: 1,
                        },
                        640: {
                            width: 640,
                            slidesPerView: 2,
                        },
                        768: {
                            width: 768,
                            slidesPerView: 2,
                        },
                        1024: {
                            width: 1024,
                            slidesPerView: 3,
                        },
                        1280: {
                            width: 1280,
                            slidesPerView: 4,
                        },
                        1536: {
                            width: 1536,
                            slidesPerView: 4,
                        },
                    }}
                    spaceBetween={50}
                    slidesPerView={4}
                    className=""
                >
                    {data.length
                        ? data.map((item) => (
                              <SwiperSlide>
                                  <div className="flex flex-col space-y-5 mr-5">
                                      <div className="relative h-48 md:h-56 w-full bg-gray-300 rounded-md overflow-hidden font-sans">
                                          <span className="absolute z-40 bg-[#3009fa] rounded-full top-1 right-1 text-white py-0.5 px-4 font-semibold text-sm">
                                              Nouveau
                                          </span>
                                          <Link
                                              to={`${
                                                  item &&
                                                  item.Categories &&
                                                  item.Categories.length
                                                      ? item.Categories[0].link
                                                      : "non-categorise"
                                              }/${item.slug}`}
                                          >
                                              <div className="absolute z-10 inset-0 grid place-content-center">
                                                  <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="h-20 w-20 stroke-current text-[#ff00ff]"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                      strokeWidth={2}
                                                  >
                                                      <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                      />
                                                  </svg>
                                              </div>
                                              <img
                                                  className="absolute z-30 transition-opacity ease-in delay-150 duration-500 cursor-pointer h-full w-full object-cover"
                                                  src={`${config.API_URL}${
                                                      item.File && item.File.url
                                                          ? item.File.url
                                                          : null
                                                  }`}
                                                  onError={(e) =>
                                                      (e.target.style.display =
                                                          "none")
                                                  }
                                                  alt={item.title}
                                              />
                                          </Link>
                                      </div>
                                      <div className="space-y-5">
                                          <div className="flex space-x-5 font-sans">
                                              <Link
                                                  to={`${
                                                      item &&
                                                      item.Categories &&
                                                      item.Categories.length
                                                          ? item.Categories[0]
                                                                .link
                                                          : "non-categorise"
                                                  }`}
                                                  className="inline-flex items-center text-gray-400 "
                                              >
                                                  <svg
                                                      className="h-4 w-4 stroke-current text-[#ff00ff]"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                  >
                                                      <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth={2}
                                                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                                      />
                                                  </svg>
                                                  <span className="ml-2 text-md">
                                                      {item &&
                                                      item.Categories &&
                                                      item.Categories.length
                                                          ? item.Categories[0]
                                                                .title
                                                          : "non-categorise"}
                                                  </span>
                                              </Link>
                                              <p className="inline-flex items-center text-gray-400">
                                                  <svg
                                                      className="h-4 w-4 stroke-current text-[#ff00ff]"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                  >
                                                      <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth={2}
                                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                      />
                                                  </svg>
                                                  <span className="ml-2 text-md">
                                                      {moment(
                                                          item.date_publication
                                                      ).format("LL")}
                                                  </span>
                                              </p>
                                          </div>
                                          <div>
                                              <Link
                                                  to={`${
                                                      item &&
                                                      item.Categories &&
                                                      item.Categories.length
                                                          ? item.Categories[0]
                                                                .link
                                                          : "non-categorise"
                                                  }/${item.slug}`}
                                              >
                                                  <p className="text-gray-700 dark:text-gray-300 text-left text-ellipsis overflow-hidden max-h-32 text-[1.275rem]">
                                                      {item.title}
                                                  </p>
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              </SwiperSlide>
                          ))
                        : null}
                </Swiper>
            </div>
        </div>
    );
}
