import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;
class TagService {
    async getAllTag() {
        const {data} = await axios.get(`${API_URL}/tag/all`);
        return data;
    }

    async createTag(values) {
        const {data} = await axios.post(`${API_URL}/tag/create`, values);
        return data;
    }

    async deleteOneTagById(id) {
        const {data} = await axios.delete(`${API_URL}/tag/delete/${id}`);
        return data;
    }

    async getOneTagById(id) {
        const {data} = await axios.get(`${API_URL}/tag/one/${id}`);
        return data;
    }

    async updateOneTagById(id,values) {
        const {data} = await axios.put(`${API_URL}/tag/update/${id}`, values);
        return data;
    }

    async countTag() {
        const {data} = await axios.get(`${API_URL}/tag/count`);
        return data;
    }
}

export default new TagService();