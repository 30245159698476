import React,{useContext, useEffect, useState} from 'react';
import { BlogContext } from './blogProvider';
import parse from 'html-react-parser';
import moment from 'moment';
import config from '../../../../config/conifg.json';

export default function BlogPreview(props) {
    const {currentBlog} = useContext(BlogContext);
    const [categorie, setCategories] = useState([]);
    const [currentCategorie, setCurrentCategorie] = useState(null);

    if (props.currentBlog) {
        return (<div className="mt-10">
                    <div className="max-w-7xl mx-auto py-10 bg-white rounded-xl">
                        <div className="">
                            <div className="space-y-5">
                                <h2 className="text-gray-700 text-4xl font-bold">{props.currentBlog.title}</h2>
                                <div className="flex">
                                    <div className="bg-yellow-500 text-white rounded-full font-bold py-1 px-4">{props.currentCategorie && props.currentCategorie.title ? props.currentCategorie.title : "Non catégorisé"}</div>
                                </div>
                                <div className="flex space-x-5">
                                    <div className="text-sm text-gray-700 font-semiblod"><p>{props.currentAuthor ? `${props.currentAuthor.nom} ${props.currentAuthor.prenom}` : "Aucun auteur"}</p></div>
                                    <p className="text-sm text-gray-700 font-semiblod">{props.currentBlog.date_publication ? moment(props.currentBlog.date_publication).format("LL") : "Auccune date"}</p>
                                </div>
                            </div>
                            {/* <div className="w-full overflow-hidden">
                                {props.currentBlog.featured_image ? 
                                <img className="w-full h-full object-cover" src={URL.createObjectURL(props.currentBlog.featured_image)}/>
                                : props.currentBlog.file ? <img className="w-full h-full object-cover" src={`${config.API_URL}${props.currentBlog.file.url}`}/> : null}
                            </div> */}
                            <div className="mt-10 prose-base text-justify">
                                {props.currentBlog.article ? 
                                parse(props.currentBlog.article)
                                : "Pas de contenu"}
                            </div>
                        </div>
                    </div>
                </div>);
    } else {
        return (<div>
            <div className="max-w-7xl mx-auto m-10">
                <div className="mt-10">
                    <div>
                        <h2 className="text-gray-700 text-4xl font-bold text-center">Rien à afficher</h2>
                    </div>
                </div>
            </div>
        </div>);
    }
}