import logo from './logo.svg';
import './App.css';
import Home from './App/public/pages/home/home';
import {Navigate, Route, Routes} from 'react-router-dom';
import AdminPanel from './App/private/admin/pages/home/admin';
import CategorieList from './App/private/admin/pages/categorie/categorieList';
import CategorieAdd from './App/private/admin/pages/categorie/categorieAdd';
import MediaAdd from './App/private/admin/pages/media/mediaAdd';
import Signup from './App/public/pages/authentication/signup/signup';
import News from './App/public/pages/news/news';
import Signin from './App/public/pages/authentication/signin/signin';
import Recovery from './App/public/pages/authentication/recovery';
import BlogList from './App/public/pages/blog/blogList';
import BlogDetail from './App/public/pages/blog/blogDetail';
import CategorieEdit from './App/private/admin/pages/categorie/categorieEdit';
import BlogAdd from './App/private/admin/pages/blog/blogAdd';
import BlogListAdmin from './App/private/admin/pages/blog/blogListAdmin';
import BlogPreview from './App/private/admin/pages/blog/blogPreview';

import SigninAdmin from './App/private/admin/pages/Authentication/signin';
import PrivateComponents from './App/private/utils/privateComponent';
import { AppContext } from './App/appProvider';
import {useContext, useEffect, useState} from 'react';
import TagAdd from './App/private/admin/pages/tag/tagAdd';
import TagList from './App/private/admin/pages/tag/tagList';
import Profile from './App/private/client/profile/profile';
import Preference from './App/private/client/preference/preference';
import Historique from './App/public/pages/historique/historique';
import Dashboard from './App/private/admin/pages/dashboard/dashboard';
import UserAdd from './App/private/admin/pages/user/userAdd';
import UserList from './App/private/admin/pages/user/userList';
import MediaList from './App/private/admin/pages/media/mediaList';
import BlogEdit from './App/private/admin/pages/blog/blogEdit';
import TagEdit from './App/private/admin/pages/tag/tagEdit';
import UserEdit from './App/private/admin/pages/user/userEdit';
import Search from './App/private/admin/pages/search/search';
import SearchClient from './App/public/pages/search/search';
import PasswordChange from './App/public/pages/authentication/passwordChange/passwordChange';
import Confirmation from './App/public/pages/authentication/confirmation/confirmation';
import SortCategorieList from './App/private/admin/pages/categorie/sortCategorieList';
import SubCategorieSortList from './App/private/admin/pages/subCategorie.js/subCategorieSortList';
import SubCategorieList from './App/private/admin/pages/subCategorie.js/subCategorieList';
import SubCategorieAdd from './App/private/admin/pages/subCategorie.js/subCategorieAdd';
import SortSubCategorieList from './App/private/admin/pages/subCategorie.js/subCategorieSortList';
import Contact from './App/public/pages/contact/contact';
import PreferenceAdd from './App/private/client/preference/ajoutPreference';
import {Helmet} from 'react-helmet';
import PageAdd from './App/private/admin/pages/pages/pageAdd';
import PageList from './App/private/admin/pages/pages/pageList';
import PageEdit from './App/private/admin/pages/pages/pageEdit';
import SubCategorieEdit from './App/private/admin/pages/subCategorie.js/subCategorieEdit';
import Don from './App/public/pages/don/don';
import DernieresEmissions from './App/public/pages/news/derniereEmission';
import axios from 'axios';
import ProtectedClientComponent from './App/public/utils/protectedClientComponent';
import PagePreview from './App/private/admin/pages/pages/pagePreview';
import AuthorAdd from './App/private/admin/pages/author/authorAdd';
import AuthorEdit from './App/private/admin/pages/author/authorEdit';
import AuthorList from './App/private/admin/pages/author/authorList';
import EmailSend from './App/private/admin/pages/email/EmailSend';

function App() {
  const [scrollposition, setScrollPosition] = useState(0);
  const {isAuthenticated, darkMode, isAuthenticatedClient, role} = useContext(AppContext);
  let the_route = isAuthenticated ? "<CategorieList/>" : `<Navigate to="/admin/login"/>`;

  useEffect(() => {
    axios.interceptors.request.use(function (req) {
      let token = localStorage.getItem("token");
      if (token) {
        if (req.headers["x-downloadImage"]) {
          req.headers = {"Content-Type":"image/jpeg"}
          return req
        } else {
          req.headers.Authorization = `Bearer ${token}`;
        }
      }
      return req
    }, (error) => {
      return Promise.reject(error);
    })

    window.addEventListener("scroll", () => {
      setScrollPosition(window.pageYOffset)
    })
    
  },[])

  return (
    <div className={`${darkMode} flex flex-col min-h-screen`}>
      <div className='dark:bg-slate-800 dark:transition-colors dark:delay-150 dark:ease-in-out dark:duration:10000'>
      { scrollposition >= 300 ? 
        <button className="fixed bottom-10 right-10 bg-[#ff00ff] z-[1000] p-2 rounded-full text-white" onClick={() => window.scrollTo({top:0,behavior: "smooth"})}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
          </svg>
        </button>
      : 
        null
      }
      
      <Helmet>
          <title>BE MEDIA TV - La chaine 100% digitale</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home/>}>
          <Route path="" element={<News/>}/>
          <Route path="inscription" element={
            <>
            <div className='grid grid-cols-1 md:grid-cols-2 divide-x divide-dashed'>
              <Signup/>
              <Signin/>
            </div>
            </>
          
          }/>
          <Route path="identification" element={<Signin/>}/>
          <Route path="recuperation" element={<Recovery/>}/>
          <Route path="nouveau-mot-de-passe/:token" element={<PasswordChange/>}/>
          <Route path="activation-compte/:token" element={<Confirmation/>}/>
          <Route path="article" element={<BlogList/>}/>
          <Route path="/categorie" element={<BlogList/>}/>
          <Route path="/categorie/id" element={<BlogDetail/>}/>
          <Route path="profile" element={<ProtectedClientComponent><Profile/></ProtectedClientComponent>}>
            <Route path="mot-de-passe" element={<PasswordChange/>}/>  
          </Route>
          <Route path="preference" element={<ProtectedClientComponent><Preference/></ProtectedClientComponent>}/>
          <Route path="preference/ajout" element={<ProtectedClientComponent><PreferenceAdd/></ProtectedClientComponent>}/>
          <Route path="historique" element={<ProtectedClientComponent><Historique/></ProtectedClientComponent>}/>
          <Route path="recherche/:slug" element={<SearchClient/>}/>
          <Route path="recherche" element={<SearchClient/>}/>
          <Route path="contact" element={<Contact/>}/>
          <Route path="faire-un-don" element={<Don/>}/>
          <Route path="dernieres-emissions" element={<DernieresEmissions/>}/>
          <Route path=":categorie" element={<BlogList/>}/>
          <Route path=":categorie/:id" element={<BlogDetail/>}/>
        </Route>

        <Route path="admin/identification" element={<SigninAdmin/>}/>
        <Route path="admin" element={<AdminPanel/>}>
          <Route path="" element={<Navigate to="/admin/identification"/>}/>
          <Route path="dashboard" element={<PrivateComponents><Dashboard/></PrivateComponents>}/>
          <Route path="utilisateur/ajout" element={<PrivateComponents><UserAdd/></PrivateComponents>}/>
          <Route path="utilisateur/edit/:id" element={<PrivateComponents><UserEdit/></PrivateComponents>}/>
          <Route path="utilisateur/liste" element={<PrivateComponents><UserList/></PrivateComponents>}/>
          <Route path="auteur/ajout" element={<PrivateComponents><AuthorAdd/></PrivateComponents>}/>
          <Route path="auteur/edit/:id" element={<PrivateComponents><AuthorEdit/></PrivateComponents>}/>
          <Route path="auteur/liste" element={<PrivateComponents><AuthorList/></PrivateComponents>}/>
          <Route path="categorie/liste" element={<PrivateComponents><CategorieList/></PrivateComponents>}/>
          <Route path="categorie/organiser" element={<PrivateComponents><SortCategorieList/></PrivateComponents>}/>
          <Route path="categorie/ajout" element={<PrivateComponents><CategorieAdd/></PrivateComponents>}></Route>
          <Route path="categorie/edit/:id" element={<PrivateComponents><CategorieEdit/></PrivateComponents>}/>
          <Route path="categorie/edit" element={<PrivateComponents><CategorieEdit/></PrivateComponents>}/>
          <Route path="sous-categorie/liste" element={<PrivateComponents><SubCategorieList/></PrivateComponents>}/>
          <Route path="sous-categorie/organiser" element={<PrivateComponents><SortSubCategorieList/></PrivateComponents>}/>
          <Route path="sous-categorie/ajout" element={<PrivateComponents><SubCategorieAdd/></PrivateComponents>}></Route>
          <Route path="sous-categorie/edit/:id" element={<PrivateComponents><SubCategorieEdit/></PrivateComponents>}/>
          <Route path="sous-categorie/edit" element={<PrivateComponents><SubCategorieEdit/></PrivateComponents>}/>
          {/* <Route path="sous-categorie/edit/:id" element={<PrivateComponents><SubCategorieEdit/></PrivateComponents>}/> */}
          {/* <Route path="sous-categorie/edit" element={<PrivateComponents><SubCategorieEdit/></PrivateComponents>}/> */}
          <Route path="tag/ajout" element={<PrivateComponents><TagAdd/></PrivateComponents>}/>
          <Route path="tag/edit/:id" element={<PrivateComponents><TagEdit/></PrivateComponents>}/>
          <Route path="tag/liste" element={<PrivateComponents><TagList/></PrivateComponents>}/>
          <Route path="page/ajout" element={<PrivateComponents><PageAdd/></PrivateComponents>}/>
          <Route path="page/liste" element={<PrivateComponents><PageList/></PrivateComponents>}/>
          <Route path="page/edit/:id" element={<PrivateComponents><PageEdit/></PrivateComponents>}/>
          <Route path="page/ajout/previsualiser" element={<PrivateComponents><PagePreview/></PrivateComponents>}/>
          <Route path="page/edit/:id/previsualiser" element={<PrivateComponents><PagePreview/></PrivateComponents>}/>
          <Route path="email/send" element={<PrivateComponents><EmailSend/></PrivateComponents>}/>
          <Route path="article/ajout" element={<PrivateComponents><BlogAdd/></PrivateComponents>}/>
          <Route path="article/edit/:id" element={<PrivateComponents><BlogEdit/></PrivateComponents>}/>
          <Route path="article/liste" element={<PrivateComponents><BlogListAdmin/></PrivateComponents>}/>
          <Route path="article/ajout/previsualiser" element={<PrivateComponents><BlogPreview/></PrivateComponents>}/>
          <Route path="article/edit/:id/previsualiser" element={<PrivateComponents><BlogPreview/></PrivateComponents>}/>
          <Route path="media/ajout" element={<PrivateComponents><MediaAdd/></PrivateComponents>}></Route>
          <Route path="media/liste" element={<PrivateComponents><MediaList/></PrivateComponents>}></Route>
          <Route path="recherche/:slug" element={<PrivateComponents><Search/></PrivateComponents>}></Route>
          <Route path="recherche/" element={<PrivateComponents><Search/></PrivateComponents>}></Route>
        </Route>
      </Routes>
      </div>
    </div>
  );
}

export default App;
