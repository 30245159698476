import React, { useContext } from 'react';
import config from '../../../config/conifg.json';
import { Link } from 'react-router-dom';
import {Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import moment from 'moment';
import { AppContext } from '../../../appProvider';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

export default function CategorieCarousel(props) {
    const {setCurrentCategorie} = useContext(AppContext);
    const {data} = props;

    return (<div className="font-['Verdana'] my-20 mx-5 md:mx-20">
                <div>
                    <div className='flex border-l-4 border-[#ff00ff]  py-3  md:py-5 items-center justify-between pl-5 md:pl-10 overflow-hidden'>
                        <h2 className=" text-[#3009fa] dark:text-white text-xl md:text-4xl text-left truncate">{data.title}</h2>
                        <Link to={data.link} onClick={() => setCurrentCategorie(data,"categorie")} replace className="shrink-0 block flex items-center text-sm md:text-xl text-[#ff00ff] space-x-1 md:space-x-3">
                            <span className="">Voir tout </span>
                            <svg className="h-4 w-4 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className="mt-10">
                    <Swiper
                        breakpoints={{
                            0: {
                                width: 0,
                                slidesPerView:2
                            },
                            640: {
                                width: 640,
                                slidesPerView:2
                            },
                            768: {
                                width: 768,
                                slidesPerView:2
                            },
                            1024: {
                                width: 1024,
                                slidesPerView:3
                            },
                            1280: {
                                width: 1280,
                                slidesPerView:4
                            },
                            1536: {
                                width: 1536,
                                slidesPerView:4
                            },
                        }}
                        spaceBetween={50}
                        slidesPerView={4}
                        className=""
                    >
                        {data.Blogs.length ? 
                            data.Blogs.map(item => (
                            <SwiperSlide>
                            <div className="flex flex-col space-y-5 mr-5">
                                <div className="relative h-48 md:h-56 w-full bg-gray-300 rounded-md overflow-hidden">
                                    <Link to={`${data.link}/${item.slug}`}>
                                        <div className="absolute z-10 inset-0 grid place-content-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 stroke-current text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                        </div>
                                        <img className="absolute z-40 transition-opacity ease-in delay-150 duration-500 cursor-pointer h-full w-full object-cover" src={`${config.API_URL}${item.File && item.File.url ? item.File.url : null}`} onError={(e) => e.target.style.display = "none"} alt={item.title}/>
                                    </Link>
                                </div>
                                <div className="space-y-5">
                                <div className="font-sans flex space-x-5">
                                            <Link to={`${data.link}`} className="inline-flex items-center text-gray-400 ">
                                                <svg className="h-4 w-4 stroke-current text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                                </svg>
                                                <span className="ml-2 text-md">{data.title}</span>
                                            </Link>
                                            <p className="inline-flex items-center text-gray-400">
                                                <svg className="h-4 w- stroke-current text-[#ff00ff]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <span className="ml-2 text-dm">
                                                    {moment(item.date_publication).format('LL')}
                                                </span>
                                            </p>
                                        </div>
                                    <div>
                                        <Link to={`${data.link}/${item.slug}`}>
                                            <p className="md:text-[1.275rem] text-gray-700 dark:text-gray-300 text-left text-ellipsis overflow-hidden max-h-32">{item.title}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>
                            ))
                        : null}

                    </Swiper>
                </div>
            </div>)
}