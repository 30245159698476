import axios from 'axios';
import config from '../../../../config/conifg.json';
const API_URL = config.API_URL;
class UserService {
    async getAllUser() {
        const {data} = await axios.get(`${API_URL}/user/all`);
        return data;
    }

    async addUser(value) {
        const {data} = await axios.post(`${API_URL}/user/ajout`);
        return data;
    }

    async updateUser(id) {
        const {data} = await axios.put(`${API_URL}/user/update/${id}`);
        return data;
    }

    async getUserById(id) {
        const {data} = await axios.get(`${API_URL}/user/id/${id}`);
        return data;
    }

    async deleteUser(id) {
        const {data} = await axios.delete(`${API_URL}/user/delete/${id}`);
        return data;
    }
}

export default new UserService();