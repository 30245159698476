import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AppContext } from '../../../../appProvider';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import config from '../../../../config/conifg.json';
import axios from 'axios';
import NotificationList from '../../../../shared/notification/notificationList';

export default function Signin(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const {isAuthenticatedClient,initializeClient, role, setNotification} = useContext(AppContext);
    let from = location.state?.from?.pathname || "/admin/dashboard";

    if (isAuthenticatedClient && role == "ADMIN") {
        return (<Navigate to={from} state={{from:location}} replace={true}/>)
    }

    return (<>
                <NotificationList/>
                <div className="bg-gray-200 w-full h-screen">
                    <div className="grid content-center max-w-sm mx-auto  h-screen">
                        <div className=" p-5 bg-white rounded">
                            <h2 className="text-4xl font-bold text-gray-700 text-center">S'identifier</h2>
                            <Formik
                                initialValues={{
                                    email: "",
                                    password: "",
                                }}

                                validationSchema={Yup.object({
                                    email: Yup.string().email("Doit etre un email valide").required("Email requis"),
                                    password: Yup.string().max(255,"doit etre inferieur à 255 caractères").required("Mot de passe requis")
                                })}

                                onSubmit={(values) => {
                                    axios.post(`${config.API_URL}/admin/signin`, values)
                                        .then(async response => {
                                            if (response.data.token) {
                                                if(await initializeClient(response.data.token)) {
                                                    navigate("/admin/dashboard",{replace:true})
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            if (error && error.response) {
                                                setNotification({
                                                    code: 1,
                                                    content: error.response.data.message,
                                                    title: "Erreur"
                                                })
                                            } else {
                                                setNotification({
                                                    code: 1,
                                                    content: error.message,
                                                    title: "Erreur"
                                                })
                                            }
                                        })
                                }}
                            >
                                {(formik) => (
                                    <Form className="space-y-5 mt-10" onSubmit={formik.handleSubmit}>
                                        <div className="w-full space-y-3">
                                            <label className="w-full font-semibold text-gray-700 block">Email</label>
                                            <input  id="email"
                                                    name="email"
                                                    type="text" 
                                                    className="w-full block border border-gray-300 placeholder:italic placeholder:text-gray-400 py-2 px-3 rounded outline-none focus:ring-2 focus:ring-blue-500" 
                                                    placeholder="Nom d'utilisateur ..."
                                                    {...formik.getFieldProps("email")}/>
                                        </div>
                                        <div className="w-full space-y-3">
                                            <label className="font-semibold text-gray-700 full text-gray-700 block">Mot de passe</label>
                                            <input  type="password" 
                                                    className="text-gray-700 w-full block border border-gray-300 placeholder:italic placeholder:text-gray-400 py-2 px-3 rounded outline-none focus:ring-2 focus:ring-blue-500" 
                                                    placeholder='Votre mot de passe ...'
                                                    {...formik.getFieldProps("password")}/>
                                        </div>
                                        <div>
                                            <button     type="submit" 
                                                        className="w-full py-2 px-4 bg-[#ff00ff] text-white font-semibold text-lg rounded">Connexion</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            
                        </div>
                    </div>
                </div>
            </>)
}