import React, { useEffect, useState, useContext, useRef } from 'react';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import blogService from '../../../private/admin/pages/blog/blogService';
import {convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { AppContext } from '../../../appProvider';
import config from "../../../config/conifg.json";
import moment from 'moment';
import 'moment/locale/fr';
import {Helmet} from 'react-helmet';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Recaptcha from 'react-google-invisible-recaptcha';
import contactService from '../contact/contactService';

moment.locale('fr')

const hashConfig = {
    trigger: '#',
    separator: ' ',
}

const directional = false


function CheckCircleIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-green-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function WarningIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-yellow-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>)
}

function DangerIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-red-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function InfoIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

let status = [
    {
        textColor: 'text-yellow-500',
        icon: <WarningIcon/>,
    },
    {
        textColor: 'text-red-500',
        icon: <DangerIcon/>,
    },
    {
        textColor: 'text-blue-500',
        icon: <InfoIcon/>
    },
    {
        textColor : 'text-green-500',
        icon : <CheckCircleIcon/>,
    }
]


export default function BlogDetail(props) {
    let params = useParams();
    const {currentCategorie} = useContext(AppContext)
    const [blog, setBlog] = useState(null);
    const formikRef = useRef(null);
    const recaptchaRef = useRef();
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const [currentNotification, setCurrentNotification] = useState(null);
    const [showForm, setShowForm] = useState(true);




    useEffect(() => {
        blogService.getOneBlogBySlug(params.id)
            .then(response => {
                setBlog(response.data);
                if (response.data.entreprise_email) {
                    formikRef.current.setFieldValue("titre_article", response.data.title);
                    formikRef.current.setFieldValue("entreprise_email", response.data.entreprise_email);
                }

                
            })
            .catch(error => {
                console.error()
            })
    },[]);

    const closeCurrentNotification =() => {
        setCurrentNotification(null);
    }

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    const handleResolved = () => {
        contactService.sendEntrepriseMessage(formikRef.current.values)
                                .then(response => {
                                    setShowForm(false);
                                    setCurrentNotification({
                                        code: 3,
                                        content: response.message,
                                        title: "Succès"
                                    })
                                })
                                .catch(error => {
                                    if (error && error.response) {
                                        setCurrentNotification({
                                            code: 1,
                                            content: error.response.data.message,
                                            title: "Error"
                                        })
                                    } else {
                                        setCurrentNotification({
                                            code: 1,
                                            content: error.message,
                                            title: "Error"
                                        })
                                    }
                                    console.error(error);
                                })
    }

    return (<div className="font-['Verdana']">
                <Helmet>
                    <title>{`${ blog?.title ? blog?.title : ""} | ${blog?.excerpt ? blog?.excerpt : ""} | BE MEDIA TV- La chaine 100% digitale`}</title>
                </Helmet>
                {/* <div className="">
                <div className="mx-5 sm:mx-20 lg:max-w-5xl xl:max-x-7xl lg:mx-auto flex items-center justify-between  py-10">
                        <h2 className="text-3xl font-bold text-gray-700">Details</h2>
                        <div className="flex items-center">
                            <div className="inset-y-0">
                                <Link to="#"><span className="text-semibold text-gray-700 hover:text-red-500">Accueil</span></Link>
                            </div>
                            <div className="inset-y-0">
                                <svg className="h-5 w-5 stroke-current text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                            <div className="inset-y-0">
                            <Link to="#"><span className="text-semibold text-gray-700 hover:text-red-500">Detail</span></Link>
                            </div>
                        </div>
                </div>
                </div> */}
                <div className="bg-white mb-20 dark:bg-slate-800">
                    {blog ? 
                    <div className="mt-10  mx-5 sm:mx-20 lg:max-w-5xl lg:mx-auto">
                        <div className="space-y-5">
                            <h2 className="text-gray-700 text-lg md:text-3xl font-bold dark:text-gray-300">{blog.title}</h2>
                            <div className="flex">
                                <div className="bg-yellow-500 text-white rounded-full font-bold py-1 px-5">{blog && blog.Categories && blog.Categories.length ? blog.Categories[0].title : "Non catégorisé"}</div>
                            </div>
                            <div className="flex items-center space-x-5 font-sans overflow-hiddent">
                                <p className="text-md md:text-xl text-gray-600 font-semiblod dark:text-gray-300 truncate">{blog && blog.Auteur ? `${blog.Auteur.nom} ${blog.Auteur.prenom}` : "Aucun Auteur"}</p>
                                <p className="shrink-0 text-sm text-gray-700 font-bold dark:text-[#ff00ff]"> |  {moment(blog.date_publication).format('LL')}</p>
                            </div>
                        </div>
                        <div className="mt-10 font-['Verdana'] text-sm md:text-[1rem] text-gray-700 dark:text-gray-300 text-justify prose-base ">
                            {parse(blog.article)}
                        </div>

                        {blog.entreprise_email ?

                            <div className="max-w-xl mx-auto mt-10">
                            {
                                currentNotification ? 
                                <div className="">
                                    <div className="relative flex items-center bg-gray-200  p-5 space-x-5">
                                        <div className="absolute top-2 right-2">
                                            <button onClick={() =>closeCurrentNotification()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 fill-current" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                            </svg>
                                            </button>
                                        </div>
                                        <div>
                                            {status[currentNotification.code].icon}
                                        </div>
                                        <div>
                                            <h2 className={`${status[currentNotification.code].textColor} text-lg font-bold`}>{currentNotification.title}</h2>
                                            <p className=""> {currentNotification.content}</p>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {showForm ? 
                            <>
                            <h2 className="text-4xl text-gray-700 font-bold text-center mt-5 dark:text-gray-300">Contact</h2>
                            <Formik
                                innerRef={formikRef}
                                initialValues={{
                                    nom: "",
                                    prenom:"",
                                    email:"",
                                    message:"",
                                    titre_article:"",
                                    entreprise_email: ""
                                }}

                                validationSchema={Yup.object({
                                    nom: Yup.string().required("Nom réquis"),
                                    prenom: Yup.string().required("Prénom réquis"),
                                    email: Yup.string().email("Email doit être un valide email").required("Email réquis"),
                                    message: Yup.string().required("Message réquis"),
                                })}

                                onSubmit={(values) => {
                                    try {
                                        recaptchaRef.current.callbacks.execute()
                                    } catch (error) {
                                        setCurrentNotification({
                                            code: 1,
                                            content: error.message,
                                            title: "Error"
                                        })
                                    }
                                
                                }}
                            >{(formik) => (
                                <form className="space-y-7 text-left mt-10" onSubmit={formik.handleSubmit}>
                                    <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-5">
                                        <div className='w-full relative'>
                                            <label className="block text-lg text-semibold text-gray-700 dark:text-gray-400">Nom<span className='text-red-700'>*</span></label>
                                            <input  id="nom"
                                                    name="nom"
                                                    type="text" 
                                                    {...formik.getFieldProps("nom")}
                                                    onFocus={() => handleDidFocus("nom")}
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${!!didFocus && currentField == "nom" && formik.errors.nom || formik.touched.nom && formik.errors.nom ? "border-red-500" : null } `}/>
                                                    {!!didFocus && currentField == "nom" && formik.errors.nom || formik.touched.nom && formik.errors.nom ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.nom}</span> : null }
                                        </div>
                                        <div className='w-full relative'>
                                            <label className="block text-lg text-semibold text-gray-700 dark:text-gray-400">Prenom <span className='text-red-700'>*</span></label>
                                            <input  id="prenom"
                                                    name="prenom"
                                                    type="text"
                                                    {...formik.getFieldProps("prenom")} 
                                                    onFocus={() => handleDidFocus("prenom")}
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${!!didFocus && currentField == "prenom" && formik.errors.prenom || formik.touched.prenom && formik.errors.prenom ? "border-red-500" : null } `}/>
                                                    {!!didFocus && currentField == "prenom" && formik.errors.prenom || formik.touched.prenom && formik.errors.prenom ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.prenom}</span> : null }

                                        </div>
                                    </div>

                                    <div className="w-full relative">
                                        <label className="block text-lg text-semibold text-gray-700 dark:text-gray-400">Email <span className='text-red-700'>*</span></label>
                                        <input  id="email" 
                                                name="email"
                                                type="email" 
                                                {...formik.getFieldProps("email")} 
                                                onFocus={() => handleDidFocus("email")}
                                                className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border-red-500" : null } `}/>
                                                {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.email}</span> : null }
                                    </div>

                                    <div className='w-full relative'>
                                        <label className="block text-lg text-semibold text-gray-700 dark:text-gray-400">Message <span className='text-red-700'>*</span></label>
                                        <textarea  id="message" 
                                                name="message" 
                                                type="message" 
                                                {...formik.getFieldProps("message")}
                                                onFocus={() => handleDidFocus("message")}
                                                className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${!!didFocus && currentField == "message" && formik.errors.message || formik.touched.message && formik.errors.message ? "border-red-500" : null } `}/>
                                                {!!didFocus && currentField == "email" && formik.errors.message || formik.touched.message && formik.errors.message ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.message}</span> : null }
                                    </div>

                                    <div className="space-x-10">
                                        <button type="submit" className="py-2 px-4 bg-[#ff00ff] text-white w-full">Envoyer</button>
                                    </div>
                                </form>
                            )}</Formik>
                            </>
                            : null}
                            <Recaptcha
                                ref={recaptchaRef}
                                // sitekey="6LdEreQeAAAAAEZ5VL2AW-0vlOZE2LJyrZdTIiUV"
                                sitekey="6LfqyiIUAAAAAMsIxtdyit8rvpi2jnETS1dU6EZL"
                                onResolved={handleResolved}
                                />
                            </div>
                            
                        :null}

                    </div>
                    : null}
                </div>
            </div>)
}