import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;
class CategorieService {
    async getAllCategorie() {
        const {data} = await axios.get(`${API_URL}/categorie/all`);
        return data;
    }

    async getAllCategorieOrderASC() {
        const {data} = await axios.get(`${API_URL}/categorie/all-order-asc`);
        return data;
    }

    async getCurrentCategorie(id) {
        const {data} = await axios.get(`${API_URL}/categorie/${id}`);
        return data;
    }

    async getCategorieById(id) {
        const {data} = await axios.get(`${API_URL}/categorie/id/${id}`);
        return data;
    }

    async createCategorie(values) {
        const {data} = await axios.post(`${API_URL}/categorie/create`, values);
        return data;
    }

    async getOneCategoreByLink(link) {
        const {data} = await axios.get(`${API_URL}/categorie/link/${link}`);
        return data;
    }

    async deleteOneCategoryById(id) {
        const {data} = await axios.delete(`${API_URL}/categorie/delete/${id}`);
        return data;
    }

    async organiserCategorieMenu(value) {
        const {data} = await axios.post(`${API_URL}/categorie/organiser`, value);
        return data;
    }

    async countCategorie() {
        const {data} = await axios.get(`${API_URL}/categorie/count`);
        return data;
    }

}

export default new CategorieService();