import axios from 'axios';
import config from '../../../../config/conifg.json'

const API_URL = config.API_URL;

class confirmationService {
    async confirmationCompte(values) {
        const {data} = await axios.post(`${API_URL}/client/confirmation-de-compte`, {token : values});
        return data;
    }
}

export default new confirmationService();