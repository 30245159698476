import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;
class SubCategorieService {
    async getAllSubCategorie() {
        const {data} = await axios.get(`${API_URL}/sous-categorie/all`);
        return data;
    }

    async getAllSubCategorieGroupByCategorie() {
        const {data} = await axios.get(`${API_URL}/sous-categorie/all/groupby`);
        return data;
    }

    async getCurrentSubCategorie(id) {
        const {data} = await axios.get(`${API_URL}/sous-categorie/${id}`);
        return data;
    }

    async getSubCategorieById(id) {
        const {data} = await axios.get(`${API_URL}/sous-categorie/id/${id}`);
        return data;
    }

    async createSubCategorie(values) {
        const {data} = await axios.post(`${API_URL}/sous-categorie/create`, values);
        return data;
    }

    async getOneSubCategoreByLink(link) {
        const {data} = await axios.get(`${API_URL}/sous-categorie/link/${link}`);
        return data;
    }

    async deleteOneSubCategoryById(id) {
        const {data} = await axios.delete(`${API_URL}/sous-categorie/delete/${id}`);
        return data;
    }

    async organiserSubCategorieMenu(value) {
        const {data} = await axios.post(`${API_URL}/sous-categorie/organiser`, value);
        return data;
    }

    async countSubCategorie() {
        const {data} = await axios.get(`${API_URL}/sous-categorie/count`);
        return data;
    }
}

export default new SubCategorieService();