import React from 'react';
export const PageContext = React.createContext();
export default class PageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage : null
        }
        this.setCurrentPage = this.setCurrentPage.bind(this);
    }
    setCurrentPage(value) {
        this.setState({currentPage:value});
    }

    render() {
        const {title, article} = this.state;
        const {children} = this.props;
        const configObject ={
            title,
            article,
            setCurrentPage: (value) => this.setCurrentPage(value)
        }

        return  <PageContext.Provider value={configObject}>
                    {children}
                </PageContext.Provider>
    }
}