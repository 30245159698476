import React, {useContext, useEffect, useRef, useState} from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom'; 
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import signupService from './signupService';
import { AppContext } from '../../../../appProvider';
import Multiselect from '../../../../shared/dropdown/dropdown';

function CheckCircleIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-green-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function WarningIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-yellow-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>)
}

function DangerIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-red-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function InfoIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

let status = [
    {
        textColor: 'text-yellow-500',
        icon: <WarningIcon/>,
    },
    {
        textColor: 'text-red-500',
        icon: <DangerIcon/>,
    },
    {
        textColor: 'text-blue-500',
        icon: <InfoIcon/>
    },
    {
        textColor : 'text-green-500',
        icon : <CheckCircleIcon/>,
    }
]

const all_categories = ['john', 'milos', 'steph', 'kathreine'];

export default function Signup(props) {
    const {isAuthenticatedClient} = useContext(AppContext);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [currentNotification, setCurrentNotification] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const formikRef = useRef();

    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    useEffect(() => {
        signupService.getAllCategorie()
            .then(response => {
                if (response.data.length) {
                    setCategories(response.data.map(item => ({id: item.id,text: item.title, value: item.id})))
                }
            })
            .catch(error => {
                console.error(error);
            })
    },[])

    if (isAuthenticatedClient) {
        return (<Navigate to={from} state={{from:location}} replace={true}/>)
    }

    const closeCurrentNotification =() => {
        setCurrentNotification(null);
    }

    const handleSelectedCategories = (values) => {
        setSelectedCategories(values)
        let data = values.map(item =>  item.id);
        formikRef.current.setFieldValue('categories', data);
    }

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    return (<div>
                <div className='mx-5 md:max-w-md md:mx-auto mt-10 mb-12'>

                {
                    currentNotification ? 
                    <div className="">
                        <div className="relative flex items-center bg-gray-200  p-5 space-x-5">
                            <div className="absolute top-2 right-2">
                                <button onClick={() =>closeCurrentNotification()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 fill-current" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                                </button>
                            </div>
                            <div>
                                {status[currentNotification.code].icon}
                            </div>
                            <div>
                                <h2 className={`${status[currentNotification.code].textColor} text-lg font-bold`}>{currentNotification.title}</h2>
                                <p className=""> {currentNotification.content}</p>
                            </div>
                        </div>
                    </div>
                    : null
                }
                

                    {showForm ? 
                        <h2 className="dark:text-gray-300 text-3xl text-gray-700 font-bold text-center mt-5">Inscription</h2>
                    : null}
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            nom: "",
                            prenom:"",
                            email:"",
                            password:"",
                            categories:"",
                            confirmation:"",
                            prevent_exclusive_blog: true,
                            prevent_public_blog: true
                        }}

                        validationSchema={Yup.object({
                            nom: Yup.string().matches(/^[a-zA-Z\s]+$/, {message: "Ne doit comporter que des lettres"}).required("Nom requis"),
                            prenom: Yup.string().matches(/^[a-zA-Z\s]+$/, {message: "Ne doit comporter que des lettres"}).required("Prenom requis"),
                            email: Yup.string().email("Email doit être un valide email").required("Email requis"),
                            password: Yup.string().required("Mot de passe requis"),
                            confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Le mote de passe doit correspondre').required("Confirmation requis"),
                            // categories: Yup.array().of(Yup.string()).required()
                            // categories: Yup.array().of(Yup.string())
                        })}

                        onSubmit={(values) => {
                            signupService.createUser(values)
                                .then(response => {
                                    if (response) {
                                        setCurrentNotification({
                                            code: 3,
                                            content: response.message,
                                            title: "Succès"
                                        })

                                        setShowForm(false);
                                    }
                                })
                                .catch(error => {
                                    if (error && error.response) {
                                        setCurrentNotification({
                                            code: 1,
                                            content: error.response.data.message,
                                            title: "Error"
                                        })
                                    } else {
                                        setCurrentNotification({
                                            code: 1,
                                            content: error.message,
                                            title: "Error"
                                        })
                                    }
                                    console.error(error)
                                })
                        }}
                    >{(formik) => (
                        <>
                        {showForm ? 
                        <Form className="space-y-5 text-left mt-10" onSubmit={formik.handleSubmit}>
                            <div className="flex flex-col space-y-5 md:flex-row md:space-x-5 md:space-y-0">
                                <div className='w-full relative'>
                                    <label className="dark:text-gray-400 block text-lg text-semibold text-gray-700">Nom<span className='text-red-700'>*</span></label>
                                    <input  id="nom"
                                            name="nom"
                                            type="text" 
                                            {...formik.getFieldProps("nom")}
                                            onFocus={() => handleDidFocus("nom")}
                                            className={`bg-gray-200 py-1.5 px-3 w-full outline-none border-b ${!!didFocus && currentField == "nom" && formik.errors.nom || formik.touched.nom && formik.errors.nom ? "border-red-500" : null } `}/>
                                            {!!didFocus && currentField == "nom" && formik.errors.nom || formik.touched.nom && formik.errors.nom ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.nom}</span> : null }
                                </div>
                                <div className='w-full relative'>
                                    <label className="dark:text-gray-400 block text-lg text-semibold text-gray-700">Prenom <span className='text-red-700'>*</span></label>
                                    <input  id="prenom"
                                            name="prenom"
                                            type="text"
                                            {...formik.getFieldProps("prenom")} 
                                            onFocus={() => handleDidFocus("prenom")}
                                            className={`bg-gray-200 py-1.5 px-3 w-full outline-none border-b ${!!didFocus && currentField == "prenom" && formik.errors.prenom || formik.touched.prenom && formik.errors.prenom ? "border-red-500" : null } `}/>
                                            {!!didFocus && currentField == "prenom" && formik.errors.prenom || formik.touched.prenom && formik.errors.prenom ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.prenom}</span> : null }
                                </div>
                            </div>

                            <div className="relative">
                                <label className="dark:text-gray-400 block text-lg text-semibold text-gray-700">Email <span className='text-red-700'>*</span></label>
                                <input  id="email" 
                                        name="email"
                                        type="email" 
                                        {...formik.getFieldProps("email")}
                                        onFocus={() => handleDidFocus("email")}
                                        className={`bg-gray-200 py-1.5 px-3 w-full outline-none border-b ${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border-red-500" : null } `}/>
                                        {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.email}</span> : null }
                            </div>

                            <div className="flex flex-col space-y-5 md:flex-row md:space-x-5 md:space-y-0">
                                <div className='w-full relative'>
                                    <label className="dark:text-gray-400 block text-lg text-semibold text-gray-700">Mot de passe <span className='text-red-700'>*</span></label>
                                    <input  id="password" 
                                            name="password" 
                                            type="password" 
                                            {...formik.getFieldProps("password")}
                                            onFocus={() => handleDidFocus("password")}
                                            className={`bg-gray-200 py-1.5 px-3 w-full outline-none border-b ${!!didFocus && currentField == "password" && formik.errors.password || formik.touched.password && formik.errors.password ? "border-red-500" : null } `}/>
                                            {!!didFocus && currentField == "password" && formik.errors.password || formik.touched.password && formik.errors.password ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.password}</span> : null }
                                </div>
                                <div className='w-full relative'>
                                    <label className="dark:text-gray-400 block text-lg text-semibold text-gray-700">Confirmation <span className='text-red-700'>*</span></label>
                                    <input  id="confirmation" 
                                            name="confirmation"
                                            type="password" 
                                            {...formik.getFieldProps("confirmation")}
                                            onFocus={() => handleDidFocus("confirmation")}
                                            className={`bg-gray-200 py-1.5 px-3 w-full outline-none border-b ${!!didFocus && currentField == "confirmation" && formik.errors.confirmation || formik.touched.confirmation && formik.errors.confirmation ? "border-red-500" : null } `}/>
                                            {!!didFocus && currentField == "confirmation" && formik.errors.confirmation || formik.touched.confirmation && formik.errors.confirmation ?<span className="absolute left-0 top-[101%] text-red-500 text-sm">{formik.errors.confirmation}</span> : null }
                                </div>
                            </div>
                            
                            {/* <div className=''> */}
                                {/* <label className="dark:text-gray-400 text-gray-700 text-lg text-semibold">Catégorie(s)</label> */}
                                {/* <select     name="categories" 
                                            id="categories"
                                            className="bg-gray-200 py-1.5 px-3 w-full outline-none"
                                            multiple
                                            {...formik.getFieldProps("categories")}>
                                    <option value="">--veuillez choisir une option--</option>
                                    {categories.map(item => (<option value={item.id}>{item.title}</option>))}
                                </select> */}


                                {/*vrai select */}
                                {/* <Multiselect items={categories} setItems={setCategories} selectedItems={selectedCategories} setSelected={handleSelectedCategories}/> */}
                            {/* </div> */}

                            <div className=''>
                                <input      type="checkbox"
                                            name="prevent_exclusive_blog" 
                                            id="prevent_exclusive_blog"
                                            className="bg-gray-200 mr-2  outline-none"
                                            defaultChecked={formik.values.prevent_exclusive_blog}
                                            {...formik.getFieldProps("prevent_exclusive_blog")}>
                                </input>
                                <label className="dark:text-gray-400 text-gray-700 text-lg text-semibold">Prévenir en cas de nouvel article exclusif</label>
                            </div>

                            <div className=''>
                                <input      type="checkbox"
                                            name="prevent_public_blog" 
                                            id="prevent_public_blog"
                                            className="bg-gray-200 mr-2  outline-none"
                                            defaultChecked={formik.values.prevent_public_blog}
                                            {...formik.getFieldProps("prevent_public_blog")}>
                                </input>
                                <label className="dark:text-gray-400 text-gray-700 text-lg text-semibold">Prévenir en cas de nouvel article</label>
                            </div>

                            <div className="space-x-10">
                                <button type="submit" className="py-1.5 px-4 bg-[#ff00ff] text-white w-full">S'inscrire</button>
                            </div>
                        </Form>
                        : null}</>
                    )}</Formik>
                    <div className='mt-10 text-center'>
                        <p className='dark:text-gray-400'>Vous avez déjà un compte? <Link to="/identification"><a><span className="text-[#ff00ff] font-bold text-xl">Identification</span></a></Link></p>
                    </div>
                </div>
            </div>)
}