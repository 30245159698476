import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'chart.js/auto';
import config from '../../../../config/conifg.json';
import categorieService from '../categorie/categorieService';
import blogService from '../blog/blogService';
import tagService from '../tag/tagService';
import subCategorieService from '../subCategorie.js/subCategorieService';

const labels = ["January", "February", "March", "April", "May", "June"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "hsl(252, 82.9%, 67.8%)",
        borderColor: "hsl(252, 82.9%, 67.8%)",
        data: [0, 10, 5, 2, 20, 30, 45],
      },
    ],
  };

  const configLineChart = {
    type: "line",
    data,
    options: {},
  };

export default function Dashboard(props) {
    const ref = useRef(null);
    const [blogs, setBlogs] = useState([]);
    const [blogCount, setBlogCount] = useState(null);
    const [tagCount, setTagCount] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categorieCount, setCategorieCount] = useState(null);
    const [subCategorieCount, setSubCategorieCount] = useState(null);

    useEffect(()=> {
        tagService.countTag()
            .then(response => {
                setTagCount(response.data)
            })
        .catch(error => {

        })
        blogService.countBlog()
            .then(response => {
                setBlogCount(response.data)
            })
            .catch(error => {

            })
        categorieService.getAllCategorieOrderASC()
            .then(response => {
                setCategories(response.data)
            })
            .catch(error => {

            })
        
            blogService.getLastBlog(3)
                .then(response => {
                    setBlogs(response.data);
                })
                .catch(error => {

                })
        subCategorieService.countSubCategorie()
            .then(response => {
                setSubCategorieCount(response.data);
            })
            .catch(error => {

        })

        categorieService.countCategorie()
        .then(response => {
            setCategorieCount(response.data);
        })
    },[])

    useEffect(() => {
        if (ref && ref.current) {
            new Chart(ref.current,configLineChart)
        }
    },[ref])

    const refreshLastblog = () => {
            blogService.getLastBlog(3)
                .then(response => {
                    setBlogs(response.data);
                })
                .catch(error => {

                })
    }

    const refreshCategorie = () => {
        categorieService.getAllCategorieOrderASC()
            .then(response => {
                setCategories(response.data)
            })
            .catch(error => {

            })
    }

    return (<div className="m-10">
                <div>
                    <h2 className="text-2xl text-gray-700 font-semibold">Tableau de bord</h2>
                    <div className="grid sm:grid-cols-2 md:grid-cols-5 gap-5 mt-10">
                        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-3 md:col-span-3">
                            <div className="grid bg-white rounded-xl w-full h-56 text-center content-center">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-700 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                        <span className="">Article Brouillon</span>
                                    </div>
                                {blogCount?.draft_blog_count ? 
                                <span className="text-6xl text-gray-700 font-semibold">{blogCount?.draft_blog_count}</span>
                                : <span className="text-md text-gray-700 font-semibold">Loading ...</span>}
                                
                            </div>

                            <div className="grid bg-white rounded-xl w-full h-56 text-center content-center">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-700 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                        <span className="">Article Publié</span>
                                    </div>
                                    {blogCount?.published_blog_count ? 
                                    <span className="text-6xl text-gray-700 font-semibold">{blogCount?.published_blog_count}</span>
                                : <span className="text-md text-gray-700 font-semibold">Loading ...</span>}
                            </div>
                                
                            <div className="grid bg-white rounded-xl w-full h-56 text-center content-center">
                                <div>
                                    <svg className="h-12 w-12 mx-auto text-gray-700 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                                    </svg>
                                    <span className="">Catégories</span>
                                </div>
                                {categorieCount?.categorie_count? 
                                <span className="text-6xl text-gray-700 font-semibold">{categorieCount?.categorie_count}</span>
                                : <span className="text-md text-gray-700 font-semibold">Loading ...</span>}
                            </div>

                            <div className="grid bg-white rounded-xl w-full h-56 text-center content-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-700 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                    </svg>
                                    <span className="">Sous Catégorie</span>
                                </div>
                                {subCategorieCount?.subcategorie_count? 
                                <span className="text-6xl text-gray-700 font-semibold">{subCategorieCount?.subcategorie_count}</span>
                                : <span className="text-md text-gray-700 font-semibold">Loading ...</span>}
                            </div>

                            <div className="grid bg-white rounded-xl w-full h-56 text-center content-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-700 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                    </svg>
                                    <span className="">Tags</span>
                                </div>
                                {tagCount?.tag_count? 
                                <span className="text-6xl text-gray-700 font-semibold">{tagCount?.tag_count}</span>
                                : <span className="text-md text-gray-700 font-semibold">Loading ...</span>}
                            </div>
                            
                            <div className="bg-white rounded-xl w-full h-56">

                            </div>
                        </div>
                        <div className="bg-white rounded-xl md:col-span-2">
                            <div className="m-5">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md text-gray-700 font-semibold">Dernières articles</h2>
                                    <svg onClick={refreshLastblog} className="cursor-pointer h-5 w-5 stroke-current text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>
                                </div>
                                <div className="mt-5 space-y-5">
                                    {blogs.length ? 
                                        blogs.map(item => (
                                            <div className="flex space-x-3 items-center">
                                                <div className="relative z-10 h-24 w-24 bg-red-300 rounded flex-shrink-0 overflow-hidden">
                                                    <img className="w-full h-full object-cover" src={`${config.API_URL}${item.File && item.File.url ? item.File.url : null}`} alt="coeur"/>
                                                    <span className="absolute top-1 right-1 rounded-full bg-blue-700 text-white font-semibold px-2">Nouveau</span>
                                                </div>
                                                <p className="text-basic text-gray-700">{item.title}</p>
                                            </div>
                                        ))
                                    : null}
                                </div>
                            </div>
                        </div>
                        </div>

                        
                    </div>
                    <div className="grid md:grid-clos-2 xl:grid-cols-5 gap-10 mt-10 h-96">
                        <div className="bg-white rounded-xl col-span-2">
                            <div className="m-5">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md text-gray-700 font-semibold">Catégories</h2>
                                    <svg onClick={refreshCategorie} className="cursor-pointer h-5 w-5 stroke-current text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>
                                </div>
                                <div className="mt-5">
                                    <div className="grid grid-cols-2 gap-2">
                                        {categories?.length ?
                                        categories.map(item => 
                                            <Link to="" className="text-basic text-gray-700 text-semibold">{item.title}</Link>
                                            )
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="bg-white rounded-xl col-span-3">
                            <div className="m-5">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md text-gray-700 font-semibold">Visite</h2>
                                    <svg className="h-5 w-5 stroke-current text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>
                                </div>
                                <div className="mt-5">
                                <div>
                                <canvas ref={ref}></canvas>
                            </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
            </div>)
}