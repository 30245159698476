import axios from 'axios';
// import * as config from '../../../../config/conifg.json';
import config from '../../../../config/conifg.json'

const API_URL = config.API_URL;
class BlogService {

    async getAllAdminBlog() {
        const {data} = await axios.get(`${API_URL}/blog/admin/all`);
        return data;
    }
    async getAllBlog(values) {
        const {data} = await axios.get(`${API_URL}/blog/all`);
        return data;
    }

    async getAllBlogByCategorie(catID) {
        const {data} = await axios.get(`${API_URL}/blog/c/${catID}`);
        return data;
    }

    async getAllBlogBySubCategorie(catID) {
        const {data} = await axios.get(`${API_URL}/blog/s/${catID}`);
        return data;
    }

    async createBlog(values) {
        const {data} = await axios.post(`${API_URL}/blog/create`, values);
        return data;
    }

    async getOneBlogById(id) {
        const {data} = await axios.get(`${API_URL}/blog/id/${id}`);
        return data;
    }

    async deleteOneBlogById(id) {
        const {data} = await axios.delete(`${API_URL}/blog/delete/${id}`);
        return data;
    }

    async getOneBlogBySlug(slug) {
        const {data} = await axios.get(`${API_URL}/blog/slug/${slug}`);
        return data;
    }

    async getLastBlog(nbr) {
        const {data} = await axios.get(`${config.API_URL}/blog/${nbr}/last`);
        return data;
    }

    async countBlog() {
        const {data} = await axios.get(`${API_URL}/blog/count`);
        return data;
    }
}

export default new BlogService();