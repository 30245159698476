import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import config from '../../../config/conifg.json'
import preferenceService from './preferenceService';
import { AppContext } from '../../../appProvider';

export default function Preference(props) {
    const {client} = useContext(AppContext);
    const [preference,setPreference] = useState([]);
    useEffect(() => {
        if (client) {
            preferenceService.getAllPreferenceByUserId(client.id)
                .then(response => {
                    setPreference(response.data);
                })
                .catch(error => {
                    console.error();
                })
        }
    },[client])
    return (<div>
        <div className="bg-gray-200 py-10">
            <div className="mx-5 sm:mx-20 xl:max-w-7xl xl:mx-auto flex items-center justify-between">
                <h2 className="text-3xl font-bold text-gray-700">Préférences</h2>
            </div>
        </div>
        <div className='mx-5 sm:mx-20 xl:max-w-7xl xl:mx-auto flex items-center mt-5'>
            <Link to="ajout" className='flex space-x-3 items-center py-1 px-4 rounded bg-[#ff00ff] text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                </svg>
                <span>Ajouter vos préférences</span>
            </Link>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 max-w-7xl mx-auto mt-12 mb-20 px-20 xl:px-0">
            {preference && preference?.Categories && preference.Categories.length?
                preference?.Categories.map(categorie => (
                    <div className="flex flex-col">
                    <div className="relative w-full h-72 bg-gray-400 overflow-hidden">
                        <div className="absolute top-3 left-3 bg-yellow-500 rounded-full text-center py-1 px-3 text-semibold text-white">{categorie?.title || null}</div>
                        {categorie?.File ? 
                            <img className="w-full h-full object-cover" src={`${config.API_URL}${categorie?.File.url}`} alt={categorie.title}/>
                        : null}
                    </div>
                    <div>
                        <div className="mt-10">
                            <Link to={`/${categorie.slug}`}>
                                <p className="text-gray-700 text-3xl font-semibold hover:text-red-500">{categorie.title}</p>
                            </Link>
                        </div>
                    </div>
                </div>
                ))
            
            
            : null}
        </div>
    </div>)
}