import React, { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navbarService from "./navbarService";
import { Menu, Transition, Disclosure, Dialog } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../appProvider";
import { slugify } from "../../utils/slugify";
import logo_bardel from "../../../assets/images/logo.png";
import logo_bardel_white from "../../../assets/images/logo_white.png";
import PopupInscription from "./popupInscription";

function ProfileInactiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="#EDE9FE"
            viewBox="0 0 24 24"
            stroke="#fb7185"
            strokeWidth="2"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
        </svg>
    );
}

function ProfileActiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="#fb7185"
            stroke="#EDE9FE"
            viewBox="0 0 24 24"
            strokeWidth="2"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
        </svg>
    );
}

function LogoutInactiveIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="#EDE9FE"
            viewBox="0 0 24 24"
            stroke="#fb7185"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
        </svg>
    );
}

function LogoutActiveIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="#fb7185"
            viewBox="0 0 24 24"
            stroke="#EDE9FE"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
        </svg>
    );
}

function SettingInactiveIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="#EDE9FE"
            viewBox="0 0 24 24"
            stroke="#fb7185"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
        </svg>
    );
}

function SettingActiveIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="#fb7185"
            viewBox="0 0 24 24"
            stroke="#EDE9FE"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
        </svg>
    );
}

export default function Navbar(props) {
    const {
        isAuthenticatedClient,
        setAuthenticatedClient,
        setCurrentCategorie,
        clientSearch,
        duration,
        setDuration,
        setDarkMode,
        darkMode,
        logout,
        setHistorique,
        client,
    } = useContext(AppContext);

    const [categories, setCategories] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [searchDataSlugify, setSearchDataSlugify] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const searchButtonRef = useRef(null);
    const [searchFocus, setSearchFocus] = useState(false);
    useEffect(() => {
        window.addEventListener("keyup", (e) => {
            if (searchFocus) {
                if (e.keyCode == 13) {
                    e.preventDefault();
                    searchButtonRef.current.click();
                }
            }
        });
    }, [searchFocus]);

    useEffect(() => {
        if (isAuthenticatedClient && client) {
            navbarService
                .createHistory({
                    utilisateurId: client.id,
                    location: location.pathname,
                })
                .then((response) => {})
                .catch((error) => {
                    console.error();
                });
        }
    }, [location, client, isAuthenticatedClient]);

    useEffect(() => {
        navbarService
            .getAllCategorieOrderASC()
            .then((response) => {
                if (response.data.length) {
                    setCategories(response.data);
                }
            })
            .catch((error) => {
                console.error("error : ", error);
            });
    }, []);

    const handleSearchChange = (e) => {
        setSearchData(e.target.value);
        setSearchDataSlugify(slugify(e.target.value));
    };

    return (
        <>  
            <PopupInscription/>
            <div className="font-['Verdana'] flex bg-gray-200 py-2 items-center justify-end">
                <div className="flex space-x-5 mr-5 lg:mr-20 items-center">
                    <div>
                        {darkMode ? (
                            <button
                                className="flex space-x-3 items-center rounded-full border-2 border-[#ff00ff] py-1 px-4"
                                onClick={() => setDarkMode("")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                                    />
                                </svg>
                                Lumineux
                            </button>
                        ) : (
                            <button
                                className="flex space-x-3 items-center rounded-full border-2 border-[#ff00ff] py-1 px-4"
                                onClick={() => setDarkMode("dark")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                    />
                                </svg>
                                Sombre
                            </button>
                        )}
                    </div>
                    <div className="flex space-x-5">
                        <a href="https://lnkd.in/eD3jQCgp">
                            <svg
                                id="Layer_1"
                                viewBox="0 0 512 512"
                                className="h-4 w-4 text-gray-700 fill-current"
                            >
                                <g>
                                    <path d="M308.3,508.5c-2.5,0.1-4.1,0.3-5.7,0.3c-34.2,0-68.3-0.1-102.5,0.1c-4.8,0-6.1-1.3-6.1-6.1c0.1-79.6,0.1-159.3,0.1-238.9   c0-2.1,0-4.2,0-6.9c-18.6,0-36.7,0-55.1,0c0-28.4,0-56.3,0-85c1.9,0,3.7,0,5.4,0c15,0,30-0.1,45,0.1c3.8,0,4.8-1.1,4.8-4.8   c-0.2-22.3-0.2-44.7,0-67c0.1-15.6,2.6-30.8,9.8-44.9c10.3-19.9,26.6-32.8,47.2-40.8c16.8-6.6,34.5-9,52.3-9.3   c29-0.4,58-0.2,87-0.3c2.7,0,4.9-0.1,4.9,3.7c-0.1,27.5-0.1,55-0.1,82.5c0,0.3-0.1,0.6-0.5,1.9c-1.7,0-3.6,0-5.5,0   c-18,0-36-0.1-54,0c-10.4,0-18.8,4.2-24.1,13.3c-1.6,2.7-2.6,6.2-2.6,9.4c-0.3,17,0,34-0.2,51c0,4,1.2,5.1,5.1,5.1   c25-0.2,50-0.1,75-0.1c2,0,3.9,0,7.3,0c-3.5,28.6-6.9,56.6-10.4,84.9c-26,0-51.3,0-77.1,0C308.3,340.8,308.3,424.4,308.3,508.5z" />
                                </g>
                            </svg>
                        </a>
                        <a href="https://lnkd.in/gS7QRsq">
                            <svg
                                enable-background="new 0 0 512 512"
                                id="Layer_1"
                                version="1.1"
                                viewBox="0 0 512 512"
                                className="h-4 w-4 text-gray-700 fill-current"
                            >
                                <g>
                                    <path d="M173.5,508.7c0-2.4,0-4.7,0-7c0-107.7,0-215.3,0-323c0-3-0.2-5.4,4.2-5.4c31.8,0.2,63.7,0.1,95.5,0.1   c0.8,0,1.6,0.2,2.9,0.4c0,14,0,27.8,0,41.7c0.4,0.1,0.7,0.3,1.1,0.4c4-5.2,7.8-10.6,12-15.7c12.2-15,27.5-26,45.5-32.6   c9.8-3.6,20.5-5.8,30.8-6.3c14.2-0.6,28.7-0.4,42.7,2c23,3.9,43.3,14.1,60.1,30.8c15.9,15.7,26.4,34.6,32.1,55.9   c3,11.3,4.9,22.9,6.7,34.4c1.2,8,1.8,16.2,1.8,24.2c0.1,65,0.1,130,0.1,195c0,1.6-0.1,3.2-0.2,5.3c-1.9,0-3.5,0-5.1,0   c-30.7,0-61.3-0.1-92,0.1c-5.1,0-6-1.7-6-6.3c0.1-59.8,0.3-119.7-0.1-179.5c-0.1-10.5-1.1-21.4-3.8-31.5   c-4.9-17.7-16.1-30.5-33.9-37c-15-5.5-30.2-6.5-45.6-2.6c-17,4.3-28.7,15.6-37.5,30.5c-6.8,11.5-8.1,24.2-8.2,36.9   c-0.4,60.7-0.2,121.3-0.2,182c0,2.3,0,4.6,0,7.1C241.9,508.7,208,508.7,173.5,508.7z" />
                                    <path d="M109.1,342.6c0,53.3-0.1,106.6,0.1,159.9c0,4.8-1,6.6-6.3,6.5c-30.5-0.3-61-0.3-91.5,0c-5,0-6.1-1.7-6.1-6.3   c0.1-107.1,0.1-214.3,0-321.4c0-4.3,1.2-5.8,5.6-5.8c30.8,0.2,61.6,0.2,92.5,0c4.8,0,5.7,1.8,5.7,6.1   C109.1,235.3,109.1,289,109.1,342.6z" />
                                    <path d="M56.1,4.8C89,4,108.7,27.9,108.8,57.2c0.1,30-20.9,52.4-53.9,52.3c-30.7,0-52.4-22-52.3-53.1C2.7,27.3,23.8,3.8,56.1,4.8z" />
                                </g>
                            </svg>
                        </a>
                    </div>
                    {!isAuthenticatedClient ? (
                        <div className="bg-[#ff00ff] p-2 rounded">
                            <Link to="/inscription">
                                <a className="text-gray-600 font-semibold hover:text-[#ff00ff]">
                                    <svg
                                        id="Outline"
                                        viewBox="0 0 24 24"
                                        className="h-5 w-5 text-white fill-current bg-[#ff00ff]"
                                    >
                                        <path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" />
                                        <path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" />
                                    </svg>
                                </a>
                            </Link>
                        </div>
                    ) : (
                        <div className="bg-[#ff00ff] z-50 p-2 rounded">
                            <Menu as="div" className="relative">
                                <div>
                                    <Menu.Button>
                                        <svg
                                            id="Outline"
                                            viewBox="0 0 24 24"
                                            className="h-5 w-5 text-white fill-current bg-[#ff00ff]"
                                        >
                                            <path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" />
                                            <path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" />
                                        </svg>
                                    </Menu.Button>
                                </div>
                                <div>
                                    <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg">
                                        <div className="p-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to="profile">
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-[#ff00ff] text-white"
                                                                    : "text-gray-900"
                                                            } group flex  items-center w-full px-2 py-2 text-sm`}
                                                        >
                                                            {active ? (
                                                                <ProfileActiveIcon
                                                                    className="w-5 h-5 mr-2"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <ProfileInactiveIcon
                                                                    className="w-5 h-5 mr-2"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            <span className="ml-2">
                                                                Profile
                                                            </span>
                                                        </button>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to="preference">
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-[#ff00ff] text-white"
                                                                    : "text-gray-900"
                                                            } group flex  items-center w-full px-2 py-2 text-sm`}
                                                        >
                                                            {active ? (
                                                                <SettingActiveIcon
                                                                    className="w-5 h-5 mr-2"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <SettingInactiveIcon
                                                                    className="w-5 h-5 mr-2"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            <span className="ml-2">
                                                                Préferences
                                                            </span>
                                                        </button>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to="historique">
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-[#ff00ff] text-white"
                                                                    : "text-gray-900"
                                                            } group flex  items-center w-full px-2 py-2 text-sm`}
                                                        >
                                                            {active ? (
                                                                <SettingActiveIcon
                                                                    className="w-5 h-5 mr-2"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <SettingInactiveIcon
                                                                    className="w-5 h-5 mr-2"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            <span className="ml-2">
                                                                Historique
                                                            </span>
                                                        </button>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => logout()}
                                                        className={`${
                                                            active
                                                                ? "bg-[#ff00ff] text-white"
                                                                : "text-gray-900"
                                                        } group flex items-center w-full px-2 py-2 text-sm`}
                                                    >
                                                        {active ? (
                                                            <LogoutActiveIcon
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            <LogoutInactiveIcon
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        <span className="ml-2">
                                                            Déconnexion
                                                        </span>
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </div>
                            </Menu>
                        </div>
                    )}
                </div>
            </div>

            <nav className="font-['Verdana'] relative bg-transparent dark:bg-white min-h-[70px]">
                <Disclosure>
                    {({ open }) => (
                        <>
                            <div className="flex mx-5 lg:mx-20 items-center justify-between h-full content-center min-h-[70px]">
                                <div className="hidden lg:block h-full mx-auto">
                                    <ul className="flex justify-center flex-wrap items-center min-h-[70px]">
                                        <li className="active:border-[#ff00ff] my-0 mr-8 border-b-[4px] border-transparent block hover:ease-in-out hover:delay-100 hover:duration-300  hover:border-b-[4px] h-[70px] hover:border-[#ff00ff]   grid content-center">
                                            <Link to="/">
                                                <a className="no-underline text-[#3009fa] text-[14px] font-semibold">
                                                    Accueil
                                                </a>
                                            </Link>
                                        </li>
                                        {/* <div className="grid grid-cols-5 gap-x-3"> */}
                                        {categories.map((item) => {
                                            if (
                                                item.type == "LISTE_ARTICLE" &&
                                                item?.SubCategories.length
                                            ) {
                                                return (
                                                    <Menu
                                                        as="div"
                                                        className="relative inline-block text-left"
                                                    >
                                                        <div className="active:border-[#ff00ff] mr-8 h-full grid content-center border-b-[4px] border-transparent block hover:ease-in-out hover:delay-100 hover:duration-300  hover:border-b-[4px] h-[70px] hover:border-b-4 hover:border-[#ff00ff] active:border-b-4 active:border-[#ff00ff]">
                                                            <Menu.Button className="text-[#3009fa] text-[14px] inline-flex items-end  font-semibold ">
                                                                {item.title}
                                                                <ChevronDownIcon
                                                                    className="h-5 w-5 ml-2 text-[#3009fa] font-semibold"
                                                                    aria-hidden="true"
                                                                />
                                                            </Menu.Button>
                                                        </div>
                                                        <Menu.Items className="absolute z-[1000] w-56 left-0 bg-white divide-y divide-gray-300 last:border-b-4 last:border-[#ff00ff]">
                                                            <Menu.Item>
                                                                <Link
                                                                    to={
                                                                        item.link
                                                                    }
                                                                    className="group flex px-2 py-2 w-full  hover:text-white hover:bg-[#ff00ff] text-[14px] text-center"
                                                                    onClick={() => {
                                                                        setCurrentCategorie(
                                                                            item,
                                                                            "categorie"
                                                                        );
                                                                    }}
                                                                >
                                                                    Tout
                                                                </Link>
                                                            </Menu.Item>
                                                            {item.SubCategories.map(
                                                                (submenu) => (
                                                                    <Menu.Item>
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <Link
                                                                                to={
                                                                                    submenu &&
                                                                                    submenu.link
                                                                                        ? submenu.link
                                                                                        : submenu &&
                                                                                          submenu?.lien
                                                                                        ? submenu.lien
                                                                                        : null
                                                                                }
                                                                                className="group flex px-2 py-2 w-full  hover:text-white hover:bg-[#ff00ff] text-[14px] text-center"
                                                                                onClick={() => {
                                                                                    setCurrentCategorie(
                                                                                        submenu,
                                                                                        submenu.type ==
                                                                                            "LISTE_ARTICLE"
                                                                                            ? "subcategorie"
                                                                                            : "page"
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {submenu.type ==
                                                                                "LISTE_ARTICLE"
                                                                                    ? submenu.title
                                                                                    : submenu.type ==
                                                                                      "PAGE"
                                                                                    ? submenu.titre
                                                                                    : null}
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            )}
                                                        </Menu.Items>
                                                    </Menu>
                                                );
                                            } else {
                                                return (
                                                    <li className="grid content-center mr-8 border-b-[4px] border-transparent block hover:ease-in-out hover:delay-100 hover:duration-300  hover:border-b-[4px] h-[70px] hover:border-b-4 hover:border-[#ff00ff] active:border-[#ff00ff]">
                                                        <Link
                                                            to={
                                                                item.type ==
                                                                "LISTE_ARTICLE"
                                                                    ? item.link
                                                                    : item.lien
                                                            }
                                                            className="no-underline text-[#3009fa] text-[14px] font-semibold"
                                                            onClick={() => {
                                                                setCurrentCategorie(
                                                                    item,
                                                                    item.type ==
                                                                        "LISTE_ARTICLE"
                                                                        ? "categorie"
                                                                        : "page"
                                                                );
                                                            }}
                                                        >
                                                            {item.title ||
                                                                item.titre}
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                        })}
                                        {/* </div> */}
                                        <li className="grid content-center border-b-[4px] border-transparent block hover:ease-in-out hover:delay-100 hover:duration-300  hover:border-b-[4px] h-[70px] hover:border-b-4 hover:border-[#ff00ff] active:border-[#ff00ff] text-center">
                                            <Link
                                                to="/contact"
                                                className="no-underline text-[#3009fa] text-[14px] font-semibold"
                                            >
                                                Contactez-Nous
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <Disclosure.Button className="mr-5 lg:hidden">
                                        <svg
                                            id="Outline"
                                            viewBox="0 0 24 24"
                                            className="h-6 w-6 text-gray-700 fill-current"
                                        >
                                            <rect
                                                y="11"
                                                width="24"
                                                height="2"
                                                rx="1"
                                            />
                                            <rect
                                                y="4"
                                                width="24"
                                                height="2"
                                                rx="1"
                                            />
                                            <rect
                                                y="18"
                                                width="24"
                                                height="2"
                                                rx="1"
                                            />
                                        </svg>
                                    </Disclosure.Button>
                                </div>
                                {/* Recherche */}
                            </div>
                            <Disclosure.Panel className="absolute top-[60px] text-sm text-gray-200 z-[1000] bg-[#3009fa] dark:bg-[#a1a1a1] w-full lg:hidden">
                                <ul className="text-center">
                                    <li className="mx-10 py-4 border-b-4 border-transparent hover:border-b-4 hover:border-[#ff00ff] text-left">
                                        <Link to="/">
                                            <a className="no-underline text-white font-semibold">
                                                Accueil
                                            </a>
                                        </Link>
                                    </li>
                                    {categories.map((item) => {
                                        if (
                                            item.type == "LISTE_ARTICLE" &&
                                            item?.SubCategories.length
                                        ) {
                                            return (
                                                <Disclosure
                                                    as="div"
                                                    className="px-10 relative inline-block text-left w-full"
                                                >
                                                    <Disclosure.Button
                                                        as="div"
                                                        className="text-left w-full flex  py-4 border-b-4 border-transparent hover:border-b-4 hover:border-[#ff00ff] text-center"
                                                    >
                                                        <span className="font-semibold">
                                                            {item.title}
                                                        </span>
                                                        <ChevronDownIcon
                                                            className="h-5 w-5 ml-2 text-white font-semibold"
                                                            aria-hidden="true"
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="divide-y divide-gray-300">
                                                        <ul className="dark:bg-gray-500  w-full text-center">
                                                            {item.SubCategories.map(
                                                                (submenu) => (
                                                                    <li className="px-20 text-left py-4 border-b-4 border-transparent hover:border-b-4 hover:border-[#ff00ff] ">
                                                                        <Link
                                                                            to={
                                                                                submenu &&
                                                                                submenu.link
                                                                                    ? submenu.link
                                                                                    : submenu &&
                                                                                      submenu?.lien
                                                                                    ? submenu.lien
                                                                                    : null
                                                                            }
                                                                            className="no-underline text-white font-semibold"
                                                                            onClick={() => {
                                                                                setCurrentCategorie(
                                                                                    submenu,
                                                                                    submenu.type ==
                                                                                        "LISTE_ARTICLE"
                                                                                        ? "subcategorie"
                                                                                        : "page"
                                                                                );
                                                                            }}
                                                                        >
                                                                            {submenu.type ==
                                                                            "LISTE_ARTICLE"
                                                                                ? submenu.title
                                                                                : submenu.type ==
                                                                                  "PAGE"
                                                                                ? submenu.titre
                                                                                : null}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </Disclosure>
                                            );
                                        } else {
                                            return (
                                                <li className="mx-10 text-left grid content-center mr-8 border-b-[4px] border-transparent hover:ease-in-out hover:delay-100 hover:duration-300  hover:border-b-[4px] h-[70px] hover:border-b-4 hover:border-[#ff00ff] active:border-[#ff00ff]">
                                                    <Link
                                                        to={
                                                            item.type ==
                                                            "LISTE_ARTICLE"
                                                                ? item.link
                                                                : item.lien
                                                        }
                                                        className="no-underline text-white font-semibold"
                                                        onClick={() => {
                                                            setCurrentCategorie(
                                                                item,
                                                                item.type ==
                                                                    "LISTE_ARTICLE"
                                                                    ? "categorie"
                                                                    : "page"
                                                            );
                                                        }}
                                                    >
                                                        {item.title ||
                                                            item.titre}
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    })}
                                    <li className="mx-10 border-transparent hover:border-b-4 hover:border-[#ff00ff] py-4 text-left">
                                        <Link to="/contact">
                                            <a className="no-underline text-white font-semibold">
                                                Contactez-Nous
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </nav>

            <div className="font-['Verdana'] bg-white pt-3 py-10  sm:pt-10 dark:bg-slate-800 dark:transition-colors dark:ease-in-out dark:delay-150 dark:duration:6000">
                <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row  mx-5 lg:mx-20 justify-between items-center">
                    <div className="flex space-x-3 items-end">
                        <div className="shrink-0">
                            {darkMode == "dark" ? (
                                <Link to="/" replace>
                                    <img
                                        className="h-[200px] w-auto"
                                        src={logo_bardel_white}
                                    />
                                </Link>
                            ) : (
                                <Link to="/" replace>
                                    <img
                                        className="h-[200px] w-auto"
                                        src={logo_bardel}
                                    />
                                </Link>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${
                            location.pathname == "/"
                                ? "transition-all ease-in-out delay-150 duration-500 dark:transition-colors dark:ease-in-out dark:delay-150 dark:duration-10000 mx-5 md:mx-20 mt-10 dark:bg-slate-800"
                                : null
                        } h-[60px] overflow-hidden`}
                    >
                        {location?.pathname == "/" ? (
                            null
                            // <div className="font-['Verdana'] font-serif flex border-l-4 border-[#ff00ff]  py-3 md:py-5 items-center justify-between pl-5 md:pl-10 mb-5 md:mb-10">
                            //     <h2 className=" text-[#3009fa] text-xl md:text-4xl text-left dark:text-white font-['Verdana']">
                            //         Ecoutez la Radio
                            //     </h2>
                            // </div>
                        ) : null}
                        <iframe
                            loading="lazy"
                            allowfullscreen=""
                            src="https://www.webradio.media/bardeleco/"
                            data-rocket-lazyload="fitvidscompatible"
                            className="w-full h-[60px] transition-all ease-in-out duration-500"
                            frameborder="0"
                            style={{height: "60px !important"}}
                        ></iframe>
                    </div>
                    <div className="space-y-2">
                        <div>
                            <p className="text-gray-700 dark:text-white font-semibold text-xs md:text-basic">
                                Combien de temps avez-vous?
                            </p>
                            <div className="flex items-center">
                                <Link
                                    to={`/recherche/${searchDataSlugify}`}
                                    onClick={() =>
                                        setDuration("5min", searchDataSlugify)
                                    }
                                    className={`${
                                        duration == "5min"
                                            ? "block bg-[#6600ff]"
                                            : ""
                                    } block bg-[#ff00ff] text-white py-1 px-2 hover:bg-rose-800 rounded-l`}
                                >
                                    5 min
                                </Link>
                                <Link
                                    to={`/recherche/${searchDataSlugify}`}
                                    onClick={() =>
                                        setDuration("10min", searchDataSlugify)
                                    }
                                    className={`${
                                        duration == "10min"
                                            ? "block bg-[#6600ff]"
                                            : ""
                                    } block bg-[#ff00ff] text-white py-1 px-2 hover:bg-rose-800`}
                                >
                                    10 min
                                </Link>
                                <Link
                                    to={`/recherche/${searchDataSlugify}`}
                                    onClick={() =>
                                        setDuration("20min", searchDataSlugify)
                                    }
                                    className={`${
                                        duration == "20min"
                                            ? "block bg-[#6600ff]"
                                            : ""
                                    } block bg-[#ff00ff] text-white py-1 px-2 hover:bg-rose-800 rounded-r`}
                                >
                                    20 min
                                </Link>
                            </div>
                        </div>
                        <div>
                            <p className="text-gray-700 dark:text-white font-semibold text-xs md:text-basic">
                                Vous aimez notre chaîne sans pub ?
                            </p>
                            <div>
                                <Link
                                    to={`/faire-un-don`}
                                    className={`inline-block w-auto bg-[#3009fa] text-white py-1 px-2 hover:bg-[#30097a] rounded`}
                                >
                                    Faire un don
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="font-['Verdana'] relative bg-transparend  min-h-[70px] border-b-2 border-[#E5E7EB] dark:border-slate-800  dark:bg-slate-800 dark:transition-colors dark:ease-in-out dark:delay-150 dark:duration:6000">
                <div className="mx-5 lg:mx-20">
                    <div className="w-full lg:w-auto flex justify-end">
                        <div className="relative w-full sm:w-96">
                            <button
                                ref={searchButtonRef}
                                onClick={() => {
                                    clientSearch(searchDataSlugify);
                                    navigate(`/recherche/${searchDataSlugify}`, {
                                        replace: true,
                                    });
                                }}
                                className="absolute inset-y-0 right-0 flex items-center pr-4"
                            >
                                <svg
                                    id="Outline"
                                    viewBox="0 0 24 24"
                                    className="h-4 w-4 fill-current text-gray-300"
                                >
                                    <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" />
                                </svg>
                            </button>
                            <input
                                type="text"
                                onChange={(e) => handleSearchChange(e)}
                                onFocus={() => setSearchFocus(true)}
                                onBlur={() => setSearchFocus(false)}
                                className="flex w-full placeholder:text-gray-300 pl-5 pr-12 py-2 rounded-full bg-gray-700 outline-none text-gray-200"
                                placeholder="Recherche"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
