import React,{useEffect, useState, useContext,useMemo} from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import categorieService from './categorieService';
import {AppContext} from '../../../../appProvider';
import {Navigate, useLocation} from 'react-router-dom';
import config from '../../../../config/conifg.json';
import {Link} from 'react-router-dom';
import {useTable, usePagination, useAsyncDebounce, useGlobalFilter} from 'react-table';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="relative w-96">
            <div className="absolute block inset-y-0 right-0 flex items-center pr-4">
                <svg    id="Outline" 
                        viewBox="0 0 24 24"
                        className="h-4 w-4 fill-current text-gray-700">
                    <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/>
                </svg>
            </div>
            <input
                className='w-full placeholder:text-gray-700 pl-5 pr-12 py-2 rounded-full bg-gray-200 outline-none text-gray-700'
                value={value || ""}
                onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
                }}
                placeholder={`Filtrer ...`}
                style={{
                fontSize: '1.1rem',
                border: '0',
                }}
            />
        </div>
    )
}

function Table ({columns, data}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize ,globalFilter},
        setGlobalFilter,
        preGlobalFilteredRows
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,

    )

    let pages = [];
    
    for (let i = 0;i < pageCount;i ++) {
        pages.push(<button onClick={() => gotoPage(i)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{i + 1} </button>)
    }


    return (
            <>
                <div className="mb-5">
                    <GlobalFilter 
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}/>
                </div>
                <table {...getTableProps()} className="border-collapse min-w-full">
                    <thead className='border-b bg-gray-800'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                <th className='text-sm font-medium text-white px-6 py-4' {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                        <tr {...row.getRowProps()} className="border border-slate-300">
                            {row.cells.map(cell => {
                            return <td className="text-center text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                    })}
                    {/* {rows.map(
                    (row, index) =>
                    prepareRow(row) || (
                        <Row
                        index={index}
                        row={row}
                        moveRow={moveRow}
                        {...row.getRowProps()}
                        />
                    )
                )} */}
                    </tbody>
                </table>
            {/* 
                Pagination can be built however you'd like. 
                This is just a very basic UI implementation:
            */}

            <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div class="flex-1 flex justify-between sm:hidden">
                <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                <p class="text-sm text-gray-700">
                    page
                    <span class="font-medium"> {pageIndex + 1} </span>
                    sur
                    <span class="font-medium"> {pageOptions.length} </span>
                    pages
                </p>
                </div>

                <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button  onClick={() => gotoPage(0)} disabled={!canPreviousPage} class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Début</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                        </svg>
                    </button>
                    <button  onClick={() => previousPage()} disabled={!canPreviousPage} class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Précedent</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    {/* {pageCount < 6 ?
                        pages.map(item => item)
                    : 
                    <>
                        <button onClick={() => gotoPage(pageIndex)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageIndex + 1 } </button>
                        <button onClick={() => gotoPage(pageIndex + 1)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageIndex + 2} </button>
                        <span  aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">... </span>
                        <button onClick={() => gotoPage(pageCount - 2)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageCount - 1} </button>
                        <button onClick={() => gotoPage(pageCount - 1)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageCount} </button>
                    </>} */}
                    <button onClick={() => nextPage()} disabled={!canNextPage}  class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Suivant</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}  class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Fin</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                        </svg>
                    </button>
                </nav>
                </div>
                <div className='space-x-10'>
                    <span>
                        | Aller a la page:{' '}
                        <input
                            className="form-select appearance-none
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="number"
                            min="1"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                        </span>{' '}
                    <select className="form-select appearance-none
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                            {[1,2,10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                            Afficher {pageSize}
                            </option>
                        ))}
                    </select>
                </div>


            </div>
            </div>
            </>)
}

const rows = [10,20,50,100]
export default function CategorieList(props) {
    const {isAuthenticated} = useContext(AppContext)
    const [categories, setCategories] = useState([]);
    const [currentCategorieId, setCurrentCategorieId] = useState(null);
    const [listType, setListeType] = useState("table");


    useEffect(() => {
        categorieService.getAllCategorie()
            .then(response => {
                if (response.data.length) {
                    setCategories(response.data);
                }
            })
            .catch(error => {
                console.error("categorie :",error)
            })
    },[])

    const columns = React.useMemo(
        () => [
            {
                Header: () => null,
                id: "file",
                accessor: "File",
                Cell:({row}) => (
                    <div className="w-28 h-28 bg-gray-400 rounded-lg overflow-hidden">
                            <img className="h-full w-full object-cover" src={`${config.API_URL}${row.values?.file?.url}`} alt={row.values?.name}/>
                    </div>
                )
            },
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Tirre',
                accessor: 'title',
            },
            {
                Header: 'Description',
                accessor:"description"
            },
            {
                Header: () => null,
                id: "actions",
                Cell: ({row}) => (
                    <div className="p-5">
                    <div className="flex space-x-5">
                        {currentCategorieId && row.values.id == currentCategorieId ? 
                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteCategorie(row.values.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                                <span className="text-red-500 fond-semibold text-sm">Confirmation</span>
                            </button>
                        :
                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() =>{setCurrentCategorieId(row.values.id)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                                <span className="text-red-500 fond-semibold text-sm">Supprimer</span>
                            </button>
                        }


                        <Link to={`/admin/categorie/edit/${row.values.id}`} className="flex items-center space-x-2 px-4 p-1 rounded-full bg-blue-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                            <span className="text-blue-500 fond-semibold text-sm">Editer</span>
                        </Link>
                    </div>
                </div>
                )
            },
        ]
    )

    const deleteCategorie = (id) => {
        categorieService.deleteOneCategoryById(id)
            .then(response => {
                let current = categories.findIndex(item => item.id == response.id);
                if (current >= 0) {
                    categories.splice(current,1)
                    setCategories(prevState => {
                        return [...prevState]
                    })
                }
            })
            .catch(error => {
                console.error()
            })
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" replace/>
    }

    const handleCurrentCategorieId = (id) => {
        setCurrentCategorieId(id);
    }

    
    return (<div classNamae="m-10">
                <div className="p-10">
                    <div>
                        <h2 className="text-2xl font-bold text-gray-700">Toutes les categorie</h2>
                        <div className="flex space-x-5">
                            <Link to="/admin/categorie/ajout">
                                <button className="flex mt-3 px-4 py-2 rounded-md bg-blue-500 text-white font-semibold text-basic">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    <span>
                                        Ajouter categorie
                                    </span>
                                </button>
                            </Link>
                            <Link to="/admin/categorie/organiser" className="block flex mt-3 px-4 py-2 rounded-md bg-green-500 text-white font-semibold text-basic">
                                Organiser Menu
                            </Link>
                        </div>
                    </div>

                    {listType == "table" ? 
                        <div className="p-5 bg-white rounded-xl mt-10">
                            <Table columns={columns} data={categories} deleteCategorie={deleteCategorie} currentCategorieId={currentCategorieId} setCurrentCategorieId={(id) => handleCurrentCategorieId(id)}/>
                        </div>
                    :

                    <div className='grid md:grid-cols-2 xl:grid-cols-2 gap-5 mt-10'>
                        {categories.length ? 
                            categories.map(item => (
                                <div className="flex flex-col lg:flex-row space-x-5 bg-white rounded-xl items-center lg:p-3 overflow-hidden">
                                    <div className={`${item?.File ? null : "grid content-center"} h-56 w-full lg:h-56 lg:w-56 lg:rounded-xl bg-gray-300 flex-shrink-0 overflow-hidden`}>
                                        {item?.File ? 
                                        <img className="object-cover h-full w-full" src={`${config.API_URL}${item.File.url}`} alt={item.title}/> : 
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-500 stroke-current mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        }
                                    </div>
                                    <div className='space-y-3 xl:mr-3 p-5'>
                                        <h3 className="text-gray-700 font-bold text-xl">{item.title}</h3>
                                        <p className="text-gray-400 text-sm">{item.description}</p>
                                        <div className="flex space-x-5">
                                            {currentCategorieId && item.id == currentCategorieId ? 
                                                <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteCategorie(item.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                    </svg>
                                                    <span className="text-red-500 fond-semibold text-sm">Confirmation</span>
                                                </button>
                                            :
                                                <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() => setCurrentCategorieId(item.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    <span className="text-red-500 fond-semibold text-sm">Supprimer</span>
                                                </button>
                                            }


                                            <Link to={`/admin/categorie/edit/${item.id}`} className="flex items-center space-x-2 px-4 p-1 rounded-full bg-blue-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                </svg>
                                                <span className="text-blue-500 fond-semibold text-sm">Editer</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        :null}
                    </div>
                    }
                </div>
            </div>)
}