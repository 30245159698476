import axios from "axios";
import config from '../../config/conifg.json';

class FooterService {
    async getPageByType(type) {
        const {data} = await axios.get(`${config.API_URL}/page/type/${type}`);
        return data;
    }
}

export default new FooterService();