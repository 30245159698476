import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;
class MediaService {
    async getAllFile() {
        const {data} = await axios.get(`${API_URL}/file/all`);
        return data;
    }
    async uploadFile(values) {
        let formdata = new FormData();
        formdata.append("file", values.files[0]);

        const {data} = await axios.post(`${API_URL}/file/upload`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
            } 
        })

        return data;
    }

    async deleteOneFileById(id) {
        const {data} = await axios.delete(`${API_URL}/file/delete/${id}`);
        return data;
    }
}

export default new MediaService();