import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../appProvider';

function CheckCircleIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-green-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function WarningIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-yellow-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>)
}

function DangerIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-red-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

function InfoIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-blue-500 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>)
}

let status = [
    {
        textColor: 'text-yellow-500',
        icon: <WarningIcon/>,
    },
    {
        textColor: 'text-red-500',
        icon: <DangerIcon/>,
    },
    {
        textColor: 'text-blue-500',
        icon: <InfoIcon/>
    },
    {
        textColor : 'text-green-500',
        icon : <CheckCircleIcon/>,
    }
]


export default function Notification(props) {
    const {closeNotification} = useContext(AppContext);
    useEffect(() => {
        let timeout = setTimeout(() => {
            closeNotification(props.id)
        },3000)
        return () => clearTimeout(timeout);
    },[])
    return (<div className="fixed top-20 right-20 w-96 z-[1000]">
                <div className="">
                    <div className="relative flex items-center bg-white rounded-lg shadow-md p-5 space-x-5">
                        <div className="absolute top-2 right-2">
                            <button onClick={() =>closeNotification(props.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 fill-current" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                            </button>
                        </div>
                        <div>
                            {status[props.data.code].icon}
                        </div>
                        <div>
                            <h2 className={`${status[props.data.code].textColor} text-lg font-bold`}>{props.data.title}</h2>
                            <p className=""> {props.data.content}</p>
                        </div>
                    </div>
                </div>
            </div>);
}