import React , {useState, useEffect} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import donService from './donService';

export default function FormCheckout(props) {
    
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    

    // const cardStyle = {
    //     style: {
    //       base: {
    //         color: "#32325d",
    //         fontFamily: 'Arial, sans-serif',
    //         fontSmoothing: "antialiased",
    //         fontSize: "16px",
    //         "::placeholder": {
    //           color: "#32325d"
    //         }
    //       },
    //       invalid: {
    //         fontFamily: 'Arial, sans-serif',
    //         color: "#fa755a",
    //         iconColor: "#fa755a"
    //       }
    //     }
    //   };
        
   

    const handleChange = async (event) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    }

    const handleSubmit = async (ev) => {
        ev.preventDefault();

        setProcessing(true);
        setDisabled(true);

        const payload = await stripe.confirmCardPayment(props.clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details:{
                    name: props.nom,
                    email: props.email
                }
            }
        })

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
            setDisabled(false)
        } else {
            
            await donService.createPaymentIntent({amount : props.currentAmount})
            .then(response => {
                if (response.clientSecret) {
                    props.handleClientSecret(response.clientSecret)
                    setError(null);
                    setProcessing(false);
                    setSucceeded(true);
                    setDisabled(false);
                }
            })
            .catch(error => {
                console.error(error);
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                setDisabled(false);
            })
        }
    }

    return (
                        <form id="payment-form" onSubmit={handleSubmit}>
                            <div>
                                <label className="text-gray-700 text-lg font-semibold mb-3 dark:text-gray-400">Information sur la carte</label>
                                <div className={`border ${error ? "border-red-500": "border-slate-400"} mt-3  rounded px-4 py-4`}>
                                    <CardElement className='' id="card-element"  onChange={handleChange}/>
                                </div>
                            </div>
                            <button className='w-full mt-10 bg-[#ff00ff] text-lg font-semibold rounded px-4 py-2 text-white ' disabled={processing || disabled } id="submit">
                                <span id="button-text">
                                    {processing ? "Envoie encours ..." : "Envoyer maintenant"}
                                </span>
                            </button>
                            {error && (
                                <div className="card-error mt-5 text-red-500 text-lg border border-red-300 rounded p-3" role="alert">
                                    {error}
                                </div>
                            )}
                            {/* Show a success message upon completion */}
                            <p className={`${succeeded ? "result-message" : "result-message hidden"} mt-5 text-green-700 text-lg border border-green-300 rounded p-3`}>
                                Payment reussi, clicker a nouveau sur le bouton pour envoyer un autre don
                            </p>
                        </form>)
}