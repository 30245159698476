import axios from 'axios';
// import * as config from '../../../../config/conifg.json';
import config from '../../../config/conifg.json'

const API_URL = config.API_URL;
class BlogClientService {
    async getBlogByLink(link) {
        const {data} = await axios.get(`${API_URL}/blog/link/${link}`);
        return data;
    }

    async getBlogWithRerservedByLink(link) {
        const {data} = await axios.get(`${API_URL}/blog/with-reserved/link/${link}`);
        return data;
    }
}

export default new BlogClientService();