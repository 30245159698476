import React, { useEffect, useState, useContext, useRef, forwardRef } from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, Modifier,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import config from '../../../../config/conifg.json'
import { AppContext } from '../../../../appProvider';
import moment from 'moment';
import categorieService from '../categorie/categorieService';
import subCategorieService from '../subCategorie.js/subCategorieService';
import pageService from './pageService';
import { PageContext } from './pageProvider';
import PagePreview from './pagePreview';

const API_URL = config.API_URL;

const hashConfig = {
    trigger: '#',
    separator: '<br>',
}

const directional = false


export default function PageEdit(props) {
    const navigate = useNavigate();
    const {setNotification} = useContext(AppContext);
    // const {setCurrentPage} = useContext(PageContext);
    const [currentPage, setCurrentPage] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [contentState, setContentState] = useState(EditorState.createEmpty());
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategorieFiltered, setSubCategorieFiltered] = useState([]);
    const ref = useRef(null);
    const [files, setFiles] = useState([]);
    const [editorRef, setEditorRef] = useState(null);
    const [scriptValue, setScriptValue] = useState("")
    const params = useParams();
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const [previewStatus, setPreviewStatus] = useState(false);


    const onEditorStateChange = (value) => {
        setEditorState(value);
        ref.current.setFieldValue("contenu", draftToHtml(convertToRaw(value.getCurrentContent()),{
            hashConfig,
            directional
        }));
    }

    const onContentStateChange = (value) => {
        setContentState(value)
    }

    const handleHtml = (value) => {
        let BlockFromHtml = htmlToDraft(value);
        const {contentBlocks, EntityMap} = BlockFromHtml;
        const contentStateNew = ContentState.createFromBlockArray(contentBlocks, EntityMap);
        const editorStateNew = EditorState.createWithContent(contentStateNew);
        return editorStateNew;
    }
    useEffect(() => {
        pageService.getOnePageById(params.id)
            .then(response => {
                let BlockFromHtml = htmlToDraft(response.data.contenu);
                const {contentBlocks, EntityMap} = BlockFromHtml;
                const contentStateNew = ContentState.createFromBlockArray(contentBlocks, EntityMap);
                const editorStateNew = EditorState.createWithContent(contentStateNew);
                setEditorState(editorStateNew);
                ref.current.setFieldValue("titre", response.data.titre);
                ref.current.setFieldValue("contenu",response.data.contenu)
                ref.current.setFieldValue("categorie", response.data.categorie);
                ref.current.setFieldValue("position", response.data.position);
                setCurrentPage({
                    titre: response.data.titre,
                    contenu: response.data.contenu,
                    position: response.data.position,
                    categorie: response.data.categorie
                })
            })
            .catch(error => {
                console.error()
            })
        categorieService.getAllCategorie()
            .then(response => {
                if (response.data) {
                    setCategories(response.data)
                }
            })
            .catch(error => {
                console.error()
            })
        
    }, [])

    useEffect(() => {
        let currentData = convertToRaw(editorState.getCurrentContent());
        setCurrentPage({
            titre: ref.current.values.titre,
            contenu: draftToHtml(currentData,{
                    hashConfig,
                    directional
                }),
            categorie: ref.current.values.categorie,
            position: ref.current.values.position
            });
    },[editorState])


    const uploadImageCallback =(file) => {
        return new Promise((resolve, reject) => {
            let formdata = new FormData();
            formdata.append("file", file);

        axios.post(`${API_URL}/file/upload`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // setPercent(percent);
            } 
            }).then(response => {
                resolve({data:{link: `http://www.bardeleconomie.fr${response.data.data.url}`}})
            }).catch(error => {
                reject(error)
            })
            
        })
    }

    const filterSubCategorie = (value) => {
        setSubCategorieFiltered(subCategories.filter(item => item.categorie == value));
    }

    // const embeddedCallback = (value) => {
    //     let src_regex = /(?<=src=").*?(?=[\?"])/i
    //     let result = src_regex.exec(value);
    //     return result;
    // }

    const handleScriptIframe = () => {
            const draft = htmlToDraft(`<p></p> <div class="w-full bg-red-300">${scriptValue}</div> <p></p>`);
            editorRef.focus();
            let contentState = ContentState.createFromBlockArray(draft.contentBlocks, draft.entityMap);
            let blockMap = contentState.getBlockMap();
            contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                blockMap
            )
            const newt_editor_state =  EditorState.push(editorState,contentState,'insert-characters');
            setEditorState(newt_editor_state)
    }


    const setEditorReference = (ref) => {
        setEditorRef(ref);
    }

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }


    return (<>
            <div className='m-10 p-10 bg-white rounded-lg'>
                <div className="max-w-5xl mx-auto divide-y divide-gray-300">
                    {!previewStatus ?
                    <div className="flex items-center justify-between text-left">
                        <h2 className="text-3xl font-bold text-gray-700">Editer Page</h2>
                        {/* <Link to="previsualiser" replace  className="block bg-blue-500 py-2 px-4 text-md text-white font-semibold rounded">Prévisualiser</Link> */}
                        <button className="block bg-blue-500 py-2 px-4 text-md text-white font-semibold rounded" onClick={() => setPreviewStatus(!previewStatus)}>Prévisualiser</button>
                    </div>
                    :
                    <div className="flex items-center justify-between text-left">
                        <h2 className="text-3xl font-bold text-gray-700">Prévisualiser page</h2>
                        <button className="block bg-blue-500 py-2 px-4 text-md text-white font-semibold rounded" onClick={() => setPreviewStatus(!previewStatus)}>Editeur de page</button>
                    </div>
                    }

                    {!previewStatus ?
                    <div className="mt-10 py-10">
                        <Formik
                            innerRef={ref}
                            initialValues={{
                                titre: currentPage && currentPage.titre ? currentPage.titre : "",
                                contenu: currentPage && currentPage.contenu ? currentPage.contenu : "",
                                categorie: currentPage && currentPage.categorie ? currentPage.categorie : "",
                                position: currentPage && currentPage.position ? currentPage.position : ""
                            }}

                            validationSchema={Yup.object({
                                titre: Yup.string().max(255,"Doit être inférieur a 255 caractères").required("Le titre est requis"),
                                contenu: Yup.string().required("Contenu est requis"),
                                position: Yup.string().required("Position requis"),
                            })}

                            onSubmit={async (values) => {
                                let formdata = new FormData();
                                formdata.append("titre", values.titre);
                                formdata.append("contenu", values.contenu);
                                formdata.append("categorie", values.categorie);
                                formdata.append("position", values.position);

                                await axios.put(`${API_URL}/page/update/${params.id}`, formdata, {
                                    headers: {
                                        "Content-Type":"multipart/form-data"
                                    }
                                }).then((response) => {
                                    setNotification({code:3, title: "Succès", content: response.message})
                                    navigate("/admin/page/liste",{replace:true});
                                }).catch(error => {
                                    if (error && error.response && error.response.data) {
                                        setNotification({code:0, title: "Attention", content: error.response.data.message})
                                    } else {
                                        setNotification({code:1, title: "Error", content: error.message})
                                    }
                                })
                            }}
                        >{(formik) => (
                            <form className='space-y-5' onSubmit={formik.handleSubmit}>
                                <div className="">
                                        <label className="block text-xl text-gray-700">Titre</label>
                                        <input  type="text" 
                                                id="titre"
                                                name="titre"
                                                placeholder="Titre de l'article ..."
                                                className={`${!!didFocus && currentField == "titre" && formik.errors.titre || formik.touched.titre && formik.errors.titre ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("titre")}
                                                value={formik.values.titre}
                                                onChange={(e) => { setCurrentPage(prevState => {prevState.titre = e.target.value; return prevState}); formik.setFieldValue("titre",e.target.value) }}/>
                                                {!!didFocus && currentField == "titre" && formik.errors.titre || formik.touched.titre && formik.errors.titre ?<span className="text-red-500 text-sm">{formik.errors.titre}</span> : null }
                                </div>
                                <div>
                                    <label className="text-xl text-gray-700 ">Script</label>
                                    <textarea   id="script_iframe"
                                                name="script_iframe"
                                                className="mt-3 h-36  w-full px-3 py-2 border border-gray-300 rounded outline-none focus:ring-2 focus:ring-blue-500 placeholder:italic placeholder:text-gray-400" 
                                                placeholder='Entrer votre script ici ...'
                                                value={scriptValue}
                                                onChange={(e) => setScriptValue(e.target.value)}></textarea>
                                    <span onClick={() => handleScriptIframe()} className="cursor-pointer mt-5 bg-green-500 rounded text-white font-semibold text-md px-4 py-1">Ajouter dans l'editeur</span>
                                </div>
                                <div>
                                    <h3 className="text-gray-700 text-xl mb-3">Editeur</h3>
                                    <div className={`${!!didFocus && currentField == "titre" && formik.errors.contenu || formik.touched.contenu && formik.errors.contenu ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} rounded p-5`} >
                                        <Editor
                                            editorRef={setEditorReference}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName='wrapperClassName'
                                            editorClassName='min-h-[450px]'
                                            onEditorStateChange={onEditorStateChange}
                                            onContentStateChange={onContentStateChange}
                                            // onChange={handleChange}
                                            toolbar={{
                                                image: {uploadCallback: uploadImageCallback, alt: {present: true, mandatory:false}},
                                                // embedded:{embedCallback: embeddedCallback, defaultSize: {
                                                //     height: 465,
                                                //     width: '100%',
                                                // },}
                                            }}
                                            spellCheck={true}
                                        />
                                    </div>
                                    {!!didFocus && currentField == "titre" && formik.errors.contenu || formik.touched.contenu && formik.errors.contenu ?<span className="text-red-500 text-sm">{formik.errors.contenu}</span> : null }
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Categorie</label>
                                        <select type="text" 
                                                id="categorie"
                                                name="categorie"
                                                placeholder='Nom de la categorie ...'
                                                value={formik.values.categorie}
                                                onChange={(e,value) => {formik.setFieldValue('categorie',e.target.value);setCurrentPage(prevState => {prevState.categorie = e.target.value; return prevState});filterSubCategorie(e.target.value)}}
                                                className={`${!!didFocus && currentField == "categorie" && formik.errors.categorie || formik.touched.categorie && formik.errors.categorie ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("categorie")}>
                                                <option value="">--Veuillez choisir un option--</option>
                                                {categories.map(categorie => (<option value={categorie.id}>{categorie.title}</option>))}
                                        </select>
                                        {!!didFocus && currentField == "categorie" && formik.errors.categorie || formik.touched.categorie && formik.errors.categorie ?<span className="text-red-500 text-sm">{formik.errors.categorie}</span> : null }
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Position</label>
                                        <select type="text" 
                                                id="position"
                                                name="position"
                                                value={formik.values.position}
                                                onChange={(e,value) => {formik.setFieldValue('position',e.target.value);setCurrentPage(prevState => {prevState.position = e.target.value; return prevState})}}
                                                className={`${!!didFocus && currentField == "position" && formik.errors.position || formik.touched.position && formik.errors.position ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("position")}>
                                                <option value="">--Veuillez choisir un option--</option>
                                                <option value="MENU">Menu</option>
                                                <option value="PIED">Pied de page</option>
                                        </select>
                                        {!!didFocus && currentField == "position" && formik.errors.position || formik.touched.position && formik.errors.position ?<span className="text-red-500 text-sm">{formik.errors.position}</span> : null }
                                </div>

                                <div className='flex space-x-10 mt-10'>
                                        <button type="submit" className="flex-1 px-4 py-2 text-white bg-blue-700 rounded">Sauvegarder</button>
                                        <button type="reset" className="flex-1 px-4 py-2 text-white bg-gray-400 rounded">Réinitialiser</button>
                                    </div>

                            </form>
                        )}</Formik>
                    </div>
                : <PagePreview currentPage={currentPage}/>}
                </div>
            </div>
            </>)
}