import axios from 'axios';
import config from '../../config/conifg.json';

const API_URL = config.API_URL;

class NavbarService {
    async getAllCategorie() {
        const {data} = await axios.get(`${API_URL}/categorie/all`);
        return data;
    }

    async getAllCategorieOrderASC() {
        const {data} = await axios.get(`${API_URL}/categorie/all-order-asc`);
        return data;
    }

    async createHistory(values) {
        const {data} = await axios.post(`${API_URL}/history/create`, values);
        return data;
    }

    async getAllHistoryByUser(id, nbr) {
        const {data} = await axios.get(`${API_URL}/nbr/${nbr}/id/${id}`);
        return data;
    }
}

export default new NavbarService();