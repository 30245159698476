import React, { useEffect, useState } from 'react';
import blogService from './blogService';
import config from '../../../../config/conifg.json';
import {Link } from 'react-router-dom';
import {useTable, usePagination, useGlobalFilter, useAsyncDebounce} from 'react-table';
import {matchSorter} from 'match-sorter';
import moment from 'moment';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="relative w-96">
            <div className="absolute block inset-y-0 right-0 flex items-center pr-4">
                <svg    id="Outline" 
                        viewBox="0 0 24 24"
                        className="h-4 w-4 fill-current text-gray-700">
                    <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/>
                </svg>
            </div>
            <input
                className='w-full placeholder:text-gray-700 pl-5 pr-12 py-2 rounded-full bg-gray-200 outline-none text-gray-700'
                value={value || ""}
                onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
                }}
                placeholder={`Filtrer ...`}
                style={{
                fontSize: '1.1rem',
                border: '0',
                }}
            />
        </div>
    )
}

function Table ({columns, data}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        preGlobalFilteredRows
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
    )

    let pages = [];
    
    for (let i = 0;i < pageCount;i ++) {
        pages.push(<button onClick={() => gotoPage(i)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{i + 1} </button>)
    }

    return (
            <>
                <div className="mb-5">
                    <GlobalFilter 
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}/>
                </div>
                <table {...getTableProps()} className="border-collapse min-w-full">
                    <thead className='border-b bg-gray-800'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                <th className='text-sm font-medium text-white px-6 py-4' {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                        <tr {...row.getRowProps()} className="border border-slate-300">
                            {row.cells.map(cell => {
                            return <td className="text-center text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            {/* 
                Pagination can be built however you'd like. 
                This is just a very basic UI implementation:
            */}

            <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div class="flex-1 flex justify-between sm:hidden">
                <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                <p class="text-sm text-gray-700">
                    page
                    <span class="font-medium"> {pageIndex + 1} </span>
                    sur
                    <span class="font-medium"> {pageOptions.length} </span>
                    pages
                </p>
                </div>

                <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button  onClick={() => gotoPage(0)} disabled={!canPreviousPage} class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Début</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                        </svg>
                    </button>
                    <button  onClick={() => previousPage()} disabled={!canPreviousPage} class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Précedent</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    {/* {pageCount < 6 ?
                        pages.map(item => item)
                    : 
                    <>
                        <button onClick={() => gotoPage(pageIndex)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageIndex + 1 } </button>
                        <button onClick={() => gotoPage(pageIndex + 1)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageIndex + 2} </button>
                        <span  aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">... </span>
                        <button onClick={() => gotoPage(pageCount - 2)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageCount - 1} </button>
                        <button onClick={() => gotoPage(pageCount - 1)} aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{pageCount} </button>
                    </>} */}
                    <button onClick={() => nextPage()} disabled={!canNextPage}  class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Suivant</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}  class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Fin</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                        </svg>
                    </button>
                </nav>
                </div>
                <div className='space-x-10'>
                    <span>
                        | Aller a la page:{' '}
                        <input
                            className="form-select appearance-none
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="number"
                            min="1"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                        </span>{' '}
                    <select className="form-select appearance-none
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                            {[1,2,10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                            Afficher {pageSize}
                            </option>
                        ))}
                    </select>
                </div>


            </div>
            </div>
            </>)
}

export default function BlogListAdmin(props) {
    const [listType, setListeType] = useState("table");
    const [blogs, setBlogs] = useState([]);
    const [currentBlogId,setCurrentBlogId] = useState([]);
    useEffect(() => {
        blogService.getAllAdminBlog()
            .then(response => {
                if (response.data) {
                    setBlogs(response.data);
                }
            })
            .catch(error => {
                console.error(error);
            })
    },[])

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Titre',
                accessor: 'title',
                Cell: ({row}) => (
                    <div className="max-h-48 overflow-hidden"><span className="truncate max-w-48  overflow-hidden">{row.values.title}</span></div>
                )
            },
            {
                Header: 'Categorie(s)',
                accessor:"Categories",
                Cell: ({row}) => (<div>{row.values.Categories.map(item => <span className="py-1 px-2 rounded-full text-white bg-[#ff00ff] mr-2">{item?.title || "Non catégorisé"}</span>)}</div>)
                // Cell: ({row}) => (<div>{row.values && row.values.Categories && row.Values.Categories.length ? row.values.Categories[0].title : "Non catégorisé"}</div>)
            },
            {
                Header: 'Auteur',
                id: 'auteur',
                accessor: "Auteur",
                Cell: ({row}) => (row.values.auteur && row.values.auteur.nom  ? `${row.values.auteur.nom} ${row.values.auteur.prenom}` : "Aucun auteur")
            },
            {
                Header: 'Statut',
                accessor: 'status',
                Cell: ({row}) => (row.values.status == "published" ? 
                <div><span className=" w-auto space-x-2 border border-green-500 text-green-500 rounded-full py-1 px-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 stroke-current inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                        Publié
                    </span></div> : 
                    <span className=" w-auto space-x-2 border border-orange-500 text-orange-500 rounded-full py-1 px-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 stroke-current inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        Brouillon
                    </span>)
            },{
                Header: 'Publication',
                accessor: "date_publication",
                Cell: ({row}) => <span className="text-gray-700 font-semibold text-md">{moment(row.values.date_publication).format("YYYY/MM/DD")}</span>
            },
            {
                Header: () => null,
                id: "actions",
                Cell: ({row}) => (
                        <div className="flex space-x-5">
                        {currentBlogId && row.values.id == currentBlogId ? 
                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteBlog(row.values.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                                <span className="text-red-500 fond-semibold text-sm">Confirmation</span>
                            </button>
                        :
                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() => setCurrentBlogId(row.values.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                                <span className="text-red-500 fond-semibold text-sm">Supprimer</span>
                            </button>
                        }


                        <Link to={`/admin/article/edit/${row.values.id}`} className="flex items-center space-x-2 px-4 p-1 rounded-full bg-blue-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                            <span className="text-blue-500 fond-semibold text-sm">Editer</span>
                        </Link>
                    </div>
                )
            },
        ]
    )

    const deleteBlog = (id) => {
        blogService.deleteOneBlogById(id)
            .then(response => {
                let current =blogs.findIndex(item => item.id == response.id);
                if (current >= 0) {
                    blogs.splice(current,1);
                    setBlogs(prevState => {
                        return [...prevState]
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const handleCurrentBlogId = (id) => {
        setCurrentBlogId(id);
    }

    const handleListType= (value) => {
        setListeType(value);
    }
    return (<div classNamae="m-10">
                <div className="p-10">
                    <div>
                        <h2 className="text-2xl font-bold text-gray-700">Tous les articles</h2>
                        <div className='flex w-full justify-between'>
                            <Link to="/admin/article/ajout">
                                <button className="flex mt-3 px-4 py-2 rounded-md bg-blue-500 text-white font-semibold text-basic">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    <span>
                                        Ajouter article
                                    </span>
                                </button>
                            </Link>
                            <div className="space-x-3">
                                <button onClick={() => setListeType("table")}>Table</button>
                                <button onClick={() => setListeType("list")}>List</button>
                            </div>
                        </div>
                    </div>

                    {listType == "table" ? 
                        <div className="p-5 bg-white rounded-xl mt-10 overflow-auto">
                            <Table columns={columns} data={blogs} deleteBlog={deleteBlog} currentBlogId={currentBlogId} setCurrentBlogId={(id) => handleCurrentBlogId(id)}/>
                        </div>
                    :
                    <div className='grid lg:grid-cols-2 gap-5 mt-10'>
                    {blogs.length ? 
                        blogs.map(item => (
                            <div className="flex flex-col sm:flex-row space-x-5 bg-white rounded-xl items-center sm:p-3 overflow-hidden">
                                <div className="h-56 w-full sm:h-56 sm:w-56 sm:rounded-xl bg-gray-300 flex-shrink-0 overflow-hidden">
                                    <img className="object-cover h-full w-full" src={`${config.API_URL}${item.imageUrl}`} alt={item.name}/>
                                </div>
                                <div className='my-3 space-y-3'>
                                    <h3 className="text-gray-700 font-bold text-xl">{item.title}</h3>
                                    <p className="max-h-[80px] text-gray-400 text-sm overflow-hidden text-ellipsis">{item.excerpt}</p>
                                    <div className="flex space-x-5">
                                        {currentBlogId && item.id == currentBlogId ? 
                                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteBlog(item.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                                <span className="text-red-500 fond-semibold text-sm">Confirmation</span>
                                            </button>
                                        :
                                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() => setCurrentBlogId(item.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                                <span className="text-red-500 fond-semibold text-sm">Supprimer</span>
                                            </button>
                                        }


                                        <Link to={`/admin/article/edit/${item.id}`} className="flex items-center space-x-2 px-4 p-1 rounded-full bg-blue-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                            </svg>
                                            <span className="text-blue-500 fond-semibold text-sm">Editer</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    :null}
                </div>
                    } 
                    
                    
                </div>
            </div>)
}