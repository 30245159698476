import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;

class SignupService {
    async createUser(values) {
        const {data} = await axios.post(`${API_URL}/client/signup`, values);
        return data;
    }

    async getAllCategorie() {
        const {data} = await axios.get(`${API_URL}/categorie/all`);
        return data;
    }
}

export default new SignupService();