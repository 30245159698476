import React, {useContext,useEffect, useState} from 'react';
import {Formik, Form } from 'formik';
import * as Yup from 'yup';
import confirmationService from './confirmationService';
import { AppContext } from '../../../../appProvider';
import { Link,useParams } from 'react-router-dom';

export default function Confirmation(props){
    const {setNotification} = useContext(AppContext);
    const [message, setMessage] = useState(null);
    const params = useParams();

    useEffect(() => {
        confirmationService.confirmationCompte(params.token)
            .then(response => {
                setMessage({
                    status: response.status,
                    message: response.message
                })
            });
    },[])
    return (<div className="my-20">
                <div className="max-w-md mx-auto mt-10 mb-20">
                    
                    {message ?
                    
                        message.status == 200 
                        ? <>
                            <h2 className="text-4xl font-bold text-gray-700 text-center">Félicitations</h2>
                            <div>
                                <p className="text-center fong-semibold text-lg text-gray-700">{message && message?.message ? message.message : null }</p>
                                <Link to="/identification"><a><span className="text-[#ff00ff] font-bold text-xl">Je me connecte</span></a></Link>
                            </div>
                        </>
                        :
                        <>
                            <h2 className="text-4xl font-bold text-gray-700 text-center">Erreur</h2>
                            <div>
                                <p className="text-center fong-semibold text-lg text-gray-700">{message && message?.message ? message.message : null }</p>
                            </div>
                        </>
                    : <p>Rien à afficher</p>}
                </div>
            </div>)
}