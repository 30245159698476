import React, { useEffect, useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../appProvider';
import historiqueService from './historiqueService';
import moment from 'moment'


export default function Historique(props) {
    const {client, historique, setHistorique} = useContext(AppContext);
    let params = useParams();
    const {currentCategorie} = useContext(AppContext)

    useEffect(() => {
        if (client) {
            historiqueService.getAllHistoryByUser(client.id, 20)
                .then(response => {
                    setHistorique(response.data);
                })
                .catch(error => {
                    console.error()
                })
        }
    },[client]);
    
    return (<div>
                <div className="bg-gray-200">
                    <div className="mx-5 sm:mx-20 lg:max-w-5xl xl:max-x-7xl lg:mx-auto flex items-center justify-between  py-10">
                        <h2 className="text-3xl font-bold text-gray-700">Historique</h2>
                    </div>
                </div>
                <div className="font-sans">
                    <div className="border broder-gray-400 rounded max-w-2xl mx-auto my-10">
                    {historique.length ? 
                        historique.map(item => (
                            
                        <div className="flex space-x-20 text-gray-700 font-semibold px-4 py-2">
                            <div className="flex space-x-5 text-gray-400">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                                <span>{moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss')}</span>
                            </div>
                            <Link to={item.location} replace className="text-[#ff00ff]">{item.location}</Link>
                        </div>
                        ))
                    : null}
                    </div>
                </div>
            </div>)
}