import axios from 'axios';
import config from '../../../config/conifg.json';

const API_URL = config.API_URL;

class recoveryService {
    async recoverPassword(values) {
        const {data} = await axios.post(`${API_URL}/client/recovery`, values);
        return data;
    }
}

export default new recoveryService();