import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AppContext } from '../../../../appProvider';
import searchService from './searchService';
import config from '../../../../config/conifg.json';
import blogService from '../blog/blogService';

export default function Search(props) {
    const {results, setResults} = useContext(AppContext);
    const [currentBlogId, setCurrentBlogId] =useState(null);
    const params = useParams();
    useEffect(() => {
        searchService.getAllSearch({words : params.slug})
            .then(response => {
                setResults(response.data);
            })
            .catch(error => {
                console.error(error);
            })
    },[])

    const deleteBlog = (id) => {
        blogService.deleteOneBlogById(id)
            .then(response => {
                let current =results.findIndex(item => item.id == response.id);
                if (current >= 0) {
                    results.splice(current,1);
                    setResults(prevState => {
                        return [...prevState]
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    return (<div classNamae="m-10">
    <div className="p-10">
        <div>
            <h2 className="text-2xl font-bold text-gray-700">Resultats</h2>
        </div>
        <div className='grid lg:grid-cols-2 gap-5 mt-10'>
            {results.length ? 
                results.map(item => (
                    <div className="flex flex-col sm:flex-row space-x-5 bg-white rounded-xl items-center sm:p-3 overflow-hidden">
                        <div className="h-56 w-full sm:h-56 sm:w-56 sm:rounded-xl bg-gray-300 flex-shrink-0 overflow-hidden">
                            <img className="object-cover h-full w-full" src={`${config.API_URL}${item.imageUrl}`} alt={item.name}/>
                        </div>
                        <div className='my-3 space-y-3'>
                            <h3 className="text-gray-700 font-bold text-xl">{item.title}</h3>
                            <p className="max-h-[80px] text-gray-400 text-sm overflow-hidden text-ellipsis">{item.excerpt}</p>
                            <div className="flex space-x-5">
                                {currentBlogId && item.id == currentBlogId ? 
                                    <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteBlog(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                        <span className="text-red-500 fond-semibold text-sm">Confirmation</span>
                                    </button>
                                :
                                    <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() => setCurrentBlogId(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                        <span className="text-red-500 fond-semibold text-sm">Supprimer</span>
                                    </button>
                                }


                                <Link to={`/admin/article/edit/${item.id}`} className="flex items-center space-x-2 px-4 p-1 rounded-full bg-blue-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                    <span className="text-blue-500 fond-semibold text-sm">Editer</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))
            :null}
        </div>
    </div>
</div>)
}