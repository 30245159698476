import { attributesToProps } from 'html-react-parser';
import React, {useState} from 'react';

const Multiselect = (props) => {
    // state showing if dropdown is open or closed
    const [dropdown, setDropdown] = useState(false);
    // managing dropdown items (list of dropdown items)
    const [items, setItems] = useState(['john', 'milos', 'steph', 'kathreine']);
    // contains selected items
    const [selectedItems, setSelected] = useState([]);


    const toogleDropdown = () => {
        setDropdown(!dropdown)
    };
    // adds new item to multiselect 
    const addTag = (item) => {
        let tag_index = props.items.findIndex(tag => tag == item);
        props.items.splice(tag_index,1);
        props.setItems(prevItems => [...prevItems])
        props.setSelected(props.selectedItems.concat(item));
        setDropdown(false);
    };
    // removes item from multiselect
    const removeTag = (item) => {
        let selected_index = props.selectedItems.find(tag => tag == item);
        const filtered = props.selectedItems.filter((e) => e !== item);
        props.items.push(selected_index);
        props.setItems(prevItems => [...prevItems])
        props.setSelected(filtered);
    }

    return (<div className="autcomplete-wrapper">
        <div className="autcomplete">
        <div className="w-full flex flex-col items-center mx-auto">
    <div className="w-full">
        <div className="flex flex-col items-center relative">
            <div className="w-full ">
                <div className={`${props.theme == "basic" ? "rounded border border-gray-300 focus:ring-2 focus:ring-blue-500" :"bg-gray-200"} my-2 p-1 flex `}>
                    <div className="flex flex-auto flex-wrap">
                        {
                            props.selectedItems.map((tag, index) => {
                                return (
                                    <div key={index} className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded text-rose-700 bg-rose-100 border border-rose-300 ">
                                            <div className="text-xs font-normal leading-none max-w-full flex-initial">{ tag.text }</div>
                                            <div className="flex flex-auto flex-row-reverse">
                                                <div onClick={() => removeTag(tag)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                                    className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>)
                            })
                        }
                        <div className="flex-1">
                            <input disabled={props.disabled} placeholder="" className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800" onFocus={toogleDropdown}/>
                        </div>
                    </div>
                    <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200" disabled={props.disabled} onClick={() => props.disabled ? null : toogleDropdown()}>
                        <span className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none" >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        { dropdown  ? <Dropdown list={props.items} addItem={addTag}></Dropdown>: null }
        </div>
    </div>
</div>

    </div>
        </div>)
};

const Dropdown = ({list, addItem}) => {


    return (<div id="dropdown" className="absolute shadow top-[100%] bg-white z-40 w-full left-0 rounded max-h-select overflow-y-auto ">
                <div className="flex flex-col w-full">
                    { list.map((item, key) => {
                        return <div key={key} 
                                className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-rose-100" 
                                onClick={() => addItem(item)}>
                                    <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-rose-100" >
                                        <div className="w-full items-center flex">
                                            <div className="mx-2 leading-6  ">
                                                { item.text }
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    })}
                </div>
            </div>);

}; 

export default Multiselect;
