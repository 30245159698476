import axios from "axios";
import config from '../../../config/conifg.json';

class ContactService {
    async getAllContact() {
        const {data} = await axios.get(`${config.API_URL}/contact/all`);
        return data;
    }

    async sendMessage(values) {
        const {data} = await axios.post(`${config.API_URL}/contact/send-message`, values);
        return data;
    }

    async sendEntrepriseMessage(values) {
        const {data} = await axios.post(`${config.API_URL}/contact/entreprise/send-message`, values);
        return data;
    }
}

export default new ContactService();