import React , {useContext} from 'react';
import Notification from './notification';
import {AppContext} from '../../appProvider';

export default function NotificationList(props) {
    const {notifications} = useContext(AppContext)
    return (<> 
                {notifications.length ? 
                    notifications.map((item,index) => <Notification key={index} id={index} data={item}/>
                    )
                :null}
            </>)
}