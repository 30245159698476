import React, { useEffect, useState } from 'react';
import mediaService from './mediaService';
import config  from '../../../../config/conifg.json';
import {Link} from 'react-router-dom'

export default function MediaList(props) {
    const [medias, setMedias] = useState([]);
    const [currentFileId, setCurrentFileId] = useState(null)
    useEffect(() => {
        mediaService.getAllFile()
            .then(response => {
                if (response.data.length) {
                    setMedias(response.data)
                }
            })
            .catch(error => {
                console.error(error)
            })
    },[])

    const deleteFile = (id) => {
        mediaService.deleteOneFileById(id)
            .then(response => {
                let current = medias.findIndex(item => item.id == response.id);
                if (current >= 0) {
                    medias.splice(current,1)
                    setMedias(prevState => {
                        return [...prevState]
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    return (<div className="p-10">
                <div>
                    <div>
                        <h2 className="text-2xl font-bold text-gray-700">Toutes les medias</h2>
                        <Link to="/admin/media/ajout">
                            <button className="flex mt-3 px-4 py-2 rounded-md bg-blue-500 text-white font-semibold text-basic">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                <span>
                                    Ajouter medias
                                </span>
                            </button>
                        </Link>
                    </div>
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10">
                        {
                            medias.length ?
                                medias.map(item => (
                                    <div className="flex flex-col bg-white rounded-md overflow-hidden">
                                        <div className="w-full h-[75%]">
                                            <img className="w-full h-full object-cover" src={`${config.API_URL}${item.url}`} alt={item.name}/>
                                        </div>
                                        <div className="p-5">
                                            <div></div>
                                            <div className="flex space-x-5 bg-white rounded-xl items-center p-3 overflow-hidden">
                                    <div className='space-y-3'>
                                        <h3 className="text-gray-700 font-bold text-xl">{item.title}</h3>
                                        <p className="text-gray-400 text-sm">{item.description}</p>
                                    </div>
                                    <div className="flex space-x-5">
                                            {currentFileId && item.id == currentFileId ? 
                                                <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteFile(item.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                    </svg>
                                                    <span className="text-red-500 fond-semibold text-sm">Confirmation</span>
                                                </button>
                                            :
                                                <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() => setCurrentFileId(item.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    <span className="text-red-500 fond-semibold text-sm">Supprimer</span>
                                                </button>
                                            }
                                        </div>
                                </div>
                                        </div>
                                    </div>
                                ))
                            : null
                        }
                    </div>
                </div>
            </div>)
}