import React, {useContext, useState} from 'react';
import { Menu } from '@headlessui/react';
import { AppContext } from '../../../appProvider';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {Disclosure} from '@headlessui/react';
import {slugify} from '../../../utils/slugify';
function ProfileInactiveIcon(props) {
    return (<svg    {...props}
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 w-5" 
                    fill="#EDE9FE"
                    viewBox="0 0 24 24"
                    stroke="#fb7185"
                    strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
    )
}

function ProfileActiveIcon(props) {
return (<svg    {...props}
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5" 
                fill="#fb7185"
                stroke="#EDE9FE"
                viewBox="0 0 24 24"
                strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
    )
}

function LogoutInactiveIcon(props) {
    return (
        <svg    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 w-5" 
                    fill="#EDE9FE"
                    viewBox="0 0 24 24" 
                    stroke="#fb7185">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
    )
}

function LogoutActiveIcon(props) {
return (<svg    xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5" 
                fill="#fb7185"
                viewBox="0 0 24 24"
                stroke="#EDE9FE" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
    )
}



function SettingInactiveIcon(props) {
    return (<svg    xmlns="http://www.w3.org/2000/svg" 
                    className="h-6 w-6" 
                    fill="#EDE9FE"
                    viewBox="0 0 24 24" 
                    stroke="#fb7185">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>)
}

function SettingActiveIcon(props) {
return (<svg    xmlns="http://www.w3.org/2000/svg" 
                className="h-6 w-6" 
                fill="#fb7185"
                viewBox="0 0 24 24"
                stroke="#EDE9FE" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                </svg>)
}

export default function Navbar(props){
    const {setAuthenticated, search, logout} = useContext(AppContext);
    const [searchData, setSearchData] = useState("");
    const [searchDataSlugify, setSearchDataSlugify] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const handlelogout = () => {
        logout();
        navigate("/admin/identification",{replace:true,from:location})
    }

    const handleSearchChange = (e) => {
        setSearchData(e.target.value);
        setSearchDataSlugify(slugify(e.target.value));
    }
    return (<nav className="fixed z-[2000] inset-x-0 h-[65px] bg-white border-b broder-gray-300 py-3 pr-5 w-full">
                <div className="flex items-center justify-between space-x-3">
                    <div className='flex items-center space-x-10'>
                        <div className="w-24">
                        <Disclosure.Button className="block mx-auto">
                            <svg    id="Outline" 
                                    viewBox="0 0 24 24" 
                                    className="h-6 w-6 text-gray-700 fill-current">
                                <rect y="11" width="24" height="2" rx="1"/>
                                <rect y="4" width="24" height="2" rx="1"/>
                                <rect y="18" width="24" height="2" rx="1"/>
                            </svg>
                        </Disclosure.Button>
                        </div>
                        <h1 className="hidden md:inline font-extrabold text-gray-700 text-xl">Bardeleconomie</h1>
                    </div>

                    <div className="relative block w-96">
                        {/* <Link to={`/admin/recherche/${searchDataSlugify}`} className="absolute inset-y-0 right-0 flex items-center pr-4"> */}
                            <button className="absolute block inset-y-0 right-0 flex items-center pr-4" onClick={() => {search(searchDataSlugify); navigate(`/admin/recherche/${searchDataSlugify}`,{replace:true});}}>
                                <svg    id="Outline" 
                                        viewBox="0 0 24 24"
                                        className="h-4 w-4 fill-current text-gray-700">
                                    <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/>
                                </svg>
                            </button>
                        {/* </Link> */}
                        <input  type="text"
                                value={searchData}
                                onChange={(e) => handleSearchChange(e)}
                                className="w-full placeholder:text-gray-700 pl-5 pr-12 py-2 rounded-full bg-gray-200 outline-none text-gray-700"
                                placeholder='Recherche'/>

                    </div>

                    <div className='bg-rose-500 p-2 rounded'>
                        <Menu as="div" className="relative">
                            <div>
                                <Menu.Button>
                                    <svg    id="Outline" 
                                            viewBox="0 0 24 24" 
                                            className="h-5 w-5 text-white fill-current bg-rose-500">
                                        <path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z"/>
                                        <path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"/>
                                    </svg>
                                </Menu.Button>
                            </div>
                            <div>
                                <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg">
                                    <div className="p-1">
                                        <Menu.Item>{(({active}) => (
                                            <button
                                            className={`${
                                            active ? 'bg-rose-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <ProfileActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <ProfileInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            <span className="ml-2">Profile</span>
                                        </button>
                                        ))}
                                        </Menu.Item>
                                        <Menu.Item>{(({active}) => (
                                            <button
                                            className={`${
                                            active ? 'bg-rose-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <SettingActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <SettingInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            <span className="ml-2">Paramètres</span>
                                        </button>
                                        ))}
                                        </Menu.Item>
                                        <Menu.Item>{(({active}) => (
                                            <button
                                            onClick={() => handlelogout()}
                                            className={`${
                                            active ? 'bg-rose-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <LogoutActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <LogoutInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            <span className="ml-2">Déconnexion</span>
                                        </button>
                                        ))}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </div>
                        </Menu>
                    </div>
                </div>
            </nav>)
}