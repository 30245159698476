import React,{useContext, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import { Link, useLocation } from 'react-router-dom';
import { PageContext } from './pageProvider';

export default function PagePreview(props) {
    const {currentPage} = useContext(PageContext);
    const location = useLocation();
    let from = location.state?.from?.pathname || "/admin/page/liste"
    useEffect(() => {
    }, [currentPage]);

    if (props.currentPage) {
        return (<div className="mt-10">
                    <div className="max-w-7xl mx-auto py-10 bg-white rounded-xl">
                        <div className="">
                            <div className="space-y-5">
                                <h2 className="text-gray-700 text-4xl font-bold">{props.currentPage.titre || "Pas de titre"}</h2>
                            </div>
                            <div className="mt-10 prose-base">
                                {props.currentPage.contenu ? 
                                parse(props.currentPage.contenu)
                                : "Pas de contenu"}
                            </div>
                        </div>
                    </div>
                </div>);
    } else {
        return (<div>
            <div className="max-w-7xl mx-auto m-10">
                <div className="mt-10">
                    <div>
                        <h2 className="text-gray-700 text-4xl font-bold text-center">Rien à afficher</h2>
                    </div>
                </div>
            </div>
        </div>);
    }
}