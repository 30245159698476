import React from 'react';
import searchService from './private/admin/pages/search/searchService';
// import jwt from 'jsonwebtoken';
import {jwtVerify, importSPKI} from 'jose';

export const AppContext = React.createContext();

const client_pubkey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0y0EcB/h+RnErhYQOw/T
EnIwTAZwCNrDhzrMiZdPJ2i0Nji57a8DBKnJ4mpssnLUPens/yXVL6NkKO5SB/gq
J4SoFplZr6Re7Ev9uqa9E3IAiVQy0ZsmUN++GMohdhAQlG1L+Ldsf3PJLBggZrCe
cJjgv7Mdm5iNjQbE6Lbdg2DrAvYlLTcSOOpjQ0t/OgEzrpzI7Z4u0o+hEJuicuY/
j8Fedx+IN1QUs9jzlJZf4foOPcnOuBrp9FFixicOMhDc9M+M4Yz6OJOBnuyazqzk
YTKPcP0zc4Fk+SNICZAzIw0kyXZ6OXZKfz0uz2AveozJuPBCDT/HUsmvQB8CtD6a
xwIDAQAB
-----END PUBLIC KEY-----`;

const admin_pubkey =`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA12Jb7vnCYjzAjQUI630h
5vLlo24AkYiUK51BXeJ868rdVkKph3bVAWgVxSioOpT75ywpooyt91mYkKtKUvMy
M4lwKSxsu4eTX6e7Y0A7mIPGElx+lkKCbT8OaBzv3ZaIKAx5edDf7BjWgSUJk8a6
rd0z/QoxJ0CSukX3m2ovNA3JL9K7aad8Q2fV84xNsIEt6YyAKZ02XfCE8mTx762J
mT0SH91oVniBvsZ5wgT9H6b41+YNfFr3OFkriVC0FBlhuTIIubZrzmajFpZ7hIjA
K9/A6W0F/sdZatMUsP2cjWR6IeMzpolf6oQOly6vz1f8vV5KTVkDLXYNVJJ/+GQp
wQIDAQAB
-----END PUBLIC KEY-----`;

class AppProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: null,
            token: null,
            user:  null,
            isAuthenticated: false,
            currentCategorie: null,
            isAuthenticatedClient: false,
            notifications: [],
            results: [],
            clientResults: [],
            currentType: "",
            duration: "",
            clientKey: null,
            darkMode: "",
            role:"",
            historique: []
        };
        this.setAuthenticated = this.setAuthenticated.bind(this);
        this.init = this.init.bind(this);
        this.setCurrentCategorie = this.setCurrentCategorie.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.initializeUser = this.initializeUser.bind(this);
        this.initializeClient = this.initializeClient.bind(this);
        this.setNotification = this.setNotification.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.search = this.search.bind(this);
        this.setResults = this.setResults.bind(this);
        this.setClientResults = this.setClientResults.bind(this);
        this.clientSearch = this.clientSearch.bind(this);
        this.setDuration = this.setDuration.bind(this);
        this.setDarkMode = this.setDarkMode.bind(this);
        this.setHistorique = this.setHistorique.bind(this);
    }
    componentDidMount() {
        this.init();
    }

    async init() {
        let token = localStorage.getItem("token");
        let darkMode = localStorage.getItem("darkMode");
        let modified_clientkey = await importSPKI(client_pubkey,"RS256");
        let modified_adminkey = await importSPKI(admin_pubkey,"RS256");

        const client_payload = await jwtVerify(token, modified_clientkey).catch(error => {

        });
        const admin_payload = await jwtVerify(token, modified_adminkey).catch(error => {
        })

        if (client_payload) {
            this.setState({client: client_payload.payload, role: "CLIENT", token, isAuthenticated :true, isAuthenticatedClient:true, darkMode});
        } else if (admin_payload) {
            this.setState({client: admin_payload.payload, role:"ADMIN", token, isAuthenticated : true, isAuthenticatedClient:true, darkMode});
        } else {
            localStorage.removeItem("token")
            this.setState({client: null, role:"", token, isAuthenticated : null, isAuthenticatedClient:null, darkMode});
        }

    }

    async initializeClient(token) {
        if (token) {
            localStorage.setItem("token", token);
            
            let modified_clientkey = await importSPKI(client_pubkey,"RS256");
            let modified_adminkey = await importSPKI(admin_pubkey,"RS256");


            const client_payload = await jwtVerify(token, modified_clientkey).catch(error => {
                console.log();
            });
            const admin_payload = await jwtVerify(token, modified_adminkey).catch(error => {
                console.log();
            })

            if (client_payload) {
                this.setState({client: client_payload.payload, role: "CLIENT", isAuthenticatedClient: true});
                return true;
            } else if (admin_payload) {
                this.setState({client: admin_payload.payload, role:"ADMIN", isAuthenticatedClient: true});
                return true;
            } else {
                return false
            }
        }

        return false
    }

    initializeUser(value) {
        if (value) {
            localStorage.setItem("currentUser", JSON.stringify(value));
            this.setState({user: value});
            return true;
        }

        return false
    }


    isAuthenticated() {
        let isAuth = parseInt(localStorage.getItem('isAuthenticated'));
        return isAuth;
    }

    setAuthenticated(value) {
        localStorage.setItem("isAuthenticated", value ? 1 : 0)
        this.setState({isAuthenticated: value})
    }

    setAuthenticatedClient(value) {
        localStorage.setItem("isAuthenticatedClient", value ? 1 : 0)
        this.setState({isAuthenticatedClient: value})
    }

    setCurrentCategorie(categorie, type) {
        this.setState({currentCategorie: categorie, currentType: type});
    }

    setNotification(notification) {
        // this.state.notifications.push(notification);
        this.state.notifications.push(notification);
        this.setState(prevState => {
            return {notifications: [...prevState.notifications]}
        })
    }

    closeNotification(id) {
        this.state.notifications.splice(id,1)
        this.setState(prevState => {
            return {notifications: [...prevState.notifications]}

        })
    }

    search(value) {
        searchService.getAllSearch({words: value})
            .then(response => {
                this.setState({results: [...response.data]});
            })
            .catch(error => {
                console.error(error)
            })
    }

    clientSearch(value) {
        searchService.getAllSearch({words: value, duration: this.state.duration})
            .then(response => {
                this.setState({clientResults: [...response.data]});
            })
            .catch(error => {
                console.error(error)
            })
    }


    setResults(values) {
        this.setState({results: values});
    }

    setClientResults(values) {
        this.setState({clientResults: values})
    }

    setDuration(value, searchdata) {
        if (value == this.state.duration) {
            this.setState({duration: ""});
        } else {
            this.setState({duration: value});
        }
    }

    setDarkMode(value) {
        localStorage.setItem("darkMode", value);
        this.setState({darkMode: value});
    }

    logout() {
        localStorage.removeItem("token");
        this.setState({isAuthenticatedClient: false, client: null, role: null});
    }

    setHistorique(historique) {
        this.setState({historique})
    }

    render() {
        const {user, isAuthenticated,isAuthenticatedClient, currentCategorie, client, notifications, results, clientResults,token, currentType, duration, darkMode,role, historique} = this.state;
        const { children } = this.props;
        const configObject = {
            user,
            client,
            token,
            isAuthenticatedClient,
            isAuthenticated,
            currentCategorie,
            notifications,
            results,
            clientResults,
            currentType,
            duration,
            darkMode,
            role,
            historique,
            setHistorique: (historique) => this.setHistorique(historique),
            logout: () => this.logout(),
            setDarkMode: (value) => this.setDarkMode(value),
            setDuration: (value, searchdata) => this.setDuration(value, searchdata),
            clientSearch : (value) =>  this.clientSearch(value),
            setClientResults: (data) =>  this.setClientResults(data),
            search: (value) => this.search(value),
            setNotification: (notification) => this.setNotification(notification),
            setResults: (data) => this.setResults(data),
            closeNotification: (id) =>  this.closeNotification(id),
            setAuthenticated: (value) => this.setAuthenticated(value),
            setCurrentCategorie: (categorie, type) => this.setCurrentCategorie(categorie, type),
            initializeUser:(value) => this.initializeUser(value),
            initializeClient: (token) => this.initializeClient(token),
            setAuthenticatedClient: (value) => this.setAuthenticatedClient(value)
        }

        return (<AppContext.Provider value={configObject}>
                    {children}
                </AppContext.Provider>)
    }
}

export default AppProvider;