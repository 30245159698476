import React, { useEffect, useState } from 'react';
import tagService from './tagService';
import config from '../../../../config/conifg.json';
import { Link } from 'react-router-dom';

export default function TagList(props) {
    const [tags, setTags] = useState([]);
    const [currentTagId, setCurrentTagId] = useState(null);
    useEffect(() => {
        tagService.getAllTag()
            .then(response => {
                if (response.data) {
                    setTags(response.data);
                }
            })
            .catch(error => {
                console.error(error);
            })
    },[])

    const deleteTag = (id) => {
        tagService.deleteOneTagById(id)
            .then(response => {
                let current = tags.findIndex(item => item.id == response.id);

                if (current >= 0) {
                    tags.splice(current,1);
                    setTags(prevState => {
                        return [...prevState]
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }
    return (<div classNamae="m-10">
                <div className="p-10">
                    <div>
                        <h2 className="text-2xl font-bold text-gray-700">Tous les tags</h2>
                        <Link to="/admin/tag/ajout">
                            <button className="flex mt-3 px-4 py-2 rounded-md bg-blue-500 text-white font-semibold text-basic">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                <span>
                                    Ajouter tag
                                </span>
                            </button>
                        </Link>
                    </div>
                    <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10'>
                        {tags.length ? 
                            tags.map(item => (
                                <div className="flex space-x-5 bg-white rounded-xl items-center p-3 overflow-hidden">
                                    <div className='space-y-3'>
                                        <h3 className="text-gray-700 font-bold text-xl">#{item.title}</h3>
                                    </div>
                                    <div className="flex space-x-5">
                                        {currentTagId && item.id == currentTagId ? 
                                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-orange-100" onClick={() => deleteTag(item.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                            </button>
                                        :
                                            <button className="flex items-center space-x-2 px-4 p-1 rounded-full bg-red-100" onClick={() => setCurrentTagId(item.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                            </button>
                                        }


                                        <Link to={`/admin/tag/edit/${item.id}`} className="flex items-center space-x-2 px-4 p-1 rounded-full bg-blue-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 stroke-current text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        :null}
                    </div>
                </div>
            </div>)
}