import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from "../../../config/conifg.json";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../appProvider";
import profileService from "./profileService";
const API_URL = config.API_URL;

function CheckCircleIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 text-green-500 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
}

function WarningIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 text-yellow-500 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
        </svg>
    );
}

function DangerIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 text-red-500 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
}

function InfoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 text-blue-500 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
}

let status = [
    {
        textColor: "text-yellow-500",
        icon: <WarningIcon />,
    },
    {
        textColor: "text-red-500",
        icon: <DangerIcon />,
    },
    {
        textColor: "text-blue-500",
        icon: <InfoIcon />,
    },
    {
        textColor: "text-green-500",
        icon: <CheckCircleIcon />,
    },
];

export default function Profile(props) {
    const { client, token } = useContext(AppContext);
    const [profile, setProfile] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const profileRef = useRef(null);
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const [currentItem, setCurrentItem] = useState("coordonnées");
    const [currentNotification, setCurrentNotification] = useState(null);
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    const handleFileChange = (e) => {
        setProfile(e.target.files[0]);
        formikRef.current.setFieldValue("profile", e.target.files[0]);
    };

    useEffect(() => {
        if (client) {
            profileService
                .getCurrentUserById(client.id)
                .then((response) => {
                    console.log("profile  :", response);
                    setCurrentUser(response.data);

                    formikRef.current.setFieldValue("nom", response.data?.nom);
                    formikRef.current.setFieldValue(
                        "prenom",
                        response.data?.prenom
                    );
                    formikRef.current.setFieldValue(
                        "email",
                        response.data?.email
                    );
                    formikRef.current.setFieldValue(
                        "prevent_exclusive_blog",
                        response.data?.prevent_exclusive_blog
                    );
                    formikRef.current.setFieldValue(
                        "prevent_public_blog",
                        response.data?.prevent_public_blog
                    );
                })
                .catch((error) => {});
        }
    }, [client]);

    const closeCurrentNotification = () => {
        setCurrentNotification(null);
    };

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field);
    };

    const handleItemChange = (item) => {
        setCurrentItem(item);
    };

    return (
        <>
            <div className="py-10 bg-gray-200">
                <h2 className="ml-20 text-4xl text-gray-700 font-bold">
                    Profile
                </h2>
            </div>

            <div className="flex justify-center divide-gray-300 divide-x m-10 ">
                <div className="">
                    <ul>
                        <li>
                            <button
                                className="flex items-center space-x-3 text-gray-700 font-bold text-lg py-1 px-5"
                                onClick={() => {
                                    handleItemChange("coordonnées");
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span className="text-md font-semibold">
                                    Coordonnées
                                </span>
                            </button>
                        </li>
                        <li>
                            <button
                                className="flex items-center space-x-3 text-gray-700 font-bold text-lg py-1 px-5"
                                onClick={() => {
                                    handleItemChange("password");
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                    />
                                </svg>
                                <span className="text-md font-semibold">
                                    {" "}
                                    Mot de passe
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="px-10">
                    {currentNotification ? (
                        <div className="mb-10">
                            <div className="relative flex items-center bg-gray-200  p-5 space-x-5">
                                <div className="absolute top-2 right-2">
                                    <button
                                        onClick={() =>
                                            closeCurrentNotification()
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-red-500 fill-current"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div>
                                    {status[currentNotification.code].icon}
                                </div>
                                <div>
                                    <h2
                                        className={`${
                                            status[currentNotification.code]
                                                .textColor
                                        } text-lg font-bold`}
                                    >
                                        {currentNotification.title}
                                    </h2>
                                    <p className="">
                                        {" "}
                                        {currentNotification.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {currentItem == "password" ? (
                        <div className="bg-white w-[500px]">
                            <h3 className="text-2xl font-bold text-gray-600 text-center">
                                Changer mot de passe
                            </h3>
                            <Formik
                                initialValues={{
                                    password: "",
                                }}
                                validationSchema={Yup.object({
                                    password: Yup.string().required(
                                        "Mot de passe requis"
                                    ),
                                    confirmation: Yup.string()
                                        .oneOf(
                                            [Yup.ref("password"), null],
                                            "Le mote de passe doit correspondre"
                                        )
                                        .required("Confirmation requis"),
                                })}
                                onSubmit={(values) => {
                                    profileService
                                        .changePassword(token, values)
                                        .then((response) => {
                                            if (response) {
                                                setCurrentNotification({
                                                    code: 3,
                                                    content: response.message,
                                                    title: "Succès",
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            if (error && error.response) {
                                                setCurrentNotification({
                                                    code: 1,
                                                    content:
                                                        error.response.data
                                                            .message,
                                                    title: "Error",
                                                });
                                            } else {
                                                setCurrentNotification({
                                                    code: 1,
                                                    content: error.message,
                                                    title: "Error",
                                                });
                                            }
                                            console.error(error);
                                        });
                                }}
                            >
                                {(formik) => (
                                    <>
                                        <Form
                                            className="space-y-5 text-left mt-10"
                                            onSubmit={formik.handleSubmit}
                                        >
                                            <div className="w-full relative">
                                                <label className="block text-lg text-semibold text-gray-700">
                                                    Mot de passe{" "}
                                                    <span className="text-red-700">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    {...formik.getFieldProps(
                                                        "password"
                                                    )}
                                                    onFocus={() =>
                                                        handleDidFocus(
                                                            "password"
                                                        )
                                                    }
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${
                                                        (!!didFocus &&
                                                            currentField ==
                                                                "password" &&
                                                            formik.errors
                                                                .password) ||
                                                        (formik.touched
                                                            .password &&
                                                            formik.errors
                                                                .password)
                                                            ? "border-red-500"
                                                            : null
                                                    } `}
                                                />
                                                {(!!didFocus &&
                                                    currentField ==
                                                        "password" &&
                                                    formik.errors.password) ||
                                                (formik.touched.password &&
                                                    formik.errors.password) ? (
                                                    <span className="absolute left-0 top-[101%] text-red-500 text-sm">
                                                        {formik.errors.password}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="w-full relative">
                                                <label className="block text-lg text-semibold text-gray-700">
                                                    Confirmation{" "}
                                                    <span className="text-red-700">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="confirmation"
                                                    name="confirmation"
                                                    type="password"
                                                    {...formik.getFieldProps(
                                                        "confirmation"
                                                    )}
                                                    onFocus={() =>
                                                        handleDidFocus(
                                                            "confirmation"
                                                        )
                                                    }
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${
                                                        (!!didFocus &&
                                                            currentField ==
                                                                "confirmation" &&
                                                            formik.errors
                                                                .confirmation) ||
                                                        (formik.touched
                                                            .confirmation &&
                                                            formik.errors
                                                                .confirmation)
                                                            ? "border-red-500"
                                                            : null
                                                    } `}
                                                />
                                                {(!!didFocus &&
                                                    currentField ==
                                                        "confirmation" &&
                                                    formik.errors
                                                        .confirmation) ||
                                                (formik.touched.confirmation &&
                                                    formik.errors
                                                        .confirmation) ? (
                                                    <span className="absolute left-0 top-[101%] text-red-500 text-sm">
                                                        {
                                                            formik.errors
                                                                .confirmation
                                                        }
                                                    </span>
                                                ) : null}
                                            </div>

                                            <div className="space-x-10">
                                                <button
                                                    type="submit"
                                                    className="py-2 px-4 bg-[#ff00ff] text-white w-full"
                                                >
                                                    Changer
                                                </button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    ) : currentItem == "coordonnées" ? (
                        <div className="bg-white w-[500px]">
                            <div className="w-full max-w-xl mx-auto">
                                <h3 className="text-2xl font-bold text-gray-600 text-center">
                                    Modifier coordonnées
                                </h3>
                                <Formik
                                    innerRef={formikRef}
                                    initialValues={{
                                        nom: currentUser?.nom,
                                        prenom: currentUser?.prenom,
                                        email: currentUser?.email,
                                        profile: "",
                                        prevent_exclusive_blog: currentUser?.prevent_exclusive_blog,
                                        prevent_public_blog: currentUser?.prevent_public_blog
                                    }}
                                    validationSchema={Yup.object({
                                        nom: Yup.string().required(
                                            "Nom requis"
                                        ),
                                        prenom: Yup.string().required(
                                            "Prenom requis"
                                        ),
                                        email: Yup.string()
                                            .email(
                                                "Email doit être un valide email"
                                            )
                                            .required("Email requis"),
                                    })}
                                    onSubmit={(values) => {
                                        console.log("profile : ", values);

                                        const formdata = new FormData();
                                        formdata.append("nom", values.nom);
                                        formdata.append(
                                            "prenom",
                                            values.prenom
                                        );
                                        formdata.append("email", values.email);
                                        formdata.append("file", values.profile);
                                        formdata.append("type", values.type);
                                        formdata.append(
                                            "prevent_exclusive_blog",
                                            values.prevent_exclusive_blog
                                                ? 1
                                                : 0
                                        );

                                        formdata.append(
                                            "prevent_public_blog",
                                            values.prevent_public_blog
                                                ? 1
                                                : 0
                                        );


                                        axios
                                            .put(
                                                `${API_URL}/user/client/update/${client.id}`,
                                                formdata
                                            )
                                            .then((response) => {
                                                if (response) {
                                                    let temp_currentUser = currentUser;

                                                    temp_currentUser.nom = values.nom;
                                                    temp_currentUser.prenom = values.prenom;
                                                    temp_currentUser.email = values.email;
                                                    temp_currentUser.prevent_exclusive_blog = values.prevent_exclusive_blog;
                                                    temp_currentUser.prevent_public_blog = values.prevent_public_blog;
                                                
                                                    setCurrentUser(temp_currentUser);

                                                    setCurrentNotification({
                                                        code: 3,
                                                        content:
                                                            response.message,
                                                        title: "Succès",
                                                    });
                                                }
                                            })
                                            .catch((error) => {
                                                if (error && error.response) {
                                                    setCurrentNotification({
                                                        code: 1,
                                                        content:
                                                            error.response.data
                                                                .message,
                                                        title: "Error",
                                                    });
                                                } else {
                                                    setCurrentNotification({
                                                        code: 1,
                                                        content: error.message,
                                                        title: "Error",
                                                    });
                                                }
                                            });
                                    }}
                                >
                                    {(formik) => (
                                        <Form
                                            className="space-y-5 w-full mt-10"
                                            onSubmit={formik.handleSubmit}
                                            onReset={formik.handleReset}
                                        >
                                            <div>
                                                <div
                                                    className="relative grid content-center group hover:border-blue-500 w-36 h-36 mx-auto rounded-full border-2 border-dashed border-gray-400 overflow-hidden"
                                                    onClick={() =>
                                                        profileRef.current.click()
                                                    }
                                                >
                                                    {profile ? (
                                                        <div className="h-28 w-28 mx-auto overflow-hidden rounded-full">
                                                            <img
                                                                className="h-full w-full object-cover"
                                                                src={URL.createObjectURL(
                                                                    profile
                                                                )}
                                                            />
                                                        </div>
                                                    ) : currentUser?.File ? (
                                                        <div className="h-28 w-28 mx-auto overflow-hidden rounded-full">
                                                            <img
                                                                className="h-full w-full object-cover"
                                                                src={`${config.API_URL}${currentUser.File.url}`}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <svg
                                                            className="h-34 w-34 mx-auto fill-current text-gray-300 group-hover:text-blue-300"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    )}

                                                    <span className="block bg-white text-center absolute bottom-3 inset-x-0 text-gray-700 text-sm text-semibold group-hover:text-blue-500">
                                                        <span className="text-2xl font-bold">
                                                            +
                                                        </span>{" "}
                                                        Ajouter
                                                    </span>
                                                    <input
                                                        hidden
                                                        type="file"
                                                        ref={profileRef}
                                                        onChange={(e) =>
                                                            handleFileChange(e)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full relative">
                                                <label className="block text-lg text-semibold text-gray-700">
                                                    Nom
                                                    <span className="text-red-700">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="nom"
                                                    name="nom"
                                                    type="text"
                                                    {...formik.getFieldProps(
                                                        "nom"
                                                    )}
                                                    onFocus={() =>
                                                        handleDidFocus("nom")
                                                    }
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${
                                                        (!!didFocus &&
                                                            currentField ==
                                                                "nom" &&
                                                            formik.errors
                                                                .nom) ||
                                                        (formik.touched.nom &&
                                                            formik.errors.nom)
                                                            ? "border-red-500"
                                                            : null
                                                    } `}
                                                />
                                                {(!!didFocus &&
                                                    currentField == "nom" &&
                                                    formik.errors.nom) ||
                                                (formik.touched.nom &&
                                                    formik.errors.nom) ? (
                                                    <span className="absolute left-0 top-[101%] text-red-500 text-sm">
                                                        {formik.errors.nom}
                                                    </span>
                                                ) : null}
                                            </div>

                                            <div className="w-full relative">
                                                <label className="block text-lg text-semibold text-gray-700">
                                                    Prénom{" "}
                                                    <span className="text-red-700">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="prenom"
                                                    name="prenom"
                                                    type="text"
                                                    {...formik.getFieldProps(
                                                        "prenom"
                                                    )}
                                                    onFocus={() =>
                                                        handleDidFocus("prenom")
                                                    }
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${
                                                        (!!didFocus &&
                                                            currentField ==
                                                                "prenom" &&
                                                            formik.errors
                                                                .prenom) ||
                                                        (formik.touched
                                                            .prenom &&
                                                            formik.errors
                                                                .prenom)
                                                            ? "border-red-500"
                                                            : null
                                                    } `}
                                                />
                                                {(!!didFocus &&
                                                    currentField == "prenom" &&
                                                    formik.errors.prenom) ||
                                                (formik.touched.prenom &&
                                                    formik.errors.prenom) ? (
                                                    <span className="absolute left-0 top-[101%] text-red-500 text-sm">
                                                        {formik.errors.prenom}
                                                    </span>
                                                ) : null}
                                            </div>

                                            <div className="relative">
                                                <label className="block text-lg text-semibold text-gray-700">
                                                    Email{" "}
                                                    <span className="text-red-700">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    {...formik.getFieldProps(
                                                        "email"
                                                    )}
                                                    onFocus={() =>
                                                        handleDidFocus("email")
                                                    }
                                                    className={`bg-gray-200 py-2 px-3 w-full outline-none border-b ${
                                                        (!!didFocus &&
                                                            currentField ==
                                                                "email" &&
                                                            formik.errors
                                                                .email) ||
                                                        (formik.touched.email &&
                                                            formik.errors.email)
                                                            ? "border-red-500"
                                                            : null
                                                    } `}
                                                />
                                                {(!!didFocus &&
                                                    currentField == "email" &&
                                                    formik.errors.email) ||
                                                (formik.touched.email &&
                                                    formik.errors.email) ? (
                                                    <span className="absolute left-0 top-[101%] text-red-500 text-sm">
                                                        {formik.errors.email}
                                                    </span>
                                                ) : null}
                                            </div>

                                            <div className="">
                                                <input
                                                    type="checkbox"
                                                    name="prevent_exclusive_blog"
                                                    id="prevent_exclusive_blog"
                                                    className="bg-gray-200 mr-2  outline-none"
                                                    checked={
                                                        formik.values
                                                            .prevent_exclusive_blog
                                                    }
                                                    {...formik.getFieldProps(
                                                        "prevent_exclusive_blog"
                                                    )}
                                                ></input>
                                                <label
                                                    htmlFor="prevent_exclusive_blog"
                                                    className="dark:text-gray-400 text-gray-700 text-lg text-semibold"
                                                >
                                                    Prévenir en cas de nouvel
                                                    article exclusif
                                                </label>
                                            </div>

                                            <div className=''>
                                                <input      type="checkbox"
                                                            name="prevent_public_blog" 
                                                            id="prevent_public_blog"
                                                            className="bg-gray-200 mr-2  outline-none"
                                                            defaultChecked={formik.values.prevent_public_blog}
                                                            {...formik.getFieldProps("prevent_public_blog")}>
                                                </input>
                                                <label className="dark:text-gray-400 text-gray-700 text-lg text-semibold">Prévenir en cas de nouvel article</label>
                                            </div>

                                            <div className="flex space-x-10 mt-10">
                                                <button
                                                    type="submit"
                                                    className="py-2 px-4 bg-[#ff00ff] text-white w-full"
                                                >
                                                    Sauvegarder
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}
