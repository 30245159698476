import React, { useEffect, useState, useContext, useRef, forwardRef } from 'react';
import {Editor} from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import {EditorState, convertToRaw, Modifier, ContentBlock , DefaultDraftBlockRenderMap,convertFromRaw, convertFromHTML,ContentState, Entity, CompositeDecorator} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BlogContext } from '../blog/blogProvider';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import categorieService from '../categorie/categorieService';
import subCategorieService from '../subCategorie.js/subCategorieService';
import tagService from '../tag/tagService';
import blogService from './blogService';
import axios from 'axios';
import config from '../../../../config/conifg.json'
import { AppContext } from '../../../../appProvider';
import Immutable from 'immutable';
import Datepicker from '@themesberg/tailwind-datepicker/Datepicker';
import moment from 'moment';
import userService from '../user/userService';
import BlogPreview from './blogPreview';
import authorService from '../author/authorService';
import getVideoId from 'get-video-id';
import Multiselect from '../../../../shared/dropdown/dropdown';


const API_URL = config.API_URL;

const hashConfig = {
    trigger: '#',
    separator: '<br>',
}

const directional = false


// Note: these aren't very good regexes, don't use them!
// const HANDLE_REGEX = /\@[\w]+/g;
// const HASHTAG_REGEX = /\#[\w\u0590-\u05ff]+/g;

// function findWithRegex(regex, contentBlock, callback) {
//     const text = contentBlock.getText();
//     let matchArr, start;
//     while ((matchArr = regex.exec(text)) !== null) {
//       start = matchArr.index;
//       callback(start, start + matchArr[0].length);
//     }
//   }

// function handleStrategy(contentBlock, callback, contentState) {
//   findWithRegex(HANDLE_REGEX, contentBlock, callback);
// }

// const HandleSpan = props => {
//     return (
//       <span {...props} className="bg-red-500 text-white">
//         {props.children}
//       </span>
//     );
//   };


//   const onlyHashtags = new CompositeDecorator([
//     {
//       strategy: handleStrategy,
//       component: HandleSpan,
//     },
//   ]);

// const IframeBlock = (props) => {
//     return (<iframe width="560" height="315" src="https://www.youtube.com/embed/ekgUjyWe1Yc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>)
// }


// const blockRenderMap = Immutable.Map({
//     iframe: {
//         element: 'iframe',
//         wrapper: <IframeBlock/>
//     }
// })

// const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);


export default function BlogAdd(props) {
    const navigate = useNavigate();
    const {setNotification} = useContext(AppContext);
    // const {setCurrentBlog} = useContext(BlogContext);
    const [currentBlog, setCurrentBlog] = useState(null);
    // const [editorState, setEditorState] = useState(EditorState.createEmpty(onlyHashtags));
    // const [contentState, setContentState] = useState(EditorState.createEmpty(onlyHashtags));
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [contentState, setContentState] = useState(EditorState.createEmpty());
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategorieFiltered, setSubCategorieFiltered] = useState([]);
    const [authors, setAuthors] = useState([]);
    const ref = useRef(null);
    const featuredImageRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const datepickerRef = useRef();
    const [editorRef, setEditorRef] = useState(null);
    const [scriptValue, setScriptValue] = useState("");
    const [onChange, setOnChange] = useState();
    const [checked, setChecked] = useState(false);

    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const [users, setUsers] = useState([]);
    const [previewStatus, setPreviewStatus] = useState(false);

    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [selectedCategorie, setSelectedCategorie] = useState(null);
    const [selectedSubCategorie, setSelectedSubCategorie] = useState(null);

    const [videoIframe, setVideoIframe] = useState(null);
    const [videoImage, setVideoImage] = useState(null);
    const [url, setUrl] = useState("");

    const [checkedCategorie, setCheckedCategorie] = useState(false);

    const [currentThumbnail, setCurrentThumbnail] = useState(null);

    const [categoriesDropdown, setCategoriesDropDown] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const downloadImageRef = useRef(null)

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }
    const handleChange = (value) => {
        setEditorState(value);
    //     ref.current.setFieldValue("article", draftToHtml(convertToRaw(editorState.getCurrentContent()),{
    //         hashConfig,
    //         directional
    //     }));
    }

    const onEditorStateChange = (value) => {
        setEditorState(value);
        ref.current.setFieldValue("article", draftToHtml(convertToRaw(editorState.getCurrentContent()),{
            hashConfig,
            directional
        }));
    }

    const onContentStateChange = (value) => {
        setContentState(value)
    }
    useEffect(() => {
        // new Datepicker(datepickerRef.current)
        categorieService.getAllCategorie()
            .then(response => {
                if (response.data) {
                    setCategories(response.data)
                    setCategoriesDropDown(response.data.map(item => ({id: item.id,text: item.title, value: item.id})))
                }
            })
            .catch(error => {
                console.error()
            })
            
        tagService.getAllTag()
            .then(response => {
                if (response.data) {
                    setTags(response.data)
                }
            })
            .catch(error => {
                console.error()
            })
        
        subCategorieService.getAllSubCategorie()
            .then(response => {
                if (response.data) {
                    setSubCategories(response.data)
                }
            })
            .catch(error => {
                console.error()
            })
        
        userService.getAllUser()
            .then(response => {
                if (response.data) {
                    setUsers(response.data)
                }
            })
            .catch(error => {
                console.error();
            })

        authorService.getAllAuthor()
            .then(response => {
                if (response.data) {
                    setAuthors(response.data)
                }
            })
            .catch(error => {
                console.error();
            })
        
    }, [])

    useEffect(() => {
        let currentData = convertToRaw(editorState.getCurrentContent());
        setCurrentBlog({
            title: ref.current.values.title,
            article: draftToHtml(currentData,{
                    hashConfig,
                    directional
                }),
            tags: ref.current.values.tags,
            status: ref.current.values.status,
            excerpt: ref.current.values.excerpt,
            featured_image: ref.current.values.featured_image,
            author: ref.current.values.author,
            date_publication: ref.current.values.date_publication,
            entreprise_email: ref.current.values.entreprise_email,
            other_categories: ref.current.values.other_categories
            });
    },[editorState])

    const handleFileChange = (e) => {
        setFiles([...e.target.files]);
        ref.current.setFieldValue("featured_image",e.target.files[0]);
        setCurrentBlog(prevState => {prevState.featured_image = e.target.files[0]; return prevState});
        setCurrentThumbnail(null);
    }

    const uploadImageCallback =(file) => {
        return new Promise((resolve, reject) => {
            let formdata = new FormData();
            formdata.append("file", file);

        axios.post(`${API_URL}/file/upload`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // setPercent(percent);
            } 
            }).then(response => {
                resolve({data:{link: `http://www.bardeleconomie.fr${response.data.data.url}`}})
            }).catch(error => {
                reject(error)
            })
            
        })
    }

    const filterSubCategorie = (value) => {

        let current = [];
        let all_sub_cateogrie = [];
        for (let categorie of categories) {
            for (let select_cat of selectedCategories) {
                if (select_cat.id == categorie.id) {
                    current.push(categorie)
                }
            }
        }

        for (let sub_categorie of current) {
            all_sub_cateogrie = [...all_sub_cateogrie,...sub_categorie.SubCategories]
        }

        setSubCategorieFiltered(all_sub_cateogrie)
    }

    // const embeddedCallback = (value) => {
    //     let src_regex = /(?<=src=").*?(?=[\?"])/i
    //     let result = src_regex.exec(value);
    //     return result;
    // }

    const handleScriptIframe = () => {
            const draft = htmlToDraft(`<p></p> <div class="w-full bg-red-300">${scriptValue}</div> <p></p>`);
            let contentState = ContentState.createFromBlockArray(draft.contentBlocks, draft.entityMap);
            let blockMap = contentState.getBlockMap();
            contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                blockMap
            )
            const newt_editor_state =  EditorState.push(editorState,contentState,'insert-characters');
            setEditorState(newt_editor_state)
    }


    const setEditorReference = (ref) => {
        setEditorRef(ref);
    }

    const handlePresentation = (e) => {
        setChecked(e.target.checked);
    }

    const handleCurrentAuthor = (id) => {
        let currentAuthor = authors.find(item => item.id == id);
        setSelectedAuthor(currentAuthor);
    }

    const handleCurrentCategorie = (id) => {
        let currentCategorie = categories.find(item => item.id == id);
        setSelectedCategorie(currentCategorie);
    }

    const handleCurrentSubCategorie = (id) => {
        let currentSubCategorie = subCategories.find(item => item.id == id);
        setSelectedSubCategorie(currentSubCategorie);
    }

    const handleUrl = (value) => {
        if (value) {
            let url_data = getVideoId(value);
            let sample_iframe = `<p></p><div><iframe width="100%" height="465" src="https://www.youtube.com/embed/${url_data.id}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div><p></p>`;
            let sample_image =`https://img.youtube.com/vi/${url_data.id}/maxresdefault.jpg`;


            setVideoIframe(sample_iframe);
            setVideoImage(sample_image);

            let draft= htmlToDraft(sample_iframe);

            let contentState = ContentState.createFromBlockArray(draft.contentBlocks, draft.entityMap);
            let blockMap = contentState.getBlockMap();
            contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                blockMap
            )
            const new_editor_state =  EditorState.push(editorState,contentState,'insert-characters');
            setEditorState(new_editor_state)

            ref.current.setFieldValue("article", draftToHtml(convertToRaw(new_editor_state.getCurrentContent()),{
                hashConfig,
                directional
            }));

            axios.post(`${config.API_URL}/file/download-thumbnail`, {url: sample_image})
                .then(response => {
                    setCurrentThumbnail(response.data.data);
                })
                .catch(error => {
                    console.error("error thumbnail : ", error);
                })
        }
    }

    const handleSelectedCategories = (values) => {
        setSelectedCategories(values)
        let current_cat = categories.find(item => item.id == values[0].id);
        setSelectedCategorie(current_cat)
        let data = values.map(item =>  item.id);
        ref.current.setFieldValue('other_categories', data);
    }

    const handleCheckedCategorie = (e) =>  {
        setCheckedCategorie(e.target.checked)
    }

    return (<div className='m-10 p-10 bg-white rounded-lg'>
                <div className="max-w-5xl mx-auto divide-y divide-gray-300">
                    {/* {ref.current.values && ref.current.values.article ? JSON.stringify(ref.current.values.arctile) : null} */}
                    {!previewStatus ? 
                    <div className="flex items-center justify-between text-left ">
                        <h2 className="text-3xl font-bold text-gray-700">Nouvel Article</h2>
                        <button className="block bg-blue-500 py-2 px-4 text-md text-white font-semibold rounded" onClick={() => setPreviewStatus(!previewStatus)}>Prévisualiser</button>

                    </div>
                    : 
                    <div className="flex items-center justify-between text-left">
                        <h2 className="text-3xl font-bold text-gray-700">Prévisualiser</h2>
                        <button className="block bg-blue-500 py-2 px-4 text-md text-white font-semibold rounded" onClick={() => setPreviewStatus(!previewStatus)}>Ajout d'article</button>
                    </div>
                    }

                    {!previewStatus ? 
                    <div className="mt-10 py-10">
                        <Formik
                            innerRef={ref}
                            initialValues={{
                                title: currentBlog && currentBlog.title ? currentBlog.title : "",
                                article: currentBlog && currentBlog.article ? currentBlog.article : "",
                                categorie:currentBlog && currentBlog.categorie ? currentBlog.categorie : "",
                                tags: currentBlog && currentBlog.tags ? currentBlog.tags : [],
                                status: currentBlog && currentBlog.status ? currentBlog.status : "",
                                excerpt: currentBlog && currentBlog.excerpt ? currentBlog.excerpt : "",
                                featured_image:"",
                                author: currentBlog && currentBlog.author ? currentBlog.author :  null,
                                sub_categorie: currentBlog && currentBlog.sub_categorie ? currentBlog.sub_categorie : "",
                                duration: currentBlog && currentBlog.duration ? currentBlog.duration : "",
                                date_publication: currentBlog && currentBlog.date_publication ? currentBlog.date_publication : moment().format("YYYY-MM-DD"),
                                entreprise_email:currentBlog && currentBlog.entreprise_email ? currentBlog.entreprise_email : "",
                                other_categories:[],
                                isForClient: false
                            }}

                            validationSchema={Yup.object({
                                title: Yup.string().max(255,"Doit être inférieur a 255 caractères").required("Titre requis"),
                                article: Yup.string().required("Contenu requis"),
                                tags: Yup.array().of(Yup.string()),
                                status: Yup.string().required("Statut requis"),
                                excerpt: Yup.string(),
                                date_publication: Yup.string().required("Date de publication requis"),
                                duration: Yup.string().required("Duration requis"),
                                author: Yup.number().required("Auteur requis"),
                                entreprise_email: checked ? Yup.string().email("Doit être un email valide").required("Email requis") : Yup.string().email("Doit être un email valide"),
                                other_categories: Yup.array().of(Yup.number()).required("Il faut choisir au moin un catégorie")
                            })}

                            onSubmit={async (values) => {
                                let formdata = new FormData();
                                formdata.append("file", values.featured_image)
                                formdata.append("title", values.title);
                                formdata.append("tags", values.tags.join(", "))
                                formdata.append("article", values.article);
                                formdata.append("status", values.status);
                                formdata.append("excerpt", values.excerpt);
                                formdata.append("author", values.author);
                                formdata.append("date_publication", values.date_publication);
                                formdata.append("sub_categorie", values.sub_categorie);
                                formdata.append("duration", values.duration);
                                formdata.append("entreprise_email", values.entreprise_email);
                                formdata.append("other_categories", values.other_categories.join(", "));
                                formdata.append("isForClient", values.isForClient ? 1 : 0);
                                // if (values.other_categories.length) {
                                //     for (let cat of values.other_categories) {
                                //         formdata.append("other_categories", cat);
                                //     }
                                // }

                                if (currentThumbnail) {
                                    formdata.append("thumbnail", currentThumbnail.id );
                                }


                                await axios.post(`${API_URL}/blog/create`, formdata, {
                                    headers: {
                                        "Content-Type":"multipart/form-data"
                                    }
                                }).then((response) => {
                                    setNotification({code:3, title: "Succès", content: response.message})
                                    navigate("/admin/article/liste",{replace:true});
                                }).catch(error => {
                                    if (error && error.response && error.response.data) {
                                        setNotification({code:0, title: "Attention", content: error.response.data.message})
                                    } else {
                                        setNotification({code:1, title: "Error", content: error.message})

                                    }
                                })
                            }}
                        >{(formik) => (
                            <form className='space-y-5' onSubmit={formik.handleSubmit}>
                                <div className="">
                                        <label className="block text-xl text-gray-700">Titre</label>
                                        <input  type="text" 
                                                id="title"
                                                name="title"
                                                placeholder="Titre de l'article ..."
                                                className={`${!!didFocus && currentField == "title" && formik.errors.title || formik.touched.title && formik.errors.title ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("title")}
                                                value={formik.values.title}
                                                onChange={(e,value) => {formik.setFieldValue('title',e.target.value);setCurrentBlog(prevState => {prevState.title = e.target.value; return prevState})}}/>
                                                {!!didFocus && currentField == "title" && formik.errors.title || formik.touched.title && formik.errors.title ?<span className="text-red-500 text-sm">{formik.errors.title}</span> : null }
                                </div>

                                <div className="space-y-5">
                                    <div>
                                        <label className="text-xl text-gray-700 ">Lien Youtube</label>
                                        <input  type="text"
                                                id="url"
                                                name="url"
                                                placeholder='URL'
                                                value={url}
                                                onChange={(e) => setUrl(e.target.value)}
                                                className="border border-gray-300 focus:ring-2 focus:ring-blue-500 w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400"/>
                                    </div>
                                    <span onClick={() => handleUrl(url)} className="inline-block cursor-pointer mt-5 bg-green-500 rounded text-white font-semibold text-md px-4 py-1">Ajouter la video dans l'éditeur et telecharger l'image en vedette</span>
                                    <div>
                                        {
                                            videoImage ?
                                            <div className="w-20 h-20">
                                                <img className="w-full h-full object-cover" src={videoImage}/>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>

                                <div>
                                    <label className="text-xl text-gray-700 ">Script</label>
                                    <textarea   id="script_iframe"
                                                name="script_iframe"
                                                className="mt-3 h-36  w-full px-3 py-2 border border-gray-300 rounded outline-none focus:ring-2 focus:ring-blue-500 placeholder:italic placeholder:text-gray-400" 
                                                placeholder='Fait un résumé ...'
                                                value={scriptValue}
                                                onChange={(e) => setScriptValue(e.target.value)}></textarea>
                                    <span onClick={() => handleScriptIframe()} className="cursor-pointer mt-5 bg-green-500 rounded text-white font-semibold text-md px-4 py-1">Ajouter dans l'editeur</span>
                                </div>
                                <div>
                                    <h3 className="text-gray-700 text-xl mb-3">Editeur Article </h3>
                                    <div className="border border-gray-300 rounded p-5">
                                        <Editor
                                            editorRef={setEditorReference}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName='wrapperClassName'
                                            editorClassName='min-h-[450px]'
                                            onEditorStateChange={onEditorStateChange}
                                            onContentStateChange={onContentStateChange}
                                            // onChange={handleChange}
                                            toolbar={{
                                                image: {uploadCallback: uploadImageCallback, alt: {present: true, mandatory:false}},
                                                // embedded:{embedCallback: embeddedCallback, defaultSize: {
                                                //     height: 465,
                                                //     width: '100%',
                                                // },}
                                            }}

                                            // blockRenderMap={extendedBlockRenderMap}
                                            spellCheck={true}
                                        />
                                    </div>
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Duration</label>
                                        <select type="text" 
                                                id="duration"
                                                name="duration"
                                                placeholder='duration ...'
                                                className={`${!!didFocus && currentField == "duration" && formik.errors.duration || formik.touched.duration && formik.errors.duration ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("duration")}
                                                value={formik.values.duration}
                                                onChange={(e,value) => {formik.setFieldValue('duration',e.target.value);setCurrentBlog(prevState => {prevState.duration = e.target.value; return prevState})}}>
                                                <option value="">--Veuillez choisir un ou plusieurs options--</option>
                                                <option value="5min">5 minutes</option>
                                                <option value="10min">10 minutes</option>
                                                <option value="20min">20 minutes</option>
                                        </select>
                                        {!!didFocus && currentField == "duration" && formik.errors.duration || formik.touched.duration && formik.errors.duration ?<span className="text-red-500 text-sm">{formik.errors.duration}</span> : null }
                                </div>

                                <div>
                                    <label className="block text-xl text-gray-700">Catégorie(s)</label>
                                    <Multiselect    items={categoriesDropdown} 
                                                    setItems={setCategoriesDropDown} 
                                                    selectedItems={selectedCategories} 
                                                    setSelected={handleSelectedCategories} 
                                                    theme="basic"/>
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Sous Categorie article</label>
                                        <select type="text" 
                                                id="sub_categorie"
                                                name="sub_categorie"
                                                placeholder='Nom de la sous categorie ...'
                                                className={`${!!didFocus && currentField == "sub_categorie" && formik.errors.sub_categorie || formik.touched.sub_categorie && formik.errors.sub_categorie ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => {handleDidFocus("sub_categorie"); filterSubCategorie()}}
                                                value={formik.values.sub_categorie}
                                                onChange={(e,value) => {formik.setFieldValue('sub_categorie',e.target.value);setCurrentBlog(prevState => {prevState.sub_categorie = e.target.value; return prevState}); handleCurrentSubCategorie(e.target.value)}}>
                                                <option value="">--Veuillez choisir un option--</option>
                                                {subCategorieFiltered.map(categorie => (<option value={categorie.id}>{categorie.title}</option>))}
                                        </select>
                                        {!!didFocus && currentField == "sub_categorie" && formik.errors.sub_categorie || formik.touched.sub_categorie && formik.errors.sub_categorie ?<span className="text-red-500 text-sm">{formik.errors.sub_categorie}</span> : null }
                                </div>
                                            

                                {/* <div className="">
                                        <label className="block text-xl text-gray-700">Tags article</label>
                                        <select type="text" 
                                                id="tags"
                                                name="tags"
                                                multiple
                                                placeholder='#tags ...'
                                                className={`${!!didFocus && currentField == "tags" && formik.errors.tags || formik.touched.tags && formik.errors.tags ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("tags")}
                                                {...formik.getFieldProps('tags')}>
                                                <option value="">--Veuillez choisir un ou plusieurs options--</option>
                                                {tags.map(tag => (<option value={tag.id}>{tag.title}</option>))}
                                        </select>
                                        {!!didFocus && currentField == "tags" && formik.errors.tags || formik.touched.tags && formik.errors.tags ?<span className="text-red-500 text-sm">{formik.errors.tags}</span> : null }
                                </div> */}

                                <div className="">
                                        <label className="block text-xl text-gray-700">Statut</label>
                                        <select type="text" 
                                                id="status"
                                                name="status"
                                                placeholder="Status de l'article ..."
                                                className={`${!!didFocus && currentField == "status" && formik.errors.status || formik.touched.status && formik.errors.status ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("status")}
                                                value={formik.values.status}
                                                onChange={(e,value) => {formik.setFieldValue('status',e.target.value);setCurrentBlog(prevState => {prevState.status = e.target.value; return prevState})}}>
                                                <option value="">--Veuillez choisir un option--</option>
                                                <option value="draft">Brouillon</option>
                                                <option value="published">Publié</option>
                                        </select>
                                        {!!didFocus && currentField == "status" && formik.errors.status || formik.touched.status && formik.errors.status ?<span className="text-red-500 text-sm">{formik.errors.status}</span> : null }
                                </div>
                                {/* <div>
                                    <label className="text-xl text-gray-700 ">Extrait</label>
                                    <textarea   id="exerpt"
                                                name="excerpt"
                                                placeholder='Fait un résumé ...'
                                                className={`${!!didFocus && currentField == "excerpt" && formik.errors.excerpt || formik.touched.excerpt && formik.errors.excerpt ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("excerpt")}
                                                value={formik.values.excerpt}
                                                onChange={(e,value) => {formik.setFieldValue('excerpt',e.target.value);setCurrentBlog(prevState => {prevState.excerpt = e.target.value; return prevState})}}>
                                    </textarea>
                                    {!!didFocus && currentField == "excerpt" && formik.errors.excerpt || formik.touched.excerpt && formik.errors.excerpt ?<span className="text-red-500 text-sm">{formik.errors.excerpt}</span> : null }
                                </div> */}

                                <div>
                                    <label className="text-xl text-gray-700 ">Image en vedette</label>
                                    <div className='mt-3 group border-dashed border-2 border-gray-400 rounded-md hover:border-blue-500 py-5' onClick={() => featuredImageRef.current.click()}>
                                        <svg className="mx-auto h-20 w-20 text-gray-700 group-hover:text-blue-400 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <div >
                                        {files.length ?
                                            <div className="mt-3 h-36 w-36 rounded-md overflow-hidden">
                                                <img className="h-full w-full object-cover" src={URL.createObjectURL(files[0])}/>
                                            </div>
                                        : currentThumbnail ? 
                                            <div className="mt-3 h-36 w-36 rounded-md overflow-hidden">
                                                <img className="h-full w-full object-cover" src={`${config.API_URL}${currentThumbnail.url}`} /> 
                                            </div>
                                        : null}
                                        <input hidden ref={featuredImageRef} type="file" onChange={(e) =>  handleFileChange(e)}/>
                                    </div>
                                </div>

                                <div>
                                    <label>Date de publication</label>
                                    <div class="relative">
                                        <input  type="date" 
                                                id="date_publication"
                                                name="date_publication"
                                                placeholder="Selectionner une date"
                                                className={`${!!didFocus && currentField == "date_publication" && formik.errors.date_publication || formik.touched.date_publication && formik.errors.date_publication ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("date_publication")}
                                                value={formik.values.date_publication}
                                                onChange={(e,value) => {formik.setFieldValue('date_publication',e.target.value);setCurrentBlog(prevState => {prevState.date_publication = e.target.value; return prevState})}}/>
                                                {!!didFocus && currentField == "date_publication" && formik.errors.date_publication || formik.touched.date_publication && formik.errors.date_publication ?<span className="text-red-500 text-sm">{formik.errors.date_publication}</span> : null }   
                                    </div>
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Auteur</label>
                                        <select type="text" 
                                                id="author"
                                                name="author"
                                                placeholder="Nom de l'auteur ..."
                                                className={`${!!didFocus && currentField == "author" && formik.errors.author || formik.touched.author && formik.errors.author ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("author")}
                                                value={formik.values.author}
                                                onChange={(e,value) => {formik.setFieldValue('author',e.target.value);setCurrentBlog(prevState => {prevState.author = e.target.value; return prevState}); handleCurrentAuthor(e.target.value);}}>
                                            <option value="">--Veuillez choisir un option--</option>
                                            {authors.length ? 
                                                authors.map(user => (
                                                    <option value={user && user.id ? user.id : null}>{`${user.nom} ${user.prenom}`}</option>
                                                ))
                                            :null}
                                        </select>
                                        {!!didFocus && currentField == "author" && formik.errors.author || formik.touched.author && formik.errors.author ?<span className="text-red-500 text-sm">{formik.errors.author}</span> : null }   
                                </div>
                                <div class="form-check">
                                    <input  class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                            type="checkbox"
                                            checked={formik.values.isForClient}
                                            id="isForClient"
                                            {...formik.getFieldProps("isForClient")}
                                            />
                                    <label class="form-check-label inline-block text-gray-800" for="isForClient">
                                        Réservé aux insscrits
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" checked={checked} onChange={(e) =>  handlePresentation(e)} id="flexCheckChecked"/>
                                    <label class="form-check-label inline-block text-gray-800" for="flexCheckChecked">
                                        Présentation d'entreprise
                                    </label>
                                </div>
                                {checked ? 
                                    <div>
                                        <div className="">
                                            <label className="block text-xl text-gray-700">Email</label>
                                            <input  type="email" 
                                                    id="entreprise_email"
                                                    name="entreprise_email"
                                                    placeholder="Email de l'entrepise ..."
                                                    className={`${!!didFocus && currentField == "entreprise_email" && formik.errors.entreprise_email || formik.touched.entreprise_email && formik.errors.entreprise_email ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                    onFocus={() => handleDidFocus("entreprise_email")}
                                                    value={formik.values.entreprise_email}
                                                    onChange={(e,value) => {formik.setFieldValue('entreprise_email',e.target.value);setCurrentBlog(prevState => {prevState.entreprise_email = e.target.value; return prevState})}}/>
                                                    {!!didFocus && currentField == "entreprise_email" && formik.errors.entreprise_email || formik.touched.entreprise_email && formik.errors.entreprise_email ?<span className="text-red-500 text-sm">{formik.errors.entreprise_email}</span> : null }
                                        </div>
                                    </div>
                                : null}

                                <div className='flex space-x-10 mt-10'>
                                        <button type="submit" className="flex-1 px-4 py-2 text-white bg-blue-700 rounded">Sauvegarder</button>
                                        <button type="reset" className="flex-1 px-4 py-2 text-white bg-gray-400 rounded">Réinitialiser</button>
                                    </div>

                            </form>
                        )}</Formik>
                    </div>
                    : <BlogPreview currentBlog={currentBlog} currentAuthor={selectedAuthor} currentCategorie={selectedCategorie} currentSubCategorie={selectedSubCategorie}/>}

                </div>
            </div>)
}