import React from 'react';

export const BlogContext = React.createContext();
class BlogProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlog: null
        }

        this.setCurrentBlog = this.setCurrentBlog.bind(this);
    }

    setCurrentBlog(blog) {
        this.setState({currentBlog: blog});
    }

    render() {
        const {currentBlog} = this.state;
        const {children} = this.props;
        const configObject = {
            currentBlog,
            setCurrentBlog: (blog) =>  this.setCurrentBlog(blog)
        }

        return  (<BlogContext.Provider value={configObject}>
                    {children}
                </BlogContext.Provider>)
    }
}

export default BlogProvider;