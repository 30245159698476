import { Disclosure, Transition } from '@headlessui/react';
import React, {useState} from 'react';
import profile from '../../../../assets/images/profile.jpg'
import {Link} from 'react-router-dom';

export default function Sidebar(props) {
    const [currentItem, setCurrentItem] = useState(null);
    return (
        <Disclosure.Panel>
        <div className="fixed inset-y-0 bg-white shadow-xl overflow-y-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                <div className="h-[66px] border-b border-gray-200"></div>
                <div className="text-center w-auto divide-y divide-gray-300 max-w-screen overflow-y-auto">
                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="dashboard">
                            <div onClick={() => setCurrentItem("dashboard")}>
                                <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                </svg>
                                <span className="text-gray-700 text-xs text-bold group-hover:text-white">Dashboard</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="utilisateur/liste">
                            <div onClick={() => setCurrentItem("utilisateur")}>
                                <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span className="text-gray-700 text-xs text-bold group-hover:text-white">Utilisateurs</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="auteur/liste">
                            <div onClick={() => setCurrentItem("auteur")}>
                                <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                                <span className="text-gray-700 text-xs text-bold group-hover:text-white">Auteurs</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="categorie/liste">
                            <div onClick={() => setCurrentItem("categorie")}>
                                <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                                </svg>
                                <span className="text-gray-700 text-xs text-bold group-hover:text-white">Categorie</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="sous-categorie/liste">
                            <div onClick={() => setCurrentItem("sous-categorie")}>
                                <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeWidth={1} strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                </svg>
                                <span className="text-gray-700 text-xs text-bold group-hover:text-white">Sous Categorie</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="tag/liste">
                            <div onClick={() => setCurrentItem("tags")}>
                                <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                </svg>
                                <span className="text-gray-700 text-xs text-bold group-hover:text-white">Tags</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="media/liste">
                            <div onClick={() => setCurrentItem("medias")}>
                                    <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    <span className="text-gray-700 text-xs text-bold group-hover:text-white">Medias</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="article/liste">
                            <div onClick={() => setCurrentItem("article")}>
                                    <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                    <span className="text-gray-700 text-xs text-bold group-hover:text-white">Articles</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="page/liste">
                            <div onClick={() => setCurrentItem("article")}>
                                    <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                                        <path strokeLinecap="round" strokeWidth={1} strokeLinejoin="round" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                                    </svg>
                                    <span className="text-gray-700 text-xs text-bold group-hover:text-white">Pages</span>
                            </div>
                        </Link>
                    </div>

                    <div className="relative grid h-24 w-24 bg-white content-center group hover:bg-rose-500">
                        <Link to="email/send">
                            <div onClick={() => setCurrentItem("article")}>
                                    <svg className="mx-auto h-10 w-10 text-gray-700 group-hover:text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                    <span className="text-gray-700 text-xs text-bold group-hover:text-white">Email</span>
                            </div>
                        </Link>
                    </div>

                    
                </div>
            </div>
            </Disclosure.Panel>
            )



}