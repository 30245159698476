import axios from 'axios';
import config from '../../../../config/conifg.json';
const API_URL = config.API_URL;
class AuthorService {
    async getAllAuthor() {
        const {data} = await axios.get(`${API_URL}/auteur/all`);
        return data;
    }

    async addAuthor(value) {
        const {data} = await axios.post(`${API_URL}/auteur/ajout`);
        return data;
    }

    async updateAuthor(id) {
        const {data} = await axios.put(`${API_URL}/auteur/update/${id}`);
        return data;
    }

    async getAuthorById(id) {
        const {data} = await axios.get(`${API_URL}/auteur/id/${id}`);
        return data;
    }

    async deleteAuthor(id) {
        const {data} = await axios.delete(`${API_URL}/auteur/delete/${id}`);
        return data;
    }
}

export default new AuthorService();