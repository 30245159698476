import axios from 'axios';
import config from '../../../../config/conifg.json';

const API_URL = config.API_URL;

class SigninService {
    async authenticate(values) {
        const {data} = await axios.post(`${API_URL}/client/signin`, values);
        return data;
    }
}

export default new SigninService();