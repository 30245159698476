import axios from "axios";
import config from "../../../../config/conifg.json";

class PasswordChangeService {
    async changePassword(token,values) {
        const {data} = await axios.post(`${config.API_URL}/client/change-password/${token}`, values);
        return data;
    }
}

export default new PasswordChangeService();