import React , {useState, useEffect} from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements, CardElement, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import donService from './donService';
import FormCheckout from './formCheckout';

const stripePromise = loadStripe("pk_live_51HlcLyE4g7Fzy2LcPMKo4nZdXDjwTpNHzmDumdFFst4pd94AQo1D7K7C75KqHiu5Hnm0gQEnSRuA3oYjkB8QZWAK000Q1ilynL");
// const stripePromise = loadStripe("pk_test_51Kg1tvKpF14rqSzs9UYx1add0iM4K7nek2bFArUTkGDdU7ZmswqrN2hpp5ltTTDK61TmWxfxt1J0ORoOsD1RDHnD00fHZ3AWDz");

export default function Don() {
    const [clientSecret, setClientSecret] = useState("");
    const [currentAmount, setCurrentAmount] = useState(5);
    const [currentEmail, setCurrentEmail] = useState("");
    const [currentNom, setCurrentNom] = useState("");
    const [currentPrenom, setCurrentPrenom] = useState("");

    useEffect(() => {
        donService.createPaymentIntent({amount : currentAmount})
            .then(response => {
                if (response.clientSecret) {
                    setClientSecret(response.clientSecret)
                }
            })
            .catch(error => {
                console.error();
            })
    },[currentAmount])

    const appearance = {
        theme: "stripe"
    }




    return (<div className="font-sans py-20">
                <div className="dark:text-gray-200 mx-20 xl:max-w-5xl xl:mx-auto text-center text-gray-700 font-semibold">
                    <p>Vous aimez notre chaîne 100% digitale ?</p>
                    <p>Faites un don, soutenez-nous !</p>
                    <p> BE MEDIA TV est diffusé par une société indépendante et nous souhaitons le rester.</p>
                    <p>A l’origine nous voulions un croisement d’expériences et de savoir-faire entre élus, chefs d’entreprise, artisans, institutions ou associations pour que tous ceux qui créé notre vivre ensemble s’y retrouvent. Nous l’avons fait de 2017 à 2020 mais la COVID nous a obligé de modifier notre façon de faire, sous forme de one to one la plupart du temps. BE MEDIA TV vous propose une sélection d'informations sur les actualités des entreprises, dans une vraie démarche de croissance. En continu du savoir utile et loin des faits divers…</p>
                    <p>Nous vous sollicitons afin de continuer à fournir un travail de qualité, à vous divertir et à acheter du matériel.</p>
                </div>
                <div className="w-96 mx-auto my-10">
                    <h2 className="text-gray-700 text-4xl font-bold text-center dark:text-gray-300">Faire un don</h2>
                </div>
                <div className="w-96 h-auto mx-auto">
                
                    <div className="w-full my-5 flex flex-col w-full text-gray-700 text-lg font-semibold mb-3">
                        <label className="text-gray-700 text-lg font-semiblod dark:text-gray-400">Nom sur la carte: </label>
                        <input  className="placeholder:italic placeholder:text-gray-300 w-full pl-10 border border-slate-400 rounded py-2 px-4" 
                                type="email"
                                placeholder="Votre nom ici" 
                                min="5" 
                                value={currentNom} 
                                onChange={(e) => setCurrentNom(e.target.value)}/>
                    </div>

                    <div className="w-full my-5 flex flex-col w-full xt-gray-700 text-lg font-semibold mb-3">
                        <label className="text-gray-700 text-lg font-semiblod dark:text-gray-400">Email : </label>
                        <input  className="placeholder:italic placeholder:text-gray-300 w-full pl-10 border border-slate-400 rounded py-2 px-4" 
                                type="email" 
                                min="5" 
                                value={currentEmail}
                                placeholder="Votre email ici"  
                                onChange={(e) => setCurrentEmail(e.target.value)}/>
                    </div>

                    <div className="w-full my-5 flex flex-col w-full text-gray-700 text-lg font-semibold mb-3">
                        <label className="text-gray-700 text-lg font-semiblod dark:text-gray-400">Montant : </label>
                        <div className="relative w-full">
                            <span className="absolute inset-y-0 flex left-2 items-center ">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </span>
                            <input className="w-full pl-10 border border-slate-400 rounded py-2 px-4" type="number" min="5" value={currentAmount} onChange={(e) => setCurrentAmount(e.target.value)}/>
                        </div>
                    </div>
                    <Elements stripe={stripePromise} options={appearance}>
                        <FormCheckout clientSecret={clientSecret} currentAmount={currentAmount}  handleClientSecret={setClientSecret} email={currentEmail} nom={currentNom}/>
                    </Elements>
                </div>
            </div>)
}