import axios from "axios";
import config from '../../../config/conifg.json';

class DonService {
    async createPaymentIntent(value) {
        const {data} = await axios.post(`${config.API_URL}/stripe/create-payment-intent`, value);
        return data;
    }
}

export default new DonService();