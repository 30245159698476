import React, { useContext, useEffect, useRef, useState } from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import userService from './userService';
import axios from 'axios';
import config from '../../../../config/conifg.json';
import { useNavigate, useParams } from 'react-router-dom';
import {AppContext} from '../../../../appProvider';
const API_URL = config.API_URL;


export default function UserEdit(props) {
    const navigate = useNavigate();
    const {setNotification} = useContext(AppContext);
    const [profile, setProfile] = useState(null);
    const [image, setImage] = useState(null);
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const profileRef = useRef(null);
    const formikRef = useRef(null);
    const params = useParams();
    const handleFileChange =(e) => {
        setProfile(e.target.files[0]);
        formikRef.current.setFieldValue("profile", e.target.files[0]);
    }

    useEffect(() => {
        userService.getUserById(params.id)
            .then(response => {
                formikRef.current.setFieldValue("nom", response.data.nom);
                formikRef.current.setFieldValue("prenom", response.data.prenom);
                formikRef.current.setFieldValue("email", response.data.email);
                formikRef.current.setFieldValue("type", response.data.type);
                if (response.data && response.data.File) {
                    setImage(response.data.File);
                }
            })
            .catch(error => {
                console.error(error);
            })
    },[])

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    return (<div className="m-10 h-full">
            <div className="p-10 bg-white rounded-xl min-h-screen h-full">
                    <div className="w-full max-w-xl mx-auto">
                    <h3 className="text-2xl font-bold text-gray-600 text-center">Editer Utilisateur</h3>
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                nom:"",
                                prenom:"",
                                email:"",
                                type:"",
                                profile: ""
                            }}

                            validationSchema={
                                Yup.object({
                                    nom: Yup.string().max(255, "Nom doit être inférieur à 255 catactères").required("Nom requis"),
                                    prenom: Yup.string().max(255, "Prénom doit être inférieur à 255 catactères").required("Prénom requis"),
                                    email:Yup.string().email("Doit être un email valide").required("Email requis"),
                                    type: Yup.string().required("Type de compte requis")
                                })
                            }
                            onSubmit={(values) => {
                                const formdata = new FormData();
                                formdata.append("nom", values.nom);
                                formdata.append("prenom", values.prenom);
                                formdata.append("email", values.email);
                                formdata.append("password", values.password); 
                                formdata.append("file", values.profile);
                                formdata.append("type", values.type);
                                axios.put(`${API_URL}/user/update/${params.id}`,formdata)
                                .then(response => {
                                    setNotification({code:3, title: "Succès", content: response.message})
                                    navigate("/admin/utilisateur/liste",{replace:true});

                                })
                            }}
                        >{((formik) => (
                            <Form className='space-y-5 w-full mt-10' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                <div>
                                    <div className="relative grid content-center group hover:border-blue-500 w-36 h-36 mx-auto rounded-full border-2 border-dashed border-gray-400 overflow-hidden" onClick={()=> profileRef.current.click()}>
                                        {profile ? 
                                            <div className="h-28 w-28 mx-auto overflow-hidden rounded-full">
                                                <img className="h-full w-full object-cover" src={URL.createObjectURL(profile)}/>
                                            </div>
                                        : image ?
                                            <div className="h-28 w-28 mx-auto overflow-hidden rounded-full">
                                                <img className="h-full w-full object-cover" src={`${config.API_URL}${image.url}`}/>
                                            </div>
                                        :
                                            <svg className="h-34 w-34 mx-auto fill-current text-gray-300 group-hover:text-blue-300" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                            </svg>
                                        }
                                        
                                        <span className="block bg-white text-center absolute bottom-3 inset-x-0 text-gray-700 text-sm text-semibold group-hover:text-blue-500"><span className="text-2xl font-bold">+</span> Ajouter</span>
                                        <input hidden type="file" ref={profileRef} onChange={(e) => handleFileChange(e)}/>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
                                    <div className="flex-1">
                                        <label className="block text-xl text-gray-700">Nom</label>
                                        <input  type="text" 
                                                id="nom"
                                                name="nom"
                                                placeholder='Votre nom ...'
                                                className={`${!!didFocus && currentField == "nom" && formik.errors.nom || formik.touched.nom && formik.errors.nom ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("nom")}
                                                {...formik.getFieldProps("nom")}/>
                                                {!!didFocus && currentField == "nom" && formik.errors.nom || formik.touched.nom && formik.errors.nom ?<span className="text-red-500 text-sm">{formik.errors.nom}</span> : null }
                                    </div>

                                    <div className="flex-1">
                                        <label className="block text-xl text-gray-700">Prénom</label>
                                        <input  type="text" 
                                                id="prenom"
                                                name="prenom"
                                                placeholder='Votre prénom ...'
                                                className={`${!!didFocus && currentField == "prenom" && formik.errors.prenom || formik.touched.prenom && formik.errors.prenom ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("prenom")}
                                                {...formik.getFieldProps("prenom")}/>
                                                {!!didFocus && currentField == "prenom" && formik.errors.prenom || formik.touched.prenom && formik.errors.prenom ?<span className="text-red-500 text-sm">{formik.errors.prenom}</span> : null }
                                    </div>
                                </div>

                                <div className="">
                                    <label className="block text-xl text-gray-700">Email</label>
                                    <input  type="email" 
                                            id="email"
                                            name="email"
                                            placeholder='Votre email ...'
                                            className={`${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                            onFocus={() => handleDidFocus("email")}
                                            {...formik.getFieldProps("email")}/>
                                            {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="text-red-500 text-sm">{formik.errors.email}</span> : null }
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Type</label>
                                        <select type="text" 
                                                id="type"
                                                name="type"
                                                placeholder="Type de compte ..."
                                                className={`${!!didFocus && currentField == "type" && formik.errors.type || formik.touched.type && formik.errors.type ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("type")}
                                                {...formik.getFieldProps("type")}>
                                            <option value="">--Veuillez choisir un option--</option>
                                            <option value="CLIENT">Client</option>
                                            <option value="ADMIN">Administrateur</option>
                                        </select>
                                        {!!didFocus && currentField == "type" && formik.errors.type || formik.touched.type && formik.errors.type ?<span className="text-red-500 text-sm">{formik.errors.type}</span> : null }
                                </div>

                                <div className='flex space-x-10 mt-10'>
                                    <button type="submit" className="flex-1 px-4 py-2 text-white bg-blue-700 rounded">Sauvegarder</button>
                                    <button type="reset" className="flex-1 px-4 py-2 text-white bg-gray-400 rounded">Réinitialiser</button>
                                </div>
                            </Form>
                        ))}
                        </Formik>
                </div>
                    
            </div>
        </div>)
}