import React,{useContext, useRef, useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import subCategorieService from './subCategorieService';
import axios from 'axios';
import config from '../../../../config/conifg.json';
import {AppContext} from '../../../../appProvider';
import { useNavigate } from 'react-router-dom';
import categorieService from '../categorie/categorieService';
import { useParams } from 'react-router-dom';

export default function SubCategorieEdit(props) {
    const navigate = useNavigate();
    const {setNotification} = useContext(AppContext)
    let featuredImageRef = useRef(null);
    let formikRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([])
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const params = useParams();
    const handleFileChange = (e) => {
        setFiles([...e.target.files]);
        formikRef.current.setFieldValue("featured_image",e.target.files[0]);
    }

    useEffect(() => {
        subCategorieService.getSubCategorieById(params.id)
            .then(response => {
                formikRef.current.setFieldValue("title", response?.data[0]?.title);
                formikRef.current.setFieldValue("parent_categorie", response?.data[0]?.categorie)
                formikRef.current.setFieldValue("description", response?.data[0]?.description)
            })
        
        categorieService.getAllCategorie()
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error(error);
            })
    },[])

    const handleDidFocus = (field) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    return (<div className="m-10 ">
            <div className="p-10 bg-white rounded-xl min-h-screen h-auto">
                    <div className="w-full max-w-xl mx-auto">
                    <h3 className="text-2xl font-bold text-gray-600">Editer Sous Categorie</h3>
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                title: "",
                                parent_categorie: "",
                                featured_image: "",
                                description: "",
                            }}

                            validationSchema={
                                Yup.object({
                                    title: Yup.string().max(255,"doit être inférieur a 255 caractères").required("Titre requis"),
                                    parent_categorie: Yup.number().required("Categorie requis"),
                                    description: Yup.string().max(255,"doit être inférieur a 255 caractères")
                                })
                            }
                            onSubmit={(values) => {
                                let formdata = new FormData();
                                formdata.append("title", values.title);
                                formdata.append("parent_categorie", values.parent_categorie);
                                formdata.append("file", values.featured_image);
                                formdata.append("description", values.description);
                                axios.put(`${config.API_URL}/sous-categorie/update/${params.id}`, formdata, {
                                    headers:{
                                        'Content-Type':"multipart/form-data"
                                    }
                                }).then(response => {
                                    setNotification({code:3, title: "Succès", content: response.message})
                                    navigate("/admin/sous-categorie/liste",{replace:true});
                                })
                            }}
                        >{((formik) => (
                            <Form className='space-y-5 w-full mt-10' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                <div className="">
                                    <label className="block text-xl text-gray-700">Titre</label>
                                    <input  type="text" 
                                            id="title"
                                            name="title"
                                            placeholder='Nom de la sous catégorie ...'
                                            className={`${!!didFocus && currentField == "title" && formik.errors.title || formik.touched.title && formik.errors.title ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                            onFocus={() => handleDidFocus("title")}
                                            {...formik.getFieldProps("title")}/>
                                            {!!didFocus && currentField == "title" && formik.errors.title || formik.touched.title && formik.errors.title ?<span className="text-red-500 text-sm">{formik.errors.title}</span> : null }
                                </div>

                                <div className="">
                                        <label className="block text-xl text-gray-700">Catégorie</label>
                                        <select type="text" 
                                                id="parent_categorie"
                                                name="parent_categorie"
                                                placeholder="Nom de l'autheur ..."
                                                className={`${!!didFocus && currentField == "parent_categorie" && formik.errors.parent_categorie || formik.touched.parent_categorie && formik.errors.parent_categorie ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("parent_categorie")}
                                                {...formik.getFieldProps("parent_categorie")}>
                                            <option value="">--Veuillez choisir un option--</option>
                                            {categories.length ?
                                                categories.map(item => (
                                                    <option value={item.id}>{item.title}</option>
                                                ))
                                            : null}
                                        </select>
                                        {!!didFocus && currentField == "parent_categorie" && formik.errors.parent_categorie || formik.touched.parent_categorie && formik.errors.parent_categorie ?<span className="text-red-500 text-sm">{formik.errors.parent_categorie}</span> : null }
                                </div>

                                <div>
                                    <label className="text-xl text-gray-700 ">Image en vedette</label>
                                    <div className='mt-3 group border-dashed border-2 border-gray-400 rounded-md hover:border-blue-500 py-5' onClick={() => featuredImageRef.current.click()}>
                                        <svg className="mx-auto h-20 w-20 text-gray-700 group-hover:text-blue-400 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <div >
                                        {files.length ?
                                            <div className="mt-3 h-36 w-36 rounded-md overflow-hidden">
                                                    <img className="h-full w-full object-cover" src={URL.createObjectURL(files[0])}/>
                                            </div>
                                        : null}
                                        <input hidden ref={featuredImageRef} type="file" onChange={(e) =>  handleFileChange(e)}/>
                                    </div>
                                </div>

                                <div>
                                    <label className="text-xl text-gray-700 ">Description</label>
                                    <textarea   id="description"
                                                name="description"
                                                placeholder='En dire plus ...'
                                                className={`${!!didFocus && currentField == "description" && formik.errors.description || formik.touched.description && formik.errors.description ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-300 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} 
                                                onFocus={() => handleDidFocus("description")}
                                                {...formik.getFieldProps("description")}>
                                    </textarea>
                                    {!!didFocus && currentField == "description" && formik.errors.description || formik.touched.description && formik.errors.description ?<span className="text-red-500 text-sm">{formik.errors.description}</span> : null }
                                </div>
                                <div className='flex space-x-10 mt-10'>
                                    <button type="submit" className="flex-1 px-4 py-2 text-white bg-blue-700 rounded">Sauvegarder</button>
                                    <button type="reset" className="flex-1 px-4 py-2 text-white bg-gray-400 rounded">Réinitialiser</button>
                                </div>
                            </Form>
                        ))}
                        </Formik>
                </div>
                    
            </div>
        </div>)
}